import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../Dropdown';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';
import FavouriteStat from './FavouriteStat';
import { links } from '../../../lib/constants';

const FavouriteHandler = ({
    parentClassName,
    logInMsg,
    slug,
    videosVideoFavoriteGTM,
    t,
    likesCount,
    online,
    isLoggedIn,
    onFavoriteClicked,
    id,
    isFavourite,
}) => {
    return (
        <Dropdown
            readAloudShare
            toggleEl={
                <FavouriteStat
                    likesCount={likesCount}
                    online={online}
                    parentClassName={parentClassName}
                    isLoggedIn={isLoggedIn}
                    onFavoriteClicked={onFavoriteClicked}
                    id={id}
                    videosVideoFavoriteGTM={videosVideoFavoriteGTM}
                    isFavourite={isFavourite}
                />
            }
        >
            <Link
                favouriteShare
                parentClassName={parentClassName}
                fullWidth
                legend={logInMsg}
                href={links.redirectReadAloudSignIn(slug)}
                dataGTM={videosVideoFavoriteGTM}
            >
                <SvgIcon name="user" /> {t('global.log-in')}
            </Link>
        </Dropdown>
    );
};

FavouriteHandler.propTypes = {
    parentClassName: PropTypes.string.isRequired,
    logInMsg: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    videosVideoFavoriteGTM: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    likesCount: PropTypes.number.isRequired,
    online: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isFavourite: PropTypes.bool.isRequired,
};

export default FavouriteHandler;
