import React from 'react';

function Header() {
    return (
        <React.Fragment>
            {navigator.language === 'id' ? (
                <h2 style={{ paddingTop: '8px', paddingLeft: '8px' }}>
                    Peringatan:
                </h2>
            ) : (
                <h2 style={{ paddingTop: '8px', paddingLeft: '8px' }}>
                    Warning:
                </h2>
            )}
        </React.Fragment>
    );
}

export default Header;
