import React from 'react';
import PropTypes from 'prop-types';
import List from '../../List';
import Dropdown from '../../Dropdown';
import Link from '../../Link';
import Caret from '../../Caret';
import Picklist from '../../Picklist';
import Inliner from '../../Inliner';
import SortOption from '../../SortOption';
import SvgIcon from '../../SvgIcon';
import { isRightToLeftDirection } from '../../../util/utils';
import '../FiltersBar.scss';

const FiltersBarComponent = ({
    t,
    viewport,
    baseClassName,
    readAloud,
    shouldDisplayCategorySelector,
    categoryFilterGTM,
    categorySearchValue,
    updateCategorySearchValue,
    applyFilter,
    removeFilter,
    filtersBarFilters,
    hideLevelSelector,
    levelFilterGTM,
    levelSearchValue,
    updateLevelSearchValue,
    filters,
    roleFilterGTM,
    hideLanguageSelector,

    languageFilterGTM,
    displayTypeSelector,
    sortOptions,
    appliedSortOption,
    applySortOption,
    isIllustration,
    isResources,
    isAddBook,
    isBookSection,
    isVideos,
    isTranslate,
    isReadingList,
    favoriteSortGTM,
    viewSortGTM,
    alphabetSortGTM,
    readSortGTM,
    openModalFilter,
    isTranslateMobile,
    openModalSort,
    handlePicklistChange,
    levelOptions,
    shouldDisplayRoleSelector,
    categoryOptions,
    roleOptions,
    languageOptions,
    shouldDisplayLanguageSelector,
    typeOptions,
    currentRole,
}) => {
    return (
        <React.Fragment>
            {viewport && viewport.medium ? (
                <div className={`${baseClassName}__action-wrapper`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__actions ${baseClassName}__actions--primary-arabic __bookFilter`
                                : `${baseClassName}__actions ${baseClassName}__actions--primary __bookFilter`
                        }
                    >
                        <span className={`${baseClassName}__section-label`}>
                            {t('global.filter-by')}
                        </span>

                        <List inline pushRight>
                            {!readAloud ? (
                                shouldDisplayCategorySelector ? (
                                    <Dropdown
                                        align="left"
                                        toggleEl={
                                            <Link
                                                lightBlack
                                                dataGTM={categoryFilterGTM}
                                            >
                                                {t('global.category', 1)}{' '}
                                                <Caret
                                                    variant="twilight-lavender"
                                                    direction="down"
                                                />
                                            </Link>
                                        }
                                    >
                                        <div>
                                            <Picklist
                                                id="filers-bar-categories"
                                                searchValue={
                                                    categorySearchValue
                                                }
                                                onSearchChange={
                                                    updateCategorySearchValue
                                                }
                                                options={categoryOptions}
                                                onChange={(value, checked) =>
                                                    handlePicklistChange(
                                                        'category',
                                                        value,
                                                        checked
                                                    )
                                                }
                                                checkedValues={
                                                    filtersBarFilters.category
                                                }
                                            />
                                        </div>
                                    </Dropdown>
                                ) : null
                            ) : null}

                            {!hideLevelSelector && (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            dataGTM={levelFilterGTM}
                                        >
                                            {t('global.level', 1)}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        id="filers-bar-levels"
                                        searchValue={levelSearchValue}
                                        onSearchChange={updateLevelSearchValue}
                                        options={levelOptions}
                                        type="level"
                                        onChange={(value, checked) =>
                                            handlePicklistChange(
                                                'level',
                                                value,
                                                checked
                                            )
                                        }
                                        checkedValues={filtersBarFilters.level}
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            )}

                            {shouldDisplayRoleSelector ? (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            dataGTM={roleFilterGTM}
                                        >
                                            {t('global.role')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        options={roleOptions}
                                        onChange={(value, checked) =>
                                            handlePicklistChange(
                                                'role',
                                                value,
                                                checked
                                            )
                                        }
                                        checkedValues={filtersBarFilters.role}
                                        currentRole={currentRole}
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            ) : null}
                            {shouldDisplayLanguageSelector &&
                            !hideLanguageSelector ? (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            dataGTM={languageFilterGTM}
                                        >
                                            {t('Book.read-other-language')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        options={languageOptions}
                                        onChange={(value, checked) =>
                                            handlePicklistChange(
                                                'language',
                                                value,
                                                checked
                                            )
                                        }
                                        checkedValues={
                                            filtersBarFilters.language
                                        }
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            ) : null}
                            {displayTypeSelector ? (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            dataGTM={levelFilterGTM}
                                        >
                                            {t('global.type')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        id="filers-bar-levels"
                                        searchValue={levelSearchValue}
                                        onSearchChange={updateLevelSearchValue}
                                        options={typeOptions}
                                        type="type"
                                        onChange={(value, checked) =>
                                            handlePicklistChange(
                                                'read_alouds',
                                                value,
                                                checked
                                            )
                                        }
                                        checkedValues={
                                            filtersBarFilters.read_alouds
                                        }
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            ) : null}
                        </List>
                    </div>
                    {sortOptions ? (
                        <div
                            className={`${baseClassName}__actions ${baseClassName}__actions--secondary`}
                        >
                            <span className={`${baseClassName}__section-label`}>
                                {t('global.sort-by')}
                            </span>
                            <Inliner auto>
                                <SortOption
                                    value={appliedSortOption}
                                    id="filters-bar-sort-by"
                                    name="filters-bar-sort-by"
                                    onChange={applySortOption}
                                    options={sortOptions}
                                    isIllustration={isIllustration}
                                    isResources={isResources}
                                    isAddBook={isAddBook}
                                    isBookSection={isBookSection}
                                    isVideos={isVideos}
                                    isTranslate={isTranslate}
                                    isReadingList={isReadingList}
                                    favoriteSortGTM={favoriteSortGTM}
                                    viewSortGTM={viewSortGTM}
                                    alphabetSortGTM={alphabetSortGTM}
                                    readSortGTM={readSortGTM}
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                />
                            </Inliner>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className={`${baseClassName}__filter-responsive`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__filter-option-rtl`
                                : `${baseClassName}__filter-option`
                        }
                    >
                        <Link filterMobile onClick={openModalFilter}>
                            <SvgIcon
                                name="filter-option"
                                size="m"
                                svgIconBooks
                            />
                            {t('global.filter', 1)}
                        </Link>
                    </div>
                    {!isTranslateMobile ? (
                        <div className={`${baseClassName}__sort-option`}>
                            <Link filterMobile onClick={openModalSort}>
                                <SvgIcon name="sort" size="m" svgIconBooks />
                                {t('global.sort')}
                            </Link>
                        </div>
                    ) : null}
                </div>
            )}
        </React.Fragment>
    );
};

FiltersBarComponent.propTypes = {
    t: PropTypes.func.isRequired,
    viewport: PropTypes.object.isRequired,
    baseClassName: PropTypes.string.isRequired,
    readAloud: PropTypes.bool,
    shouldDisplayCategorySelector: PropTypes.bool,
    categoryFilterGTM: PropTypes.string,
    categorySearchValue: PropTypes.string,
    updateCategorySearchValue: PropTypes.func,
    applyFilter: PropTypes.func,
    removeFilter: PropTypes.func,
    filtersBarFilters: PropTypes.object,
    hideLevelSelector: PropTypes.bool,
    levelFilterGTM: PropTypes.string,
    levelSearchValue: PropTypes.string,
    updateLevelSearchValue: PropTypes.func,
    filters: PropTypes.object,
    roleFilterGTM: PropTypes.string,
    hideLanguageSelector: PropTypes.bool,
    languageFilterGTM: PropTypes.string,
    displayTypeSelector: PropTypes.bool,
    sortOptions: PropTypes.array,
    appliedSortOption: PropTypes.string,
    applySortOption: PropTypes.func,
    isIllustration: PropTypes.bool,
    isResources: PropTypes.bool,
    isAddBook: PropTypes.bool,
    isBookSection: PropTypes.bool,
    isVideos: PropTypes.bool,
    isTranslate: PropTypes.bool,
    isReadingList: PropTypes.bool,
    favoriteSortGTM: PropTypes.string,
    viewSortGTM: PropTypes.string,
    alphabetSortGTM: PropTypes.string,
    readSortGTM: PropTypes.string,
    openModalFilter: PropTypes.func,
    isTranslateMobile: PropTypes.bool,
    openModalSort: PropTypes.func,
    handlePicklistChange: PropTypes.func.isRequired,
    levelOptions: PropTypes.array,
    shouldDisplayRoleSelector: PropTypes.bool,
    categoryOptions: PropTypes.array,
    roleOptions: PropTypes.array,
    languageOptions: PropTypes.array,
    shouldDisplayLanguageSelector: PropTypes.bool,
    typeOptions: PropTypes.array,
    currentRole: PropTypes.string,
};

export default FiltersBarComponent;
