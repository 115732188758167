import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import Link from '../../Link';
import ContentStyler from '../../ContentStyler';
import LoaderIcon from '../../../assets/loader.svg';

const ManageReadingListHeader = ({
    t,
    manageReadingList,
    manageReadingListBookCount,
    BookAddedToList,
    disable,
    setDisable,
    manageReadingListFunc,
}) => {
    return (
        <Block background="transparent">
            <ContentStyler manageReadingList>
                <div className="__header-section">
                    <div>
                        <div className="__title">
                            <h3>{manageReadingList.title}</h3>
                            <h3 className="__readinglist-count">
                                ({manageReadingListBookCount})
                            </h3>
                        </div>
                        <div className="__manage-description-text">
                            {manageReadingList.description}
                        </div>
                    </div>
                </div>
                <div className="__header-button-section">
                    {BookAddedToList.length <= 0 ? (
                        <div className="__disabled-button">
                            <span className="__done-button-disabled">
                                {t('ReadingList.done')}
                            </span>
                        </div>
                    ) : (
                        <div className="__done-button-section">
                            {disable ? (
                                <span
                                    className="__done-button-disabled"
                                    data-gtm="done"
                                >
                                    <img
                                        src={LoaderIcon}
                                        alt="loader"
                                        className="svgLoaderRL"
                                        style={{
                                            margin: '0px 5px',
                                        }}
                                    />
                                    {t('ReadingList.done')}
                                </span>
                            ) : (
                                <span
                                    className="__done-button"
                                    onClick={() => {
                                        manageReadingListFunc();
                                        setDisable(true);
                                    }}
                                    data-gtm="done"
                                >
                                    {t('ReadingList.done')}
                                </span>
                            )}
                        </div>
                    )}
                    {disable ? (
                        <div
                            className="__manage-button-disabled"
                            data-gtm="add-books"
                        >
                            {t('Readinglist.add-books')}
                        </div>
                    ) : (
                        <Link
                            href={'/myreadinglists/addbooks'}
                            isInternal
                            dataGTM="add-books"
                        >
                            <div
                                className="__manage-button"
                                data-gtm="add-books"
                            >
                                {t('Readinglist.add-books')}
                            </div>
                        </Link>
                    )}
                </div>
            </ContentStyler>
        </Block>
    );
};

ManageReadingListHeader.propTypes = {
    t: PropTypes.func.isRequired,
    manageReadingList: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    manageReadingListBookCount: PropTypes.number.isRequired,
    BookAddedToList: PropTypes.arrayOf(PropTypes.object).isRequired,
    disable: PropTypes.bool.isRequired,
    setDisable: PropTypes.func.isRequired,
    manageReadingListFunc: PropTypes.func.isRequired,
};

export default ManageReadingListHeader;
