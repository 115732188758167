import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PublicationForm.scss';
import { translate } from 'react-polyglot';
import { editions, orientations } from '../../lib/constants';
import { BookPublicationForm, OfflineStory } from '../../api';
import BookUploadFooter from '../BookUploadFooter';
import Authors from './components/Authors';
import Illustrators from './components/Illustrators';
import 'react-select/dist/react-select.css';
import Translators from './components/Translators';
import DataLoader from '../DataLoader/DataLoader';
import { links } from '../../lib/constants';
import withRouter from '../../util/withRouter';
import { isRightToLeftDirection } from '../../util/utils';
import PublicationHeader from './components/PublicationHeader';
import BookData from './components/BookData';
import BookMetaData from './components/BookMetaData';
import ReadingLevel from './components/ReadingLevel';
import Category from './components/Category';

const PublicationForm = ({
    t,
    navigate,
    location,
    isStoryUpload,
    isStoryEdit,
    isNewEdition,
}) => {
    const baseClassName = 'pb-upload-book-details';

    const storedStatus = localStorage.getItem('status');
    const loginStatus =
        storedStatus !== 'undefined' ? JSON.parse(storedStatus) : false;
    const tags_message = 'Please ensure each tag is separated by a comma ( , )';

    const [englishTitle, setEnglishTitle] = useState('');
    const [editionType, setEditionType] = useState('');
    const [title, setTitle] = useState('');
    const [languages, setLanguages] = useState([
        { id: '', name: '', locale: '' },
    ]);
    const [languageId, setLanguageId] = useState('');
    const [orientation, setOrientation] = useState('');
    const [copyRightYears, setCopyRightYears] = useState([
        { name: '', queryValue: '' },
    ]);
    const [copyRightYear, setCopyRightYear] = useState('');
    const [licenseTypes, setLicenseTypes] = useState([{ id: '', name: '' }]);
    const [licenseTypeId, setLicenseTypeId] = useState('');
    const [tagsData, setTagsData] = useState(''); // individual search term
    const [synopsis, setSynopsis] = useState('');
    const [publishers, setPublishers] = useState([
        { id: '', organization_name: '' },
    ]);
    const [organizationId, setOrganizationId] = useState('');
    const [copyRightHolderId, setCopyRightHolderId] = useState('');
    const [donors, setDonors] = useState([{ id: '', name: '' }]);
    const [donorId, setDonorId] = useState('');
    const [creditLine, setCreditLine] = useState('');
    const [readingLevel, setReadingLevel] = useState('');
    const [categoryTextValue, setCategoryTextValue] = useState([]);
    const [categoryIdValue, setCategoryIdValue] = useState([]);
    const [offlineAuthorIds, setOfflineAuthorIds] = useState([]);
    const [offlineIllustratorIds, setOfflineIllustratorIds] = useState([]);
    const [offlineTranslatorIds, setOfflineTranslatorIds] = useState([]);
    const [stories, setStories] = useState([]);
    const [closeDropdown, setCloseDropdown] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [offlineAuthors, setOfflineAuthors] = useState([]);
    const [offlineIllustrators, setOfflineIllustrators] = useState([]);
    const [offlineTranslators, setOfflineTranslators] = useState([]);
    const [isLoadData, setIsLoadData] = useState(false);
    const [existingTitle, setExistingTitle] = useState('');
    const [existingSynopsis, setExistingSynopsis] = useState('');
    const [projectIds, setProjectIds] = useState([]);
    const [filteredProjectIds, setFilteredProjectIds] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [projectIdDropDown, setProjectIdDropDown] = useState(false);
    const [bookId, setBookId] = useState('');
    const [displayMessages, setDisplayMessages] = useState([]);
    const [isSIDPresent, setIsSIDPresent] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (!loginStatus) {
            navigate(links.fetchTabLogin(`details`));
            window.location.reload();
        } else {
            if (isNewEdition) {
                OfflineStory.fetchOfflineStoryDetail(
                    location.pathname.split('/')[2]
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        const data = response.data;
                        setIsLoadData(true);
                        setExistingTitle(data.english_title);
                        setEnglishTitle(data.english_title);
                        setExistingSynopsis(data.synopsis);
                        setOfflineAuthors(data.authors);
                        setOfflineIllustrators(data.illustrators);
                        setOfflineTranslators(data.translators);
                        assignAuthorId(data.authors);
                        assignIllustratorId(data.illustrators);
                    });
            } else if (isStoryEdit) {
                OfflineStory.fetchOfflineStoryDetail(
                    location.pathname.split('/')[2]
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        const data = response.data;
                        // setIsLoadData(true);
                        setEnglishTitle(
                            data.english_title != null ? data.english_title : ''
                        );
                        setEditionType(
                            data.edition_type != null ? data.edition_type : ''
                        );
                        setTitle(data.title);
                        setLanguageId(data.language.id.toString());
                        setOrientation(
                            data.orientation != null ? data.orientation : ''
                        );
                        setCopyRightYear(data.copy_right_year.toString());
                        setLicenseTypeId(data.license_type.id.toString());
                        setTagsData(data.tag_list);
                        setSynopsis(data.synopsis);
                        setOrganizationId(
                            data.publishers.id != null
                                ? data.publishers.id.toString()
                                : ''
                        );
                        setCopyRightHolderId(
                            data.copy_right_holder.id != null
                                ? data.copy_right_holder.id.toString()
                                : ''
                        );
                        setDonorId(data.donor != null ? data.donor.id : '');
                        setCreditLine(data.dedication_language);
                        setReadingLevel(data.level != null ? data.level : '');
                        setOfflineAuthors(data.authors);
                        setOfflineIllustrators(data.illustrators);
                        setOfflineTranslators(data.translators);
                        setProjectId(
                            data.salesforceProjectID != null
                                ? data.salesforceProjectID
                                : ''
                        );
                        setBookId(data.id);
                        setIsSIDPresent(data.isSIDPresent);

                        assignCategoryValue(data.categories);
                        assignAuthorId(data.authors);
                        assignIllustratorId(data.illustrators);
                        assignTranslatorId(data.translators);

                        BookPublicationForm.categories(
                            data.language.id.toString()
                        )
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                const data = response.categories;
                                setCategories(data);
                            });
                    });
            } else {
                setIsLoadData(true);
            }
            BookPublicationForm.languages()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.languages;
                    setLanguages((prev) => prev.concat(data));
                });
            BookPublicationForm.licence_type()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.license_types;
                    setLicenseTypes((prev) => prev.concat(data));
                });
            BookPublicationForm.publishers()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.publishers;
                    setPublishers((prev) => prev.concat(data));
                });
            BookPublicationForm.donors()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.donors;
                    setDonors((prev) => prev.concat(data));
                });
            BookPublicationForm.copy_right_years()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.copy_right_years;
                    setCopyRightYears((prev) => prev.concat(data));
                });
            // fetch the project Ids using normal api (remove login and token retrival)
            BookPublicationForm.salesForceIds()
                .then((response) => {
                    return response.json();
                })
                .then((projectId) => {
                    setIsLoadData(true);
                    setProjectIds(projectId.data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, []);

    const assignAuthorId = (value) => {
        value.map((author) => {
            return setOfflineAuthorIds((prevOfflineAuthorIds) => [
                ...prevOfflineAuthorIds,
                author.id,
            ]);
        });
    };

    const assignIllustratorId = (value) => {
        value.map((illustrator) => {
            return setOfflineIllustratorIds((prevOfflineAuthorIds) => [
                ...prevOfflineAuthorIds,
                illustrator.id,
            ]);
        });
    };

    const assignCategoryValue = (value) => {
        value.forEach((category) => {
            setCategoryTextValue((prev) => [...prev, category.name]);
            setCategoryIdValue((prev) => [...prev, category.id]);
        });
    };

    const assignTranslatorId = (value) => {
        value.map((translator) =>
            setOfflineTranslatorIds((prev) => [...prev, translator.id])
        );
    };

    const handleSubmit = () => {
        const params = {
            title: title,
            english_title: englishTitle,
            edition_type: editionType,
            language_id: languageId,
            copy_right_year: copyRightYear,
            license_type_id: licenseTypeId,
            tag_list: tagsData,
            synopsis: synopsis,
            organization_id: organizationId,
            copy_right_holder_id: copyRightHolderId,
            donor_id: donorId,
            credit_line: creditLine,
            reading_level: readingLevel,
            category_ids: categoryIdValue,
            orientation: orientation,
            offline_author_ids: offlineAuthorIds,
            offline_illustrator_ids: offlineIllustratorIds,
            offline_translator_ids: offlineTranslatorIds,
            salesforce_project_id: projectId,
        };

        if (isNewEdition) {
            BookPublicationForm.createStory(params)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if (response.errors) {
                        window.scrollTo(0, 30);
                        setDisplayMessages(response.errors);
                    } else if (response.data.story.id) {
                        navigate(`/upload/${response.data.story.id}`);
                    }
                });
        } else if (isStoryEdit) {
            BookPublicationForm.updateStory(
                params,
                location.pathname.split('/')[2]
            )
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if (response.errors) {
                        window.scrollTo(0, 30);
                        setDisplayMessages(response.errors);
                    } else if (response.data.story.id) {
                        navigate(`/upload/${response.data.story.id}`);
                    }
                });
        } else {
            BookPublicationForm.createStory(params)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if (response.errors) {
                        window.scrollTo(0, 30);
                        setDisplayMessages(response.errors);
                    } else if (response.data.story.id) {
                        navigate(`/upload/${response.data.story.id}`);
                    }
                });
        }
    };

    const isFormValid = () => {
        setIsValidForm(
            (englishTitle.length > 0 || existingTitle.length > 0) &&
                editionType.length > 0 &&
                (bookId ? projectId.length >= 0 : projectId.length > 0) &&
                title != null &&
                title.length > 0 &&
                languageId.length > 0 &&
                orientation.length > 0 &&
                licenseTypeId.length > 0 &&
                copyRightYear.length > 0 &&
                tagsData.length > 0 &&
                synopsis != null &&
                synopsis.length > 0 &&
                organizationId.length > 0 &&
                readingLevel != null &&
                readingLevel &&
                readingLevel.length > 0 &&
                categoryIdValue.length > 0 &&
                offlineAuthorIds.length > 0 &&
                offlineIllustratorIds.length > 0 &&
                (editionType === 'adaptation'
                    ? offlineTranslatorIds.length > 0
                    : true)
        );
    };

    const onSearchTitle = (field, e, setter) => {
        // eslint-disable-next-line no-useless-escape
        const re = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+|[\b]+$/g;
        if (e.target.value === '' || re.test(e.target.value)) {
            setter(e.target.value);
            setCloseDropdown(true);
        }

        BookPublicationForm.searchEnglishTitle(e.target.value)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setStories(response.stories);
                setCloseDropdown(true);
            });
    };

    const onDescriptionChange = (field, e, setter) => {
        setter(e.target && e.target.value ? e.target.value : e);
        setCloseDropdown(false);
        setIsLoadData(false);

        isFormValid();

        BookPublicationForm.prefillData(
            e.target && e.target.value ? e.target.value : e
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setOfflineAuthors(response.data.authors);
                setOfflineIllustrators(response.data.illustrators);
                setOfflineTranslators(response.data.translators);
                setIsLoadData(true);

                assignAuthorId(response.data.authors);
                assignIllustratorId(response.data.illustrators);
                assignTranslatorId(response.data.translators);
            });
    };

    const handleSelect = (field, value, setter) => {
        setter(value);
        isFormValid();

        if (field === 'languageId') {
            BookPublicationForm.categories(value)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.categories;
                    setCategories(data);
                    isFormValid();
                });
        }
    };

    const onSearchProjectId = (e) => {
        const userInput = e.target.value;
        const filteredIds = projectIds.filter(
            (project) =>
                project.toUpperCase().indexOf(userInput.toUpperCase()) > -1
        );

        setProjectId(userInput);
        setFilteredProjectIds(filteredIds);
        setProjectIdDropDown(true);
        isFormValid();
    };

    const onSelectProjectId = (e) => {
        setProjectId(e.target && e.target.value ? e.target.value : e);
        setProjectIdDropDown(false);
        setIsLoadData(false);
        isFormValid();

        BookPublicationForm.salesForceData(
            e.target && e.target.value ? e.target.value : e
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                const projectData = response.data;
                setIsLoadData(true);
                setEnglishTitle(
                    projectData.english_title != null
                        ? projectData.english_title
                        : ''
                );
                setEditionType(
                    projectData.edition_type != null
                        ? projectData.edition_type
                        : ''
                );
                setTitle(projectData.title);
                setLanguageId(
                    projectData.language != null
                        ? projectData.language.id.toString()
                        : ''
                );
                setSynopsis(projectData.synopsis);
                setReadingLevel(
                    projectData.level != null ? projectData.level : ''
                );
                setOfflineAuthors(projectData.authors);
                setOfflineIllustrators(projectData.illustrators);
                setOfflineTranslators(projectData.translators);

                languageId &&
                    BookPublicationForm.categories(languageId)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            const data = response.categories;
                            setCategories(data);
                        });
                assignAuthorId(projectData.authors);
                assignIllustratorId(projectData.illustrators);
                assignTranslatorId(projectData.translators);
            });
    };

    const onTitleChange = (e, setter) => {
        setter(e.target.value);
        isFormValid();
    };

    const onTagsInput = (e) => {
        setTagsData(e.target.value);
        isFormValid();
    };

    const onSummaryChange = (e, setter) => {
        setter(e.target.value);
        isFormValid();
    };

    const handleRadio = (level) => {
        setReadingLevel(level);
        isFormValid();
    };

    const limitList = (list) => {
        let result = '';

        if (list.length > 0 && list.length <= 2) {
            result = list.join(', ');
        }

        if (list.length > 2) {
            result = `${list[0]}, ${list[1]} + ${list.length - 2}`;
        }

        return result;
    };

    const handlePicklistChange = (type, value, setter) => {
        const index = type.indexOf(value);
        const id = categories.filter((v) => v.queryValue === value);

        if (index !== -1) {
            // item found

            setCategoryIdValue((prev) =>
                prev.filter((style) => style !== id[0].id)
            );
            setter((prev) => prev.filter((style) => style !== value));

            isFormValid();
        } else {
            setCategoryIdValue((prev) => prev.concat([id[0].id]));
            setter((prev) => prev.concat([value]));
            isFormValid();
        }
    };

    const onAddAuthorId = (value, status) => {
        if (status === 'add') {
            setOfflineAuthorIds((prev) => [...prev, value]);
            isFormValid();
        } else {
            setOfflineAuthorIds((prev) => prev.filter((c) => c !== value));
            isFormValid();
        }
    };

    const onAddIllustratorId = (value, status) => {
        if (status === 'add') {
            setOfflineIllustratorIds((prev) => [...prev, value]);
            isFormValid();
        } else {
            setOfflineIllustratorIds((prev) => prev.filter((c) => c !== value));
            isFormValid();
        }
    };

    const onAddTranslatorId = (value, status) => {
        if (status === 'add') {
            setOfflineTranslatorIds((prev) => [...prev, value]);
            isFormValid();
        } else {
            setOfflineTranslatorIds((prev) => prev.filter((c) => c !== value));
            isFormValid();
        }
    };

    const nextPage = () => {
        handleSubmit();
    };

    return (
        <div>
            {isLoadData ? (
                <div
                    onClick={() => {
                        setCloseDropdown(false);
                        setProjectIdDropDown(false);
                    }}
                >
                    <PublicationHeader
                        t={t}
                        baseClassName={baseClassName}
                        displayMessages={displayMessages}
                    />
                    <form onSubmit={() => handleSubmit()}>
                        {existingTitle && existingTitle.length > 0 && (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}_existing-title-rtl`
                                        : `${baseClassName}_existing-title`
                                }
                            >
                                <div style={{ fontWeight: '800' }}>
                                    {t('PublicationForm.originalStoryTitle')}
                                </div>
                                <div>{existingTitle}</div>
                            </div>
                        )}
                        <BookData
                            t={t}
                            baseClassName={baseClassName}
                            existingTitle={existingTitle}
                            projectId={projectId}
                            onSearchTitle={onSearchTitle}
                            setEnglishTitle={setEnglishTitle}
                            englishTitle={englishTitle}
                            closeDropdown={closeDropdown}
                            stories={stories}
                            onDescriptionChange={onDescriptionChange}
                            editions={editions}
                            handleSelect={handleSelect}
                            setEditionType={setEditionType}
                            editionType={editionType}
                            bookId={bookId}
                            isSIDPresent={isSIDPresent}
                            onSearchProjectId={onSearchProjectId}
                            projectIdDropDown={projectIdDropDown}
                            filteredProjectIds={filteredProjectIds}
                            onSelectProjectId={onSelectProjectId}
                            onTitleChange={onTitleChange}
                            setTitle={setTitle}
                            title={title}
                            languages={languages}
                            setLanguageId={setLanguageId}
                            languageId={languageId}
                            orientations={orientations}
                            setOrientation={setOrientation}
                            orientation={orientation}
                        />
                        <BookMetaData
                            t={t}
                            baseClassName={baseClassName}
                            copyRightYears={copyRightYears}
                            handleSelect={handleSelect}
                            setCopyRightYear={setCopyRightYear}
                            copyRightYear={copyRightYear}
                            licenseTypes={licenseTypes}
                            setLicenseTypeId={setLicenseTypeId}
                            licenseTypeId={licenseTypeId}
                            onTagsInput={onTagsInput}
                            tagsData={tagsData}
                            tags_message={tags_message}
                            existingSynopsis={existingSynopsis}
                            onSummaryChange={onSummaryChange}
                            setSynopsis={setSynopsis}
                            synopsis={synopsis}
                            publishers={publishers}
                            setOrganizationId={setOrganizationId}
                            organizationId={organizationId}
                            setCopyRightHolderId={setCopyRightHolderId}
                            copyRightHolderId={copyRightHolderId}
                            donors={donors}
                            setDonorId={setDonorId}
                            donorId={donorId}
                            creditLine={creditLine}
                            setCreditLine={setCreditLine}
                        />
                        <ReadingLevel
                            t={t}
                            baseClassName={baseClassName}
                            readingLevel={readingLevel}
                            handleRadio={handleRadio}
                        />
                        {languageId.length > 0 && (
                            <Category
                                t={t}
                                baseClassName={baseClassName}
                                limitList={limitList}
                                categoryTextValue={categoryTextValue}
                                categories={categories}
                                handlePicklistChange={handlePicklistChange}
                                setCategoryTextValue={setCategoryTextValue}
                            />
                        )}
                        <div style={{ margin: '30px 0' }}>
                            <Authors
                                t={t}
                                title={t('PublicationForm.author')}
                                subHeading={t(
                                    'PublicationForm.authorSubHeading'
                                )}
                                isRightToLeftDirection={isRightToLeftDirection}
                                btnLabel={t('PublicationForm.addAuthor')}
                                offlineAuthorIds={onAddAuthorId}
                                offlineAuthorData={offlineAuthors}
                                projectId={projectId}
                            />
                        </div>
                        <div style={{ margin: '30px 0' }}>
                            <Illustrators
                                t={t}
                                title={t('PublicationForm.illustrator')}
                                subHeading={t(
                                    'PublicationForm.illustratorSubHeading'
                                )}
                                isRightToLeftDirection={isRightToLeftDirection}
                                btnLabel={t('PublicationForm.addIllustrator')}
                                offlineIllustratorIds={onAddIllustratorId}
                                offlineIllustratorData={offlineIllustrators}
                                projectId={projectId}
                            />
                        </div>
                        {editionType === 'adaptation' && (
                            <div style={{ margin: '30px 0' }}>
                                <Translators
                                    t={t}
                                    title={t('PublicationForm.translator')}
                                    subHeading={t(
                                        'PublicationForm.translatorSubHeading'
                                    )}
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                    btnLabel={t(
                                        'PublicationForm.addTranslator'
                                    )}
                                    offlineTranslatorIds={onAddTranslatorId}
                                    offlineTranslatorData={offlineTranslators}
                                    projectId={projectId}
                                />
                            </div>
                        )}
                    </form>
                    <BookUploadFooter
                        disable={!isValidForm}
                        nextPage={nextPage}
                    />
                </div>
            ) : (
                <div className={`${baseClassName}__book_loader`}>
                    <DataLoader />
                </div>
            )}
        </div>
    );
};

PublicationForm.propTypes = {
    t: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    isStoryUpload: PropTypes.bool,
    isStoryEdit: PropTypes.bool,
    isNewEdition: PropTypes.bool,
};

export default withRouter(translate()(PublicationForm));
