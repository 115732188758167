import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import close from '../../../assets/close.png';
import Modal from '../../Modal';
import Link from '../../Link';
import List from '../../List';
import Dropdown from '../../Dropdown';
import Caret from '../../Caret';
import Picklist from '../../Picklist';

const FilterModal = ({
    t,
    classes,
    baseClassName,
    modalFilterClose,
    readAloud,
    shouldDisplayCategorySelector,

    categoryFilterGTM,
    categorySearchValue,
    updateCategorySearchValue,
    categoryOptions,
    roleOptions,
    languageOptions,
    handlePicklistChange,
    filtersBarFilters,
    hideLevelSelector,

    levelSearchValue,
    updateLevelSearchValue,
    levelOptions,
    shouldDisplayRoleSelector,
    roleFilterGTM,
    shouldDisplayLanguageSelector,
    hideLanguageSelector,

    languageFilterGTM,
    displayTypeSelector,
    typeOptions,
}) => {
    return (
        <div className={classNames(classes)}>
            <Modal>
                <div className={`${baseClassName}__close-modal`}>
                    <Link onClick={() => modalFilterClose()}>
                        <img className="__close-img" src={close} alt="" />
                    </Link>
                </div>
                <div
                    className={`${baseClassName}__actions ${baseClassName}__actions--primary`}
                >
                    <div className={`${baseClassName}__section-label-filter`}>
                        {t('global.filter-by')}
                    </div>
                    <List pushRight>
                        {!readAloud ? (
                            shouldDisplayCategorySelector ? (
                                <Dropdown
                                    bookFilter
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlackResponsive
                                            dataGTM={categoryFilterGTM}
                                        >
                                            <div
                                                className="__option-values-media"
                                                data-gtm={categoryFilterGTM}
                                            >
                                                {t('global.category', 1)}{' '}
                                                <Caret
                                                    variant="twilight-lavender"
                                                    direction="down"
                                                />
                                            </div>
                                        </Link>
                                    }
                                >
                                    <div>
                                        <Picklist
                                            id="filers-bar-categories"
                                            searchValue={categorySearchValue}
                                            onSearchChange={
                                                updateCategorySearchValue
                                            }
                                            options={categoryOptions}
                                            onChange={(value, checked) =>
                                                handlePicklistChange(
                                                    'category',
                                                    value,
                                                    checked
                                                )
                                            }
                                            checkedValues={
                                                filtersBarFilters.category
                                            }
                                        />
                                    </div>
                                </Dropdown>
                            ) : null
                        ) : null}

                        {!hideLevelSelector && (
                            <Dropdown
                                bookFilter
                                align="left"
                                toggleEl={
                                    <Link lightBlackResponsive>
                                        <div className="__option-values-media">
                                            {t('global.level', 1)}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </div>
                                    </Link>
                                }
                            >
                                <Picklist
                                    id="filers-bar-levels"
                                    searchValue={levelSearchValue}
                                    onSearchChange={updateLevelSearchValue}
                                    options={levelOptions}
                                    onChange={(value, checked) =>
                                        handlePicklistChange(
                                            'level',
                                            value,
                                            checked
                                        )
                                    }
                                    checkedValues={filtersBarFilters.level}
                                    hideSearchBar
                                    fontWeight="bold"
                                />
                            </Dropdown>
                        )}

                        {shouldDisplayRoleSelector ? (
                            <Dropdown
                                align="left"
                                toggleEl={
                                    <Link
                                        lightBlackResponsive
                                        dataGTM={roleFilterGTM}
                                    >
                                        <div
                                            className="__option-values-media"
                                            data-gtm={roleFilterGTM}
                                        >
                                            {t('global.role')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </div>
                                    </Link>
                                }
                            >
                                <Picklist
                                    options={roleOptions}
                                    onChange={(value, checked) =>
                                        handlePicklistChange(
                                            'role',
                                            value,
                                            checked
                                        )
                                    }
                                    checkedValues={filtersBarFilters.role}
                                    hideSearchBar
                                    fontWeight="bold"
                                />
                            </Dropdown>
                        ) : null}
                        {shouldDisplayLanguageSelector &&
                        !hideLanguageSelector ? (
                            <Dropdown
                                align="left"
                                toggleEl={
                                    <Link
                                        lightBlackResponsive
                                        dataGTM={languageFilterGTM}
                                    >
                                        <div
                                            className="__option-values-media"
                                            data-gtm={languageFilterGTM}
                                        >
                                            {t('Book.read-other-language')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </div>
                                    </Link>
                                }
                            >
                                <Picklist
                                    options={languageOptions}
                                    onChange={(value, checked) =>
                                        handlePicklistChange(
                                            'language',
                                            value,
                                            checked
                                        )
                                    }
                                    checkedValues={filtersBarFilters.language}
                                    hideSearchBar
                                    fontWeight="bold"
                                />
                            </Dropdown>
                        ) : null}
                        {displayTypeSelector ? (
                            <Dropdown
                                align="left"
                                toggleEl={
                                    <Link lightBlackResponsive>
                                        <div className="__option-values-media">
                                            {t('global.type')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </div>
                                    </Link>
                                }
                            >
                                <Picklist
                                    options={typeOptions}
                                    onChange={(value, checked) =>
                                        handlePicklistChange(
                                            'read_alouds',
                                            value,
                                            checked
                                        )
                                    }
                                    checkedValues={
                                        filtersBarFilters.read_alouds
                                    }
                                    hideSearchBar
                                    fontWeight="bold"
                                />
                            </Dropdown>
                        ) : null}
                    </List>
                </div>
            </Modal>
        </div>
    );
};

FilterModal.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.string,
    baseClassName: PropTypes.string.isRequired,
    modalFilterClose: PropTypes.func.isRequired,
    readAloud: PropTypes.bool,
    shouldDisplayCategorySelector: PropTypes.bool,
    categoryFilterGTM: PropTypes.string,
    categorySearchValue: PropTypes.string,
    updateCategorySearchValue: PropTypes.func,
    categoryOptions: PropTypes.arrayOf(PropTypes.object),
    roleOptions: PropTypes.arrayOf(PropTypes.object),
    languageOptions: PropTypes.arrayOf(PropTypes.object),
    handlePicklistChange: PropTypes.func.isRequired,
    filtersBarFilters: PropTypes.shape({
        category: PropTypes.arrayOf(PropTypes.string),
        level: PropTypes.arrayOf(PropTypes.string),
        role: PropTypes.arrayOf(PropTypes.string),
        language: PropTypes.arrayOf(PropTypes.string),
        read_alouds: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    hideLevelSelector: PropTypes.bool,
    levelSearchValue: PropTypes.string,
    updateLevelSearchValue: PropTypes.func,
    levelOptions: PropTypes.arrayOf(PropTypes.object),
    shouldDisplayRoleSelector: PropTypes.bool,
    roleFilterGTM: PropTypes.string,
    shouldDisplayLanguageSelector: PropTypes.bool,
    hideLanguageSelector: PropTypes.bool,
    languageFilterGTM: PropTypes.string,
    displayTypeSelector: PropTypes.bool,
    typeOptions: PropTypes.arrayOf(PropTypes.object),
};

export default FilterModal;
