import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { profileTypes } from '../lib/constants';
import AppContainer from '../components/AppContainer';
import NotFoundPage from '../components/NotFoundPage';
import AllBooksContainer from '../components/AllBooksContainer';
import HomeContainer from '../components/HomeContainer';
import BookContainer from '../components/BookContainer';
import RTRBookReaderContainer from '../components/RTRBookReaderContainer';
import ProfileContainer from '../components/ProfileContainer';
import ReadingListContainer from '../components/ReadingListContainer';
import ManageReadingListContainer from '../components/ManageReadingList';
import MyReadingListDetailContainer from '../components/MyReadingListDetailContainer';
import CreateNewReadingList from '../components/CreateNewReadingList';
import ThemedReadingListsDetailContainer from '../components/ThemedReadingListsDetailContainer';
import AddBooksContainer from '../components/AddBooksContainer';
import VideoContainer from '../components/VideoContainer';
import VideoDetailContainer from '../components/VideoDetailContainer';
import ResourceContainer from '../components/ResourceContainer';
import ResourceDetailContainer from '../components/ResourceDetailContainer';
import PublicationFormContainer from '../components/PublicationFormContainer';
import BookUpload from '../components/BookUpload';
import BookPreview from '../components/BookPreview';
import BookPublication from '../components/BookPublication';
import ReadAloudsContainer from '../components/ReadAloudsContainer';
import ReadAloudsDetailContainer from '../components/ReadAloudsDetailContainer';
import MyFavouritesContainer from '../components/MyFavouritesContainer';
import OfflineBooksContainer from '../components/OfflineBooksContainer';
import useScrollToTop from '../hooks/useScrollToTop';
import { ROUTES } from '../lib/frontendRoutes';

const RouteWithOfflineRedirect = ({ element }) => {
    return window.navigator.onLine ? (
        element
    ) : (
        <Navigate to="/offline" replace />
    );
};

const Router = () => {
    useScrollToTop();
    return (
        <AppContainer>
            <Routes>
                <Route
                    path={ROUTES.HOME}
                    element={
                        <RouteWithOfflineRedirect element={<HomeContainer />} />
                    }
                />
                <Route
                    path={ROUTES.BOOKS}
                    element={
                        <RouteWithOfflineRedirect
                            element={<AllBooksContainer />}
                        />
                    }
                />
                <Route path={ROUTES.BOOK_DETAIL} element={<BookContainer />} />
                <Route
                    path={ROUTES.READ_STORY_PAGE}
                    element={<RTRBookReaderContainer />}
                />
                <Route path={ROUTES.READ_STORY} element={<BookContainer />} />
                <Route
                    path={ROUTES.THEMED_READING_LIST}
                    element={<ThemedReadingListsDetailContainer />}
                />
                <Route
                    path={ROUTES.MANAGE_READING_LIST}
                    element={<NotFoundPage />}
                />
                <Route
                    path={ROUTES.RECOMMENDED_STORIES}
                    element={
                        <RouteWithOfflineRedirect
                            element={<AllBooksContainer recommendedBooks />}
                        />
                    }
                />
                <Route
                    path={ROUTES.TRENDING_STORIES}
                    element={
                        <RouteWithOfflineRedirect
                            element={<AllBooksContainer trendingBooks />}
                        />
                    }
                />
                <Route
                    path={ROUTES.GLOBAL_SEARCH}
                    element={
                        <RouteWithOfflineRedirect
                            element={
                                <AllBooksContainer searchPage isSearchVariant />
                            }
                        />
                    }
                />
                <Route
                    path={ROUTES.CREDITS_PAGE}
                    element={
                        <RouteWithOfflineRedirect
                            element={
                                <ProfileContainer
                                    profileType={profileTypes.USER}
                                />
                            }
                        />
                    }
                />
                <Route
                    path={ROUTES.PUBLISHER_PAGE}
                    element={
                        <RouteWithOfflineRedirect
                            element={
                                <ProfileContainer
                                    profileType={profileTypes.PUBLISHER}
                                />
                            }
                        />
                    }
                />
                <Route
                    path={ROUTES.READING_LISTS}
                    element={
                        <RouteWithOfflineRedirect
                            element={<ReadingListContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.MY_READING_LIST}
                    element={
                        <RouteWithOfflineRedirect
                            element={<ReadingListContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.MY_READING_LIST_BOOK}
                    element={
                        <RouteWithOfflineRedirect
                            element={<ManageReadingListContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.MY_READING_LIST_DETAIL}
                    element={
                        <RouteWithOfflineRedirect
                            element={<MyReadingListDetailContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.CREATE_READING_LIST}
                    element={
                        <RouteWithOfflineRedirect
                            element={<CreateNewReadingList />}
                        />
                    }
                />
                <Route
                    path={ROUTES.READING_LIST}
                    element={
                        <RouteWithOfflineRedirect
                            element={<ThemedReadingListsDetailContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.ADD_BOOKS_TO_READING_LIST}
                    element={
                        <RouteWithOfflineRedirect
                            element={<AddBooksContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.VIDEOS}
                    element={
                        <RouteWithOfflineRedirect
                            element={<VideoContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.VIDEO_DETAIL}
                    element={
                        <RouteWithOfflineRedirect
                            element={<VideoDetailContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.RESOURCES}
                    element={
                        <RouteWithOfflineRedirect
                            element={<ResourceContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.RESOURCE_DETAIL}
                    element={
                        <RouteWithOfflineRedirect
                            element={<ResourceDetailContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.READ_ALOUDS}
                    element={<ReadAloudsContainer />}
                />
                <Route
                    path={ROUTES.READ_ALOUDS_DETAIL}
                    element={<ReadAloudsDetailContainer />}
                />
                <Route
                    path={ROUTES.MY_FAVOURITES}
                    element={
                        <RouteWithOfflineRedirect
                            element={<MyFavouritesContainer />}
                        />
                    }
                />
                <Route
                    path={ROUTES.OFFLINE_LIBRARY}
                    element={<OfflineBooksContainer />}
                />
                <Route
                    path={ROUTES.RESET_PASSWORD}
                    element={<Navigate to="/" />}
                />

                {/* Conditional Routes for Desktop */}
                {window.screen.width > 768 && (
                    <React.Fragment>
                        <Route
                            path={ROUTES.STORY_UPLOAD_PUBLICATION_FORM}
                            element={<PublicationFormContainer isStoryUpload />}
                        />
                        <Route
                            path={ROUTES.EDIT_STORY_PUBLICATION_FORM}
                            element={<PublicationFormContainer isStoryEdit />}
                        />
                        <Route
                            path={ROUTES.EDIT_STORY_UPLOAD_FORM}
                            element={<BookUpload isEdit />}
                        />
                        <Route
                            path={ROUTES.EDIT_STORY_PREVIEW}
                            element={<BookPreview />}
                        />
                        <Route
                            path={ROUTES.EDIT_STORY_PUBLICATION}
                            element={<BookPublication />}
                        />
                        <Route
                            path={ROUTES.NEW_EDITION}
                            element={<PublicationFormContainer isNewEdition />}
                        />
                    </React.Fragment>
                )}

                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </AppContainer>
    );
};

export default Router;
