import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import BlankSlate from '../../BlankSlate';
import { profileTypes } from '../../../lib/constants';
import UserBooks from './UserBooks';
import UserList from './UserList';
import PublisherBooks from './PublisherBooks';

const Tabs = ({
    title,
    url,
    type,
    profile,
    isCurrentUser,
    t,
    baseClassName,
    hitsCount,
    filters,
    applyFilter,
    removeFilter,
    appliedFilters,
    isFetchingPublisherProfile,
    myLanguage,
}) => {
    const tabs =
        type === profileTypes.USER
            ? [
                  <UserBooks
                      profile={profile}
                      isCurrentUser={isCurrentUser}
                      t={t}
                      title={title}
                      url={url}
                  />,
                  <UserList
                      profile={profile}
                      isCurrentUser={isCurrentUser}
                      t={t}
                      baseClassName={baseClassName}
                      title={title}
                      url={url}
                  />,
              ]
            : [
                  type === profileTypes.PUBLISHER && (
                      <PublisherBooks
                          t={t}
                          hitsCount={hitsCount}
                          profile={profile}
                          filters={filters}
                          applyFilter={applyFilter}
                          removeFilter={removeFilter}
                          appliedFilters={appliedFilters}
                          isFetchingPublisherProfile={
                              isFetchingPublisherProfile
                          }
                          myLanguage={myLanguage}
                      />
                  ),
              ];

    return (
        <Block noHorizontalPadding>
            {tabs.some((item) => item != null) ? (
                <div>{tabs}</div>
            ) : (
                <BlankSlate title={title} url={url} />
            )}
        </Block>
    );
};

Tabs.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf([profileTypes.USER, profileTypes.PUBLISHER])
        .isRequired,
    profile: PropTypes.object.isRequired,
    isCurrentUser: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    hitsCount: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    isFetchingPublisherProfile: PropTypes.bool.isRequired,
    myLanguage: PropTypes.string.isRequired,
};

export default Tabs;
