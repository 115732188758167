import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Rowifier.scss';

const Rowifier = ({
    children,
    align = 'left',
    separator,
    borderTop,
    borderBottom,
    noPadding = false,
}) => {
    const baseClassName = 'pb-rowifier';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--align-${align}`]: align,
        [`${baseClassName}--separator`]: separator,
        [`${baseClassName}--border-top`]: borderTop,
        [`${baseClassName}--border-bottom`]: borderBottom,
    };

    const divClassName = noPadding
        ? `${baseClassName}__row-no-padding`
        : `${baseClassName}__row`;

    return (
        <div className={classNames(classes)}>
            {React.Children.toArray(children).map((child, i) => (
                <div key={`row.${i}`} className={divClassName}>
                    {child}
                </div>
            ))}
        </div>
    );
};

Rowifier.propTypes = {
    children: PropTypes.node.isRequired,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    separator: PropTypes.bool,
    borderTop: PropTypes.bool,
    borderBottom: PropTypes.bool,
    noPadding: PropTypes.bool,
};

export default Rowifier;
