import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Favourites from '../Favourites';
import './ReadAloudsCard.scss';
import Block from '../Block';
import SvgIcon from '../SvgIcon';
import Link from '../Link';
import videoPlaceholder from '../../assets/placeholder.jpg';

import {
    favouriteReadAloudsWorkflow,
    fetchVideosDetailWorkflow,
} from '../../redux/readAloudsActions';
import { favouriteVideoWorkflowHome } from '../../redux/homeActions';
import { isRightToLeftDirection } from '../../util/utils';
import Thumbnail from './components/Thumbnail';

const ReadAloudsCard = ({
    t,
    isLoggedIn,
    isFavourite,
    videoID,
    parentClassName,
    videoReads,
    videoTitle,
    videoSlug,
    videoThumbnail,
    videoLikes,
    videoTime,
    videoTitleGTM,
    videoThumbnailGTM,
    isSimilarVideo,
    isFavouriting,
    myLanguage,
    favouriteReadAloudsWorkflow,
    favouriteSimilarVideoWorkflow,
}) => {
    const baseClassName = 'pb-readAloud-card';
    const videoPath = `/readaloudsdetail/${videoSlug}`;
    const similarVideoPath = `${videoSlug}`;

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const onFavoriteClicked = (id) => {
        if (isSimilarVideo) {
            favouriteSimilarVideoWorkflow(id);
        } else {
            favouriteReadAloudsWorkflow(id);
        }
    };

    return (
        <div className={classNames(classes)}>
            <Block
                noHorizontalPadding
                noVerticalPadding
                background="transparent"
            >
                <div className="__videocard-section">
                    <Link
                        isInternal
                        href={isSimilarVideo ? similarVideoPath : videoPath}
                        dataGTM={videoThumbnailGTM}
                    >
                        <div data-gtm={videoThumbnailGTM}>
                            <div
                                className="__video-play"
                                data-gtm={videoThumbnailGTM}
                            >
                                <SvgIcon
                                    name="play"
                                    size="l"
                                    data-gtm={videoThumbnailGTM}
                                />
                            </div>
                            <div data-gtm={videoThumbnailGTM}>
                                <Thumbnail
                                    videoThumbnailGTM={videoThumbnailGTM}
                                    videoThumbnail={videoThumbnail}
                                    videoPlaceholder={videoPlaceholder}
                                />
                            </div>
                        </div>
                    </Link>
                    <div className="__video-timer">{videoTime}</div>
                    <div
                        className={
                            isRightToLeftDirection
                                ? '__View-like-count-arabic'
                                : '__View-like-count'
                        }
                    >
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}__like-count-arabic`
                                    : `${baseClassName}__like-count`
                            }
                        >
                            <Favourites
                                t={t}
                                isFavourite={isFavourite}
                                favouriteToggleHandler={() =>
                                    onFavoriteClicked(videoID)
                                }
                                isFavouriting={isFavouriting}
                                isLoggedIn={isLoggedIn}
                                logInMsg={t('global.please-log-in', {
                                    action: t('global.like', 1),
                                    content_type: t('global.story', 1),
                                })}
                                videoSlug={videoSlug}
                                likes={videoLikes}
                            />
                        </div>
                        <div>
                            <span>
                                <SvgIcon
                                    name="playIcon"
                                    size="sm"
                                    pushRight
                                    svgIcon
                                    playButton
                                />
                            </span>
                            <span>{videoReads}</span>
                        </div>
                    </div>
                    <Link
                        isInternal
                        href={isSimilarVideo ? similarVideoPath : videoPath}
                        dataGTM={videoTitleGTM}
                    >
                        <div
                            className={
                                myLanguage === 'lo'
                                    ? '__video-lo-title'
                                    : isRightToLeftDirection
                                    ? '__video-title __text-right-align'
                                    : '__video-title'
                            }
                            data-gtm={videoTitleGTM}
                        >
                            {videoTitle}
                        </div>
                    </Link>
                </div>
            </Block>
        </div>
    );
};

const mapDispatchToProps = {
    favouriteReadAloudsWorkflow,
    favouriteVideoWorkflowHome,
    fetchVideosDetailWorkflow,
};

const mapStateToProps = (state, ownProps) => {
    let requiredVideos;
    if (ownProps.isFromHome) {
        requiredVideos = state.home.videos;
    } else {
        requiredVideos = state.readAlouds.readAlouds.length
            ? state.readAlouds.readAlouds
            : state.readAlouds.favouriteReadAlouds;
    }

    const associatedindex = requiredVideos.findIndex(
        (video) => video.id === ownProps.videoID
    );
    let associatedVideo = null;
    if (associatedindex >= 0) {
        associatedVideo = requiredVideos[associatedindex];
    }
    return {
        isLoggedIn: state.user.isLoggedIn,
        isFavourite: associatedVideo
            ? associatedVideo.is_favourite
            : ownProps.isFavourite,
        isFavouriting: associatedVideo ? associatedVideo.isFavouriting : null,
    };
};

ReadAloudsCard.propTypes = {
    t: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isFavourite: PropTypes.bool.isRequired,
    videoID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    parentClassName: PropTypes.string,
    videoReads: PropTypes.number,
    videoTitle: PropTypes.string.isRequired,
    videoSlug: PropTypes.string.isRequired,
    videoThumbnail: PropTypes.string.isRequired,
    videoLikes: PropTypes.number,
    videoTime: PropTypes.string.isRequired,
    videoTitleGTM: PropTypes.string,
    videoThumbnailGTM: PropTypes.string,
    isSimilarVideo: PropTypes.bool,
    isFavouriting: PropTypes.bool,
    myLanguage: PropTypes.string,
    favouriteReadAloudsWorkflow: PropTypes.func.isRequired,
    favouriteSimilarVideoWorkflow: PropTypes.func,
};

ReadAloudsCard.defaultProps = {
    parentClassName: '',
    videoReads: 0,
    videoLikes: 0,
    videoTitleGTM: '',
    videoThumbnailGTM: '',
    isSimilarVideo: false,
    isFavouriting: false,
    myLanguage: 'en',
    favouriteSimilarVideoWorkflow: () => {},
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(ReadAloudsCard)
);
