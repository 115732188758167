import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isRightToLeftDirection } from '../../../util/utils';
import Caret from '../../Caret';

const UserInfoHeaderExpanded = ({
    mobileMenu,
    baseClassName,
    menuClasses,
    menuCloseHandler,
    title,
    avatar,
    caret,
}) => {
    return mobileMenu ? null : (
        <div className={classNames(menuClasses)} onClick={menuCloseHandler}>
            <div className={`${baseClassName}--menu-title`}>{title}</div>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}--menu-avatar-arabic`
                        : `${baseClassName}--menu-avatar`
                }
            >
                {avatar}
            </div>
            <div className={`${baseClassName}--menu-caret`}>
                <Caret direction={caret} variant={'white'} />
            </div>
        </div>
    );
};

UserInfoHeaderExpanded.propTypes = {
    mobileMenu: PropTypes.bool.isRequired,
    baseClassName: PropTypes.string.isRequired,
    menuClasses: PropTypes.object.isRequired,
    menuCloseHandler: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    caret: PropTypes.bool.isRequired,
};

export default UserInfoHeaderExpanded;
