import React from 'react';
import PropTypes from 'prop-types';

const Thumbnail = ({ videoThumbnailGTM, videoThumbnail, videoPlaceholder }) => {
    return (
        <div className="__video-image" data-gtm={videoThumbnailGTM}>
            <img
                src={
                    videoThumbnail === 'original/missing_video.png'
                        ? videoPlaceholder
                        : videoThumbnail
                }
                alt="Video Thumbnail Img"
                data-gtm={videoThumbnailGTM}
            />
        </div>
    );
};

Thumbnail.propTypes = {
    videoThumbnailGTM: PropTypes.string.isRequired,
    videoThumbnail: PropTypes.string.isRequired,
    videoPlaceholder: PropTypes.string.isRequired,
};

export default Thumbnail;
