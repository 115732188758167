import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import DownloadRange from '../../DownloadRange';
import { isRightToLeftDirection } from '../../../util/utils';

const VideoButton = ({
    t,
    videoModalOpen,
    isDownloadable,
    videosVideoDownloadGTM,
    dpi_url,
}) => {
    return (
        <div className="__video-btn-section">
            <div
                className={
                    isRightToLeftDirection
                        ? '__video-watch-btn-arabic'
                        : '__video-watch-btn'
                }
            >
                <Button
                    iconLeft="video"
                    buttonWatchBlue
                    fullWidth
                    label={t('Video.watch')}
                    variant="primary"
                    onClick={() => videoModalOpen()}
                    isRightToLeftDirection={isRightToLeftDirection}
                />
            </div>

            {isDownloadable ? (
                <div className="__video-download-btn">
                    <DownloadRange
                        dataGTM={videosVideoDownloadGTM}
                        dpi_url={dpi_url}
                    />
                </div>
            ) : (
                <div className="__video-download-disable-btn">
                    <DownloadRange
                        dataGTM={videosVideoDownloadGTM}
                        dpi_url={dpi_url}
                    />
                </div>
            )}
        </div>
    );
};

VideoButton.propTypes = {
    t: PropTypes.func.isRequired,
    videoModalOpen: PropTypes.func.isRequired,
    isDownloadable: PropTypes.bool.isRequired,
    videosVideoDownloadGTM: PropTypes.string.isRequired,
    dpi_url: PropTypes.shape({
        mobile: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                resolution: PropTypes.string.isRequired,
            })
        ),
        tablet: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                resolution: PropTypes.string.isRequired,
            })
        ),
        other: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                resolution: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
};

export default VideoButton;
