import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../Img';
import SvgIcon from '../../SvgIcon';

const GraphicEl = ({ icon, image, url, baseClassName, title }) => {
    const altTitle = `Graphic for ${title}`;

    let graphicEl;
    if (image) {
        graphicEl = <Img image={image} alt={altTitle} />;
    } else if (url) {
        graphicEl = <img src={url} alt={altTitle} />;
    } else if (icon) {
        graphicEl = <SvgIcon name={icon} size="xxl" />;
    } else {
        return null;
    }

    return <div className={`${baseClassName}__graphic`}>{graphicEl}</div>;
};

GraphicEl.propTypes = {
    icon: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    baseClassName: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default GraphicEl;
