import React from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './VideoPlayer.scss';

const VideoPlayer = ({ parentClassName, videoSrc, videoPlay, name }) => {
    const baseClassName = 'pb-video-player';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };
    return (
        <div className={classNames(classes)}>
            <ReactPlayer
                playing
                controls="true"
                title={name}
                autoPlay={videoPlay}
                url={videoSrc}
            />
        </div>
    );
};

VideoPlayer.propTypes = {
    parentClassName: PropTypes.string,
    videoSrc: PropTypes.string.isRequired,
    videoPlay: PropTypes.bool.isRequired,
    name: PropTypes.string,
};

export default VideoPlayer;
