import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Caret from '../Caret';
import Loader from '../Loader';
import { isRightToLeftDirection } from '../../util/utils';
import './SelectField.scss';

const SelectField = ({
    onChange,
    id,
    disabled,
    loading,
    label,
    name,
    value,
    options,
    theme,
    formField,
    error,
    parentClassName,
    publicationForm,
}) => {
    const baseClassName = 'pb-select-field';

    const [isFocused, setIsFocused] = useState(false);

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--label`]: label,
        [`${baseClassName}--focus`]: isFocused,
        [`${baseClassName}--theme-${theme}`]: theme,
        [`${baseClassName}--disabled`]: disabled,
        [`${baseClassName}--loading`]: loading,
        [`${baseClassName}--form-field`]: formField,
        [`${baseClassName}--error`]: error,
        [parentClassName]: parentClassName,
    };

    const handleOnBlur = (e) => {
        setIsFocused(false);
    };

    const handleOnFocus = (e) => {
        setIsFocused(true);
    };

    const handleOnChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className={classNames(classes)}>
            <div className={`${baseClassName}__input-wrapper`}>
                <div className={`${baseClassName}__box`} />
                <div
                    className={
                        publicationForm
                            ? isRightToLeftDirection
                                ? `${baseClassName}__icon-wrapper-publication-rtl`
                                : `${baseClassName}__icon-wrapper-publication`
                            : isRightToLeftDirection
                            ? `${baseClassName}__icon-wrapper-rtl`
                            : `${baseClassName}__icon-wrapper`
                    }
                >
                    {loading ? (
                        <Loader
                            parentClassName={`${baseClassName}__loader`}
                            size="m"
                        />
                    ) : (
                        <Caret
                            parentClassName={`${baseClassName}__caret`}
                            direction="down"
                        />
                    )}
                </div>
                {label && (
                    <label className={`${baseClassName}__label`} htmlFor={id}>
                        {label}
                    </label>
                )}
                <select
                    id={id}
                    value={value}
                    className={
                        publicationForm
                            ? isRightToLeftDirection
                                ? `${baseClassName}__input-publication-rtl`
                                : `${baseClassName}__input-publication`
                            : isRightToLeftDirection
                            ? `${baseClassName}__input-rtl`
                            : `${baseClassName}__input`
                    }
                    onChange={handleOnChange}
                    disabled={disabled || loading}
                    name={name}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                >
                    {options &&
                        options.map((option, index) => (
                            <option
                                key={index}
                                value={option.queryValue || option.id}
                            >
                                {option.flagIcon}{' '}
                                {option.name || option.organization_name}
                            </option>
                        ))}
                </select>
            </div>
            {error && (
                <div className={`${baseClassName}__error-text`}>{error}</div>
            )}
        </div>
    );
};

SelectField.propTypes = {
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            queryValue: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func,
    theme: PropTypes.oneOf(['default', 'light']),
    formField: PropTypes.bool,
    error: PropTypes.string,
    parentClassName: PropTypes.string,
    publicationForm: PropTypes.bool,
};

export default SelectField;
