import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OfflineBooks from '../OfflineBooks';
import { unsaveOffline } from '../../redux/offlineBooksActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';
import { gaEventCategories, gaEventActions } from '../../lib/constants';

const OfflineBooksContainer = ({
    unsaveOffline,
    books,
    userEmail,
    recordGaEvents,
    viewport,
    online,
}) => {
    const [checkedValues, setCheckedValues] = useState([]);
    const myLanguage = localStorage.getItem('locale');

    const onDelete = () => {
        checkedValues.map(unsaveOffline);

        const checkedBooks = books.filter((book) =>
            checkedValues.includes(book.id)
        );

        //send delete event to GA
        checkedBooks.forEach((book) =>
            recordGaEvents({
                eventCategory: gaEventCategories.offline,
                eventAction: gaEventActions.delete,
                userEmail: userEmail,
                dimension2: book.level,
                dimension3: book.language,
                dimension5: book.slug,
                metric2: -1,
            })
        );

        setCheckedValues([]);
    };
    return (
        <OfflineBooks
            myLanguage={myLanguage}
            viewport={viewport}
            books={books}
            onDelete={onDelete}
            online={online}
            checkedValues={checkedValues}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        viewport: state.viewport,
        books: state.offlineBooks.books,
        online: state.network.online,
        userEmail: state.user.profile.email,
    };
};

const mapDispatchToProps = {
    unsaveOffline,
    recordGaEvents,
};

OfflineBooksContainer.propTypes = {
    unsaveOffline: PropTypes.func.isRequired,
    books: PropTypes.array.isRequired,
    userEmail: PropTypes.string.isRequired,
    recordGaEvents: PropTypes.func.isRequired,
    viewport: PropTypes.object.isRequired,
    online: PropTypes.bool.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfflineBooksContainer);
