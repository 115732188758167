import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from '../../Modal';
import Link from '../../Link';
import SortOption from '../../SortOption';
import close from '../../../assets/close.png';

const SortModal = ({
    t,
    classes,
    baseClassName,
    modalSortClose,
    sortOptions,
    appliedSortOption,
    applySortOption,
    isBookSectionMobile,
    isVideoMobile,
    isResourceMobile,
    isReadingListMobile,
    isAddBookMobile,
    isIllustrationMobile,
    isTranslateMobile,
    isRightToLeftDirection,
}) => {
    return (
        <div className={classNames(classes)}>
            <Modal>
                <div className={`${baseClassName}__close-modal`}>
                    <Link onClick={() => modalSortClose()}>
                        <img className="__close-img" src={close} alt="" />
                    </Link>
                </div>
                <div>
                    {sortOptions && (
                        <div
                            className={`${baseClassName}__actions ${baseClassName}__actions--secondary`}
                        >
                            <div
                                className={`${baseClassName}__section-label-filter`}
                            >
                                {t('global.sort-by')}
                            </div>
                            <SortOption
                                value={appliedSortOption}
                                id="filters-bar-sort-by"
                                name={t('global.apply')}
                                onChange={applySortOption}
                                options={sortOptions}
                                sortPopup={() => modalSortClose()}
                                isBookSectionMobile={isBookSectionMobile}
                                isVideoMobile={isVideoMobile}
                                isResourceMobile={isResourceMobile}
                                isReadingListMobile={isReadingListMobile}
                                isAddBookMobile={isAddBookMobile}
                                isIllustrationMobile={isIllustrationMobile}
                                isTranslateMobile={isTranslateMobile}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

SortModal.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.string,
    baseClassName: PropTypes.string.isRequired,
    modalSortClose: PropTypes.func.isRequired,
    sortOptions: PropTypes.array,
    appliedSortOption: PropTypes.string,
    applySortOption: PropTypes.func.isRequired,
    isBookSectionMobile: PropTypes.bool,
    isVideoMobile: PropTypes.bool,
    isResourceMobile: PropTypes.bool,
    isReadingListMobile: PropTypes.bool,
    isAddBookMobile: PropTypes.bool,
    isIllustrationMobile: PropTypes.bool,
    isTranslateMobile: PropTypes.bool,
    isRightToLeftDirection: PropTypes.bool,
};

export default SortModal;
