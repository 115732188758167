import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import ResourceCard from '../ResourceCard';
import CardShelf from '../CardShelf';

const ResourceShelf = ({ resources, viewport, t, sectionClicked, offline }) => {
    const baseClassName = 'pb-resource-shelf';

    const classes = {
        [baseClassName]: true,
    };

    return (
        <div className={classNames(classes)}>
            <CardShelf cellWidth="book-card" viewport={viewport}>
                {resources.map((resourcesItem) => {
                    return (
                        <ResourceCard
                            id={resourcesItem.id}
                            slug={resourcesItem.slug}
                            title={resourcesItem.title}
                            likesCount={resourcesItem.likesCount}
                            readsCount={resourcesItem.readsCount}
                            contentUrl={resourcesItem.contentUrl}
                            isFavourite={resourcesItem.is_favourite}
                            coverImage={resourcesItem.thumbUrl}
                            resourceThumbnailGTM="related-resource-thumbnail"
                            resourceTitleGTM="related-resource-title"
                            isSimilarResource
                        />
                    );
                })}
            </CardShelf>
        </div>
    );
};

ResourceShelf.propTypes = {
    resources: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            likesCount: PropTypes.number,
            readsCount: PropTypes.number,
            contentUrl: PropTypes.string,
            is_favourite: PropTypes.bool,
            thumbUrl: PropTypes.string,
        })
    ).isRequired,
    viewport: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    sectionClicked: PropTypes.func,
    offline: PropTypes.bool,
};

export default translate()(ResourceShelf);
