import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LikeAndShareRow from '../components/LikeAndShareRow';
import BookDetails from '../components/BookDetails';
import Translations from '../components/Translations';
import BookTags from '../components/BookTags';
import BookCard from '../../BookCard';
import Button from '../../Button';
import Dropdown from '../../Dropdown';
import Link from '../../Link';
import List from '../../List';
import BookRelatedCollection from '../../BookRelatedCollection';
import Credits from './Credits'
import SvgIcon from '../../SvgIcon';
import { API_URL } from '../../../api';
import { isRightToLeftDirection, isRTLLanguage } from '../../../util/utils';
import { MAX_OFFLINE_BOOKS_COUNT } from '../../../lib/constants';

const BookLargeScreen = ({
    t,
    baseClassName,
    title_logos,
    myLanguage,
    online,
    book,
    onReadClicked,
    slug,
    pagesCount,
    isFavourite,
    title,
    description,
    coverImage,
    titleClasses,
    level,
    isTranslation,
    isRelevelled,
    authors,
    publisher,
    illustrators,
    photographers,
    originalTitle,
    originalSlug,
    originalAuthors,
    onProfileLinkClicked,
    favouriteStat,
    favouriteStatMainBook,
    readsCount,
    viewport,
    isLoggedIn,
    approvedTitles,
    storyLevelColour,
    translators,
    edition_type,
    isOriginalStoryPresent,
    readAlouds,
    readToMeModalOpen,
    roles,
    downloadLinks,
    bookStoryDownload,
    isDownloadable,
    availableOffline,
    offlineBooksCount,
    onAddToOfflineClicked,
    onOpenModal,
    isSavingOffline,
    translations,
    isMobileWidth,
    readingListMembershipCount,
    tags,
    onMicLink,
    divRef,
    divHeight,
    language,
}) => {
    
    return (
        <div className={`${baseClassName}__masonry-container`}>
            <div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__cover-wrapper-rtl`
                            : `${baseClassName}__cover-wrapper`
                    }
                    ref={divRef}
                >
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__cover-rtl`
                                : `${baseClassName}__cover`
                        }
                    >
                        <div>
                            <BookCard
                                myLanguage={myLanguage}
                                online={online}
                                book={book}
                                fontSize="l"
                                readStory={() => onReadClicked(slug)}
                                shouldDisplayMenu={false}
                                noSrcSet={!online}
                                bookDetails
                                landingPageBook
                                pagesCount={pagesCount}
                                enableFavouriting
                                mobileViewDetails
                                isFavourite={isFavourite}
                            />
                        </div>
                    </div>
                </div>
                {isRTLLanguage(language) && (
                    <div className={`${baseClassName}__arabic-header-title`}>
                        {t('Book.arabicHeaderTitle')}
                    </div>
                )}
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__cover-wrapper-rtl`
                            : `${baseClassName}__cover-wrapper`
                    }
                >
                    <div>
                        <div className={`${baseClassName}__options-container`}>
                            <LikeAndShareRow
                                t={t}
                                favouriteStat={favouriteStat}
                                readsCount={readsCount}
                                title={title}
                                description={description}
                                coverImage={coverImage}
                                favouriteStatMainBook={favouriteStatMainBook}
                                landingPageBook
                            />
                        </div>
                        <div className={`${baseClassName}__buttons-container `}>
                            {isLoggedIn &&
                                (roles.includes('content_manager') ||
                                roles.includes('sub_admin') ||
                                roles.includes('admin') ? (
                                    API_URL ===
                                    'https://staging.literacycloud.org/api/v1' ? null : (
                                        <div
                                            className={
                                                isRightToLeftDirection
                                                    ? `${baseClassName}__book-download-arabic`
                                                    : `${baseClassName}__book-download`
                                            }
                                        >
                                            <Dropdown
                                                align="left"
                                                toggleEl={
                                                    <Link normal>
                                                        {t(
                                                            'global.download',
                                                            1
                                                        )}
                                                    </Link>
                                                }
                                                bookTabletMode
                                            >
                                                <List nowrap>
                                                    {downloadLinks &&
                                                        downloadLinks
                                                            .slice(0, 2)
                                                            .map(
                                                                (
                                                                    {
                                                                        href,
                                                                        type,
                                                                    },
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Link
                                                                            parentClassName={`${baseClassName}__link`}
                                                                            download
                                                                            href={
                                                                                href
                                                                            }
                                                                            dataGTM={
                                                                                bookStoryDownload
                                                                            }
                                                                        >
                                                                            <div
                                                                                className="url_name"
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    type
                                                                                }
                                                                            </div>
                                                                        </Link>
                                                                    );
                                                                }
                                                            )}
                                                </List>
                                            </Dropdown>
                                        </div>
                                    )
                                ) : isDownloadable ? (
                                    API_URL ===
                                    'https://staging.literacycloud.org/api/v1' ? null : (
                                        <div
                                            className={
                                                isRightToLeftDirection
                                                    ? `${baseClassName}__book-download-arabic`
                                                    : `${baseClassName}__book-download`
                                            }
                                        >
                                            <Link
                                                parentClassName={`${baseClassName}__link`}
                                                download
                                                href={downloadLinks[0].href}
                                                dataGTM={bookStoryDownload}
                                            >
                                                <div
                                                    data-gtm={bookStoryDownload}
                                                >
                                                    {t('global.download', 1)}
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                ) : null)}
                            {process.env.REACT_APP_FEATURE_OFFLINE && (
                                <div
                                    className={`${baseClassName}__offline-container`}
                                >
                                    <Button
                                        saveOffline
                                        t={t}
                                        fullWidth
                                        isLoggedIn={isLoggedIn}
                                        availableOffline={availableOffline}
                                        label={t('Book.add-to-offline')}
                                        variant="white-background"
                                        isRightToLeftDirection={
                                            isRightToLeftDirection
                                        }
                                        dataGTM={bookStoryDownload}
                                        onClickAddToOffline={() => {
                                            offlineBooksCount <
                                            MAX_OFFLINE_BOOKS_COUNT
                                                ? onAddToOfflineClicked()
                                                : onOpenModal(
                                                      'noSpaceForMoreOfflineBooks'
                                                  );
                                        }}
                                        onClickRemoveFromOffline={() =>
                                            onOpenModal('delete')
                                        }
                                        disabled={
                                            !('serviceWorker' in navigator)
                                        }
                                        isSavingOffline={isSavingOffline}
                                        bookSlug={slug}
                                    ></Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${baseClassName}__wrapper-container`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__cover-wrapper-rtl`
                                : `${baseClassName}__cover-wrapper`
                        }
                    >
                        {tags && tags.length > 0 && (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__BookTagsContainerRtl`
                                        : `${baseClassName}__BookTagsContainer`
                                }
                            >
                                <BookTags
                                    tags={tags}
                                    online={online}
                                    t={t}
                                    type={'bookPage'}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={[
                        isRightToLeftDirection
                            ? `${baseClassName}__cover-wrapper-rtl`
                            : `${baseClassName}__cover-wrapper`,
                        `${baseClassName}__wrapper-container`,
                    ].join(' ')}
                >
                    {(localStorage.getItem('locale') === 'vi' ||
                        localStorage.getItem('locale') === 'en') &&
                        onMicLink && (
                            <div
                                className={`${baseClassName}__audio-link-container`}
                            >
                                <div
                                    className={`${baseClassName}__audio-link-container-title`}
                                >
                                    {t('book.audioLinkTitle')}
                                </div>
                                <Link
                                    isExternal={true}
                                    audioLink
                                    href={onMicLink}
                                >
                                    <SvgIcon
                                        name="onMicIcon"
                                        size="onmic-icon"
                                    />
                                </Link>
                            </div>
                        )}
                </div>
            </div>
            <div>
                <div
                    className={`${baseClassName}__details-wrapper`}
                    style={{ minHeight: divHeight }}
                >
                    <div>
                        <div className={`${baseClassName}__title-row`}>
                            <div className={classNames(titleClasses)}>
                                {title}
                            </div>
                        </div>

                        <div className={`${baseClassName}__content`}>
                            <BookDetails
                                level={level}
                                title={title}
                                isTranslation={isTranslation}
                                isRelevelled={isRelevelled}
                                authors={authors}
                                publisher={publisher}
                                illustrators={illustrators}
                                photographers={photographers}
                                originalTitle={originalTitle}
                                originalSlug={originalSlug}
                                originalAuthors={originalAuthors}
                                online={online}
                                t={t}
                                onProfileLinkClicked={onProfileLinkClicked}
                                favouriteStat={favouriteStat}
                                favouriteStatMainBook={favouriteStatMainBook}
                                readsCount={readsCount}
                                description={description}
                                coverImage={coverImage}
                                viewport={viewport}
                                isLoggedIn={isLoggedIn}
                                logInMsg={t('global.please-log-in', {
                                    action: t('global.like', 1),
                                    content_type: t('global.story', 1),
                                })}
                                bookSlug={slug}
                                approvedTitles={approvedTitles}
                                title_logos={title_logos}
                                landingPageBook
                                storyLevelColour={storyLevelColour}
                                isLandingBookPage
                                translator={translators}
                                type={edition_type}
                                isOriginalStoryPresent={isOriginalStoryPresent}
                                baseClassName={baseClassName}
                                isMobileWidth={false}
                                language={book.language}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__readStory-wrapper-arabic`
                                : `${baseClassName}__readStory-wrapper`
                        }
                    >
                        <Credits
                            isMobileWidth={isMobileWidth}
                            isTranslation={isTranslation}
                            isRelevelled={isRelevelled}
                            authors={authors}
                            publisher={publisher}
                            illustrators={illustrators}
                            photographers={photographers}
                            originalTitle={originalTitle}
                            originalSlug={originalSlug}
                            originalAuthors={originalAuthors}
                            offline={!online}
                            t={t}
                            onProfileLinkClicked={onProfileLinkClicked}
                            landingPageBook
                            translator={translators}
                            type={edition_type}
                            isOriginalStoryPresent={isOriginalStoryPresent}
                        />
                        <div
                            className={`${baseClassName}__readStory-flex-wrapper`}
                        >
                            <div className={`${baseClassName}__read-button`}>
                                <Button
                                    iconLeft="read_white"
                                    buttonBlue
                                    fullWidth
                                    label={t('Book.read')}
                                    variant="primary"
                                    onClick={() => onReadClicked(slug)}
                                    dataGTM="book-read-story"
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                />
                            </div>

                            {readAlouds.length > 0 && (
                                <div
                                    className={`${baseClassName}__read-to-me-button`}
                                >
                                    <Button
                                        iconLeft="video"
                                        buttonBlue
                                        fullWidth
                                        label={t('Book.readToMe')}
                                        variant="primary"
                                        onClick={() => readToMeModalOpen()}
                                        isRightToLeftDirection={
                                            isRightToLeftDirection
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${baseClassName}__wrapper-container`}>
                    <div>
                        {online &&
                        translations &&
                        translations.length &&
                        !isMobileWidth ? (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__translation-rtl`
                                        : `${baseClassName}__translation`
                                }
                            >
                                <Translations
                                    translations={translations}
                                    versionCount={book.versionCount}
                                    languageCount={book.languageCount}
                                    offline={!online}
                                    t={t}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={`${baseClassName}__wrapper-container`}>
                    {online && readingListMembershipCount > 0 && (
                        <div
                            className={`${baseClassName}__RelatedReadingListContainer`}
                        >
                            {readingListMembershipCount ? (
                                <BookRelatedCollection
                                    title={t('Book.related-collection')}
                                    book={book}
                                    bookRelatedReadingLists="related-reading-lists"
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                />
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

BookLargeScreen.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    title_logos: PropTypes.array,
    myLanguage: PropTypes.string,
    online: PropTypes.bool.isRequired,
    book: PropTypes.object.isRequired,
    onReadClicked: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    pagesCount: PropTypes.number,
    isFavourite: PropTypes.bool,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    coverImage: PropTypes.string,
    titleClasses: PropTypes.string,
    level: PropTypes.string,
    isTranslation: PropTypes.bool,
    isRelevelled: PropTypes.bool,
    authors: PropTypes.arrayOf(PropTypes.string),
    publisher: PropTypes.string,
    illustrators: PropTypes.arrayOf(PropTypes.string),
    photographers: PropTypes.arrayOf(PropTypes.string),
    originalTitle: PropTypes.string,
    originalSlug: PropTypes.string,
    originalAuthors: PropTypes.arrayOf(PropTypes.string),
    onProfileLinkClicked: PropTypes.func,
    favouriteStat: PropTypes.object,
    favouriteStatMainBook: PropTypes.object,
    readsCount: PropTypes.number,
    viewport: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    approvedTitles: PropTypes.arrayOf(PropTypes.string),
    storyLevelColour: PropTypes.string,
    translators: PropTypes.arrayOf(PropTypes.string),
    edition_type: PropTypes.string,
    isOriginalStoryPresent: PropTypes.bool,
    readAlouds: PropTypes.array,
    readToMeModalOpen: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    downloadLinks: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })
    ),
    bookStoryDownload: PropTypes.string,
    isDownloadable: PropTypes.bool,
    availableOffline: PropTypes.bool,
    offlineBooksCount: PropTypes.number,
    onAddToOfflineClicked: PropTypes.func,
    onOpenModal: PropTypes.func,
    isSavingOffline: PropTypes.bool,
    translations: PropTypes.arrayOf(PropTypes.object),
    isMobileWidth: PropTypes.bool,
    readingListMembershipCount: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    onMicLink: PropTypes.string,
    divRef: PropTypes.object,
    divHeight: PropTypes.number,
};

export default BookLargeScreen;
