import React from 'react';
import PropTypes from 'prop-types';
import './BookUploadFooter.scss';
import locales from '../../i18n';

const BookUploadFooter = ({ backPage, nextPage, disable }) => {
    const baseClassName = 'book-upload_footer';
    const locale = localStorage.getItem('locale') || 'en';
    const messages = locales[locale];

    return (
        <div
            className={
                backPage === undefined
                    ? `${baseClassName}__button_wrapper_publication-form`
                    : `${baseClassName}__button_wrapper`
            }
        >
            {backPage !== undefined ? (
                <button
                    className={`${baseClassName}__save_button`}
                    onClick={backPage}
                >
                    {messages['global.previous']}
                </button>
            ) : (
                ''
            )}
            {nextPage !== undefined ? (
                <button
                    className={
                        `${baseClassName}__publish_button ` +
                        (disable ? `${baseClassName}__disable_button` : '')
                    }
                    onClick={nextPage}
                >
                    {messages['global.Next']}
                </button>
            ) : (
                ''
            )}
        </div>
    );
};

BookUploadFooter.propTypes = {
    backPage: PropTypes.func,
    nextPage: PropTypes.func,
    disable: PropTypes.bool,
};

export default BookUploadFooter;
