import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import './ResourceDetailContainer.scss';
import ResourceDetail from '../ResourceDetail';
import LoaderBlock from '../LoaderBlock';
import NotFoundPage from '../NotFoundPage';
import { fetchMeWorkflow } from '../../redux/userActions';
import { fetchReferenceDetailWorkflow } from '../../redux/referencesActions';
import withRouter from '../../util/withRouter';
const ResourceDetailContainer = ({
    params,
    fetchReferenceDetailWorkflow,
    fetchMeWorkflow,
    navigate,
    parentClassName,
    t,
    isFetchingResourceDetail,
    resourceDetail,
    resourceID,
    resourceReadsCount,
    resourceLikesCount,
    resourceUrl,
    online,
    viewport,
    isLoggedIn,
    isFavourite,
}) => {
    const resourceSlug = params.slug;

    useEffect(() => {
        fetchReferenceDetailWorkflow(resourceSlug);
    }, []);

    useEffect(() => {
        fetchReferenceDetailWorkflow(params.slug);
    }, [params.slug]);

    const baseClassName = 'pb-resource-detail-container';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const myLanguage = localStorage.getItem('locale');

    if (isFetchingResourceDetail) {
        return <LoaderBlock />;
    }

    if (!isFetchingResourceDetail && resourceDetail.id === undefined) {
        return <NotFoundPage />;
    }
    return (
        <div className={classNames(classes)}>
            <div>
                <ResourceDetail
                    myLanguage={myLanguage}
                    t={t}
                    resourceDetail={resourceDetail}
                    resourceID={resourceID}
                    resourceReadsCount={resourceReadsCount}
                    likesCount={resourceLikesCount}
                    isFavourite={isFavourite}
                    resourceUrl={resourceUrl}
                    online={online}
                    viewport={viewport}
                    isLoggedIn={isLoggedIn}
                    resourcesResourceThumbnailGTM="resources-resource-thumbnail"
                    resourcesResourceDownloadGTM="resources-resource-download"
                    resourcesResourceFavoriteGTM="resources-resource-favorite"
                    resourceDisqusGTM="resource-disqus-comments"
                />
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    fetchReferenceDetailWorkflow,
    fetchMeWorkflow,
};

const mapStateToProps = ({ references, viewport, network, user }) => ({
    ...references,
    resourceDetail: references.resourceDetail,
    resourceID: references.resourceDetail.id,
    resourceReadsCount: references.resourceDetail.readsCount,
    resourceLikesCount: references.resourceDetail.likesCount,
    isFavourite: references.resourceDetail.is_favourite,
    resourceUrl: references.resourceDetail.contentUrl,
    online: network.online,
    isLoggedIn: user.isLoggedIn,
    viewport,
});

ResourceDetailContainer.propTypes = {
    params: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }).isRequired,
    fetchReferenceDetailWorkflow: PropTypes.func.isRequired,
    fetchMeWorkflow: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    parentClassName: PropTypes.string,
    t: PropTypes.func.isRequired,
    isFetchingResourceDetail: PropTypes.bool.isRequired,
    resourceDetail: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        readsCount: PropTypes.number,
        likesCount: PropTypes.number,
        is_favourite: PropTypes.bool,
        contentUrl: PropTypes.string,
    }).isRequired,
    resourceID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resourceReadsCount: PropTypes.number,
    resourceLikesCount: PropTypes.number,
    resourceUrl: PropTypes.string,
    online: PropTypes.bool.isRequired,
    viewport: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isFavourite: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(translate()(ResourceDetailContainer))
);
