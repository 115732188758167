import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';
import { sortOptions } from '../../../lib/constants';
import { isRightToLeftDirection } from '../../../util/utils';

const SortTranslateMobile = ({
    t,
    isMostLiked,
    mostLikedSortChange,
    leastLikedSortChange,
    favoriteSortGTM,
    isMostView,
    mostViewSortChange,
    leastViewSortChange,
    viewSortGTM,
}) => {
    return [
        <span>
            <Link
                onClick={
                    isMostLiked
                        ? () => mostLikedSortChange(sortOptions.mostLiked)
                        : () => leastLikedSortChange(sortOptions.leastLiked)
                }
                lightBlack
                dataGTM={favoriteSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={favoriteSortGTM}
                >
                    {t('sort.most-liked')}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={favoriteSortGTM}
                    />
                </div>
            </Link>
        </span>,
        <span>
            <Link
                onClick={
                    isMostView
                        ? () => mostViewSortChange(sortOptions.mostView)
                        : () => leastViewSortChange(sortOptions.leastView)
                }
                lightBlack
                dataGTM={viewSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={viewSortGTM}
                >
                    {t('global.list-view', 2)}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={viewSortGTM}
                    />
                </div>
            </Link>
        </span>,
    ];
};

SortTranslateMobile.propTypes = {
    t: PropTypes.func.isRequired,
    isMostLiked: PropTypes.bool.isRequired,
    mostLikedSortChange: PropTypes.func.isRequired,
    leastLikedSortChange: PropTypes.func.isRequired,
    favoriteSortGTM: PropTypes.string.isRequired,
    isMostView: PropTypes.bool.isRequired,
    mostViewSortChange: PropTypes.func.isRequired,
    leastViewSortChange: PropTypes.func.isRequired,
    viewSortGTM: PropTypes.string.isRequired,
};

export default SortTranslateMobile;
