export const filterData = [
    // 'publisher',
    'category',
    'role',
    'language',
    'style',
    'level',
    'read_alouds',
];

export const levelData = (t) => {
    return {
        queryValues: [
            {
                description: t('PublicationForm.level1Text'),
                name: t('PublicationForm.level1'),
                queryValue: '1',
            },
            {
                description: t('PublicationForm.level2Text'),
                name: t('PublicationForm.level2'),
                queryValue: '2',
            },
            {
                description: t('PublicationForm.level3Text'),
                name: t('PublicationForm.level3'),
                queryValue: '3',
            },
            {
                description: t('PublicationForm.level4Text'),
                name: t('PublicationForm.level4'),
                queryValue: '4',
            },
            {
                description: t('PublicationForm.level5Text'),
                name: t('PublicationForm.level5'),
                queryValue: '5',
            },
            {
                description: t('PublicationForm.level6Text'),
                name: t('PublicationForm.level6'),
                queryValue: '6',
            },
        ],
    };
};

export const typeData = (t) => {
    return {
        queryValues: [
            {
                name: t('global.typeOption1'),
                queryValue: '1',
            },
        ],
    };
};

