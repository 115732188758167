import React from 'react';
import PropTypes from 'prop-types';
import VideoCard from '../../../VideoCard';
import Grid from '../../../Grid';

const VideosCards = ({ favouriteVideos }) => {
    const myLanguage = localStorage.getItem('locale');

    return (
        <Grid variant="4up">
            {favouriteVideos.map((videoItem) => {
                return (
                    <div key={videoItem.id}>
                        <VideoCard
                            myLanguage={myLanguage}
                            videoID={videoItem.id}
                            videoSlug={videoItem.slug}
                            
                            videoTitle={videoItem.title}
                            videoThumbnail={videoItem.thumbUrl}
                            videoUrl={videoItem.materialUrl}
                            videoDownloadUrl={videoItem.downloadUrl}
                            videoLikes={videoItem.likesCount}
                            videoReads={videoItem.readsCount}
                            videoTime={videoItem.duration}
                            isFavourite={videoItem.is_favourite}
                            videoTitleGTM="my-favorite-video-title"
                            videoThumbnailGTM="my-favorite-video-thumbnail"
                        />
                    </div>
                );
            })}
        </Grid>
    );
};

VideosCards.propTypes = {
    favouriteVideos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            thumbUrl: PropTypes.string.isRequired,
            materialUrl: PropTypes.string.isRequired,
            downloadUrl: PropTypes.string,
            likesCount: PropTypes.number.isRequired,
            readsCount: PropTypes.number.isRequired,
            duration: PropTypes.string.isRequired,
            is_favourite: PropTypes.bool.isRequired,
        })
    ).isRequired,
};

export default VideosCards;
