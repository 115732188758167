import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';

const AddRemoveButton = ({
    t,
    baseClassName,
    isShowRemoveButton,
    addToBookList,
    book,
    addToBookListGTM,
    removeFromBookList,
    manageReadingListId,
    removeFromBookListGTM,
}) => {
    return (
        <div className={`${baseClassName}__add-remove-button-section`}>
            {isShowRemoveButton ? (
                <Link
                    isInternal
                    onClick={() => addToBookList(book)}
                    dataGTM={addToBookListGTM}
                >
                    <div
                        className="__add-remove-button"
                        data-gtm={addToBookListGTM}
                    >
                        {t('global.add')}
                    </div>
                </Link>
            ) : (
                <Link
                    isInternal
                    onClick={() =>
                        removeFromBookList(book, manageReadingListId)
                    }
                    dataGTM={removeFromBookListGTM}
                >
                    <div
                        className="__add-remove-button"
                        data-gtm={removeFromBookListGTM}
                    >
                        {t('global.remove')}
                    </div>
                </Link>
            )}
        </div>
    );
};

AddRemoveButton.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    isShowRemoveButton: PropTypes.bool.isRequired,
    addToBookList: PropTypes.func.isRequired,
    book: PropTypes.object.isRequired,
    addToBookListGTM: PropTypes.string.isRequired,
    removeFromBookList: PropTypes.func.isRequired,
    manageReadingListId: PropTypes.string,
    removeFromBookListGTM: PropTypes.string.isRequired,
};

export default AddRemoveButton;
