import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import { fetchFavouriteVideosWorkflow } from '../../redux/videoActions';
import { fetchFavouriteBooksWorkflow } from '../../redux/bookActions';
import { fetchFavouriteResourcesWorkflow } from '../../redux/referencesActions';
import './MyFavouritesContainer.scss';
import Title from './components/Title';
import Tabs from './components/Tabs';
import CardsContainer from './components/CardsContainer';

const MyFavouritesContainer = ({
    t,
    favouriteVideos,
    favouriteResources,
    favouriteBooks,
    fetchFavouriteBooksWorkflow,
    fetchFavouriteResourcesWorkflow,
    fetchFavouriteVideosWorkflow,
    booksCount,
    isFetchingData,
    videosCount,
    resourcesCount,
    parentClassName,
}) => {
    const baseClassName = 'pb-my-favourites-container';
    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const [selectedTab, setSelectedTab] = useState('books');
    const [updatedVideosCount, setUpdatedVideosCount] = useState(0);
    const [updatedResourcesCount, setUpdatedResourcesCount] = useState(0);

    useEffect(() => {
        tabSelectHandler('books');
    }, []);

    useEffect(() => {
        setUpdatedVideosCount(videosCount);
        setUpdatedResourcesCount(resourcesCount);
    }, [videosCount, resourcesCount]);

    const tabSelectHandler = (selectedTab) => {
        setSelectedTab(selectedTab);
        switch (selectedTab) {
            case 'books':
                fetchFavouriteBooksWorkflow().then((response) => {
                    setUpdatedVideosCount(
                        response && response.counts && response.counts.videos
                    );
                    setUpdatedResourcesCount(
                        response && response.counts && response.counts.resources
                    );
                });
                break;
            case 'videos':
                fetchFavouriteVideosWorkflow().then((response) => {
                    setUpdatedVideosCount(response.counts.videos);
                    setUpdatedResourcesCount(response.counts.resources);
                });
                break;
            case 'resources':
                fetchFavouriteResourcesWorkflow().then((response) => {
                    setUpdatedVideosCount(response.counts.videos);
                    setUpdatedResourcesCount(response.counts.resources);
                });
                break;
            default:
                break;
        }
    };

    return (
        <div className={classNames(classes)}>
            <DocumentTitle title={t('global.my-favorites')} />
            <Title t={t} />
            <Tabs
                t={t}
                selectedTab={selectedTab}
                tabSelectHandler={tabSelectHandler}
                booksCount={booksCount}
                updatedVideosCount={updatedVideosCount}
                updatedResourcesCount={updatedResourcesCount}
            />
            <CardsContainer
                t={t}
                selectedTab={selectedTab}
                booksCount={booksCount}
                isFetchingData={isFetchingData}
                updatedResourcesCount={updatedResourcesCount}
                updatedVideosCount={updatedVideosCount}
                favouriteBooks={favouriteBooks}
                favouriteResources={favouriteResources}
                favouriteVideos={favouriteVideos}
            />
        </div>
    );
};

const mapDispatchToProps = {
    fetchFavouriteVideosWorkflow,
    fetchFavouriteBooksWorkflow,
    fetchFavouriteResourcesWorkflow,
};

const mapStateToProps = (state) => {
    return {
        favouriteBooks: state.book.favouriteBooks,
        favouriteVideos: state.videos.favouriteVideos,
        favouriteResources: state.references.favouriteResources,
        booksCount: state.book.totalFavouriteBooksCount,
        videosCount: state.videos.totalFavouriteVideosCount,
        resourcesCount: state.references.totalFavouriteResourcesCount,
        isFetchingData:
            state.references.isFetchingFavouriteResources ||
            state.book.isFetchingFavouriteBooks ||
            state.videos.isFetchingFavouriteVideos,
    };
};

MyFavouritesContainer.propTypes = {
    t: PropTypes.func.isRequired,
    favouriteVideos: PropTypes.array.isRequired,
    favouriteResources: PropTypes.array.isRequired,
    favouriteBooks: PropTypes.array.isRequired,
    fetchFavouriteBooksWorkflow: PropTypes.func.isRequired,
    fetchFavouriteResourcesWorkflow: PropTypes.func.isRequired,
    fetchFavouriteVideosWorkflow: PropTypes.func.isRequired,
    booksCount: PropTypes.number.isRequired,
    isFetchingData: PropTypes.bool.isRequired,
    videosCount: PropTypes.number.isRequired,
    resourcesCount: PropTypes.number.isRequired,
    parentClassName: PropTypes.string,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(MyFavouritesContainer)
);
