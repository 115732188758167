import React from 'react';
import PropTypes from 'prop-types';
import InfoUnit from '../../InfoUnit';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const Twitter = ({ twitterUrl }) => {
    return (
        <React.Fragment>
            {twitterUrl !== null && twitterUrl !== '' && (
                <div className="__profile-twitter">
                    <InfoUnit noWrap key={twitterUrl}>
                        <Link
                            shouldOpenNewPage={true}
                            href={twitterUrl}
                            dataGTM="profile-twitter"
                        >
                            <SvgIcon name="twitter" />
                        </Link>
                    </InfoUnit>
                </div>
            )}
        </React.Fragment>
    );
};

Twitter.propTypes = {
    twitterUrl: PropTypes.string.isRequired,
};

export default Twitter;
