import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import List from '../List';
import Dropdown from '../Dropdown';
import Link from '../Link';
import useViewport from '../../hooks/useViewport';

const DownloadRange = ({ t, align, videosVideoDownloadGTM, dpi_url }) => {
    const { isMobile, isTablet, isOther } = useViewport();

    return (
        <Dropdown
            align={align}
            toggleEl={
                <Link normal dataGTM={videosVideoDownloadGTM}>
                    {t('global.download', 1)}
                </Link>
            }
        >
            <List nowrap>
                {/*For Mobile View */}

                {isMobile
                    ? dpi_url &&
                      dpi_url.mobile.map((value, index) => {
                          return (
                              <Link
                                  parentClassName={`__link`}
                                  download
                                  href={value.url}
                                  dataGTM={videosVideoDownloadGTM}
                              >
                                  <div className="url_name" key={index}>
                                      {value.resolution}
                                  </div>
                              </Link>
                          );
                      })
                    : null}

                {/*For Tablet View */}

                {isTablet
                    ? dpi_url &&
                      dpi_url.tablet.map((value, index) => {
                          return (
                              <Link
                                  parentClassName={`__link`}
                                  download
                                  href={value.url}
                                  dataGTM={videosVideoDownloadGTM}
                              >
                                  <div className="url_name" key={index}>
                                      {value.resolution}
                                  </div>
                              </Link>
                          );
                      })
                    : null}

                {/*For Other (Higher than Tablet) View */}

                {isOther
                    ? dpi_url &&
                      dpi_url.other.map((value, index) => {
                          return (
                              <Link
                                  parentClassName={`__link`}
                                  download
                                  href={value.url}
                                  dataGTM={videosVideoDownloadGTM}
                              >
                                  <div className="url_name" key={index}>
                                      {value.resolution}
                                  </div>
                              </Link>
                          );
                      })
                    : null}
            </List>
        </Dropdown>
    );
};

DownloadRange.propTypes = {
    t: PropTypes.func.isRequired,
    align: PropTypes.string.isRequired,
    videosVideoDownloadGTM: PropTypes.string.isRequired,
    dpi_url: PropTypes.shape({
        mobile: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                resolution: PropTypes.string.isRequired,
            })
        ),
        tablet: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                resolution: PropTypes.string.isRequired,
            })
        ),
        other: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                resolution: PropTypes.string.isRequired,
            })
        ),
    }),
};

export default translate()(DownloadRange);
