import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import ReadAloudsCard from '../ReadAloudsCard';
import LoaderBlock from '../LoaderBlock';
import Grid from '../Grid';
import NoResults from './components/NoResults';
import {
    fetchReadAloudFiltersWorkflow,
    fetchReadAloudsWorkflow,
    videoReadCountWorkflow,
} from '../../redux/readAloudsActions';
import { isRightToLeftDirection } from '../../util/utils';
import './ReadAloudCardContainer.scss';

const ReadAloudCardContainer = ({
    parentClassName,
    Videos,
    isFetchingReadAlouds,
    totalVideosCount,
    myLanguage,
    t,
}) => {
    const baseClassName = 'pb-readAloud-card-container';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    return (
        <div className={classNames(classes)}>
            {isFetchingReadAlouds || !Videos ? (
                <LoaderBlock />
            ) : (
                <div>
                    {Videos.length > 0 ? (
                        <Grid variant="4up">
                            {Videos.map((videoItem) => {
                                return (
                                    <div key={videoItem.id}>
                                        <ReadAloudsCard
                                            myLanguage={myLanguage}
                                            videoID={videoItem.id}
                                            videoSlug={videoItem.slug}
                                            videoTitle={videoItem.name}
                                            videoThumbnail={videoItem.thumbUrl}
                                            videoUrl={videoItem.materialUrl}
                                            videoDownloadUrl={
                                                videoItem.downloadUrl
                                            }
                                            videoLikes={videoItem.likesCount}
                                            videoReads={videoItem.readsCount}
                                            videoTime={videoItem.duration}
                                            isFavourite={videoItem.is_favourite}
                                            readCount={videoReadCountWorkflow}
                                            videoTitleGTM="videos-video-title"
                                            videoThumbnailGTM="videos-video-thumbnail"
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                    ) : null}
                    <div
                        className={
                            isRightToLeftDirection
                                ? '__no-result-section-rtl'
                                : '__no-result-section'
                        }
                    >
                        {!totalVideosCount && <NoResults t={t} />}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    fetchReadAloudFiltersWorkflow,
    fetchReadAloudsWorkflow,
};

const mapStateToProps = ({
    readAlouds,
    viewport,
    allFilters: { readFilters },
}) => ({
    ...readAlouds,
    viewport,
    appliedFilters: readFilters,
});

ReadAloudCardContainer.propTypes = {
    parentClassName: PropTypes.string,
    Videos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            thumbUrl: PropTypes.string.isRequired,
            materialUrl: PropTypes.string.isRequired,
            downloadUrl: PropTypes.string,
            likesCount: PropTypes.number,
            readsCount: PropTypes.number,
            duration: PropTypes.string,
            is_favourite: PropTypes.bool,
        })
    ),
    isFetchingReadAlouds: PropTypes.bool.isRequired,
    totalVideosCount: PropTypes.number,
    myLanguage: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

ReadAloudCardContainer.defaultProps = {
    parentClassName: '',
    Videos: [],
    totalVideosCount: 0,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(ReadAloudCardContainer)
);
