import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import classNames from 'classnames';
import Joyride from 'react-joyride';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import DocumentHeader from '../DocumentHeader';
import DocumentClass from '../DocumentClass';
import Favourites from '../Favourites';
import Link from '../Link';
import SimilarBooks from './components/SimilarBooks';
import Comments from '../Comments/index.jsx';
import NoSpaceForMoreOfflineBooksModal from './components/NoSpaceForMoreOfflineBooksModal';
import DeleteModal from './components/DeleteModal';
import Modal from '../Modal';
import VideoPlayer from '../VideoPlayer';
import useDeviceType from '../../hooks/useDeviceType';
import useMeasureHeight from '../../hooks/useMeasureHeight';
import { favouriteBookWorkflow } from '../../redux/bookActions';
import { helpGuideSteps } from './constants/helpGuideSteps';
import {
    MAX_OFFLINE_BOOKS_COUNT,
    gaEventCategories,
    gaEventActions,
    sectionClicked,
} from '../../lib/constants';
import withRouter from '../../util/withRouter';
import { isRightToLeftDirection } from '../../util/utils';
import closeIcon from '../../assets/closeIcon.png';
import BookSmallScreen from './components/BookSmallScreen';
import BookLargeScreen from './components/BookLargeScreen';

const baseClassName = 'pb-book';

const Book = ({
    t,
    viewport,
    online,
    book,
    helpStatus,
    roles,
    isLoggedIn,
    isFavouriting,
    favouriteBookWorkflow,
    isSavingOffline,
    availableOffline,
    offlineBooksCount,
    bookStoryDownload,
    navigate,
    readToMeAction,
    onClickAddToOffline,
    userEmail,
    recordGaEvents,
    addSlimNotification,
    onClickRemoveFromOffline,
}) => {
    const [isReadToMeModalVisible, setIsReadToMeModalVisible] = useState(false);
    const [isReadToMeVideoPlay, setIsReadToMeVideoPlay] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState({
        noSpaceForMoreOfflineBooks: false,
        delete: false,
    });

    const {
        id,
        name,
        name: title,
        description,
        slug,
        coverImage,
        level,
        pagesCount,
        storyLevelColour,
        title_logos,
        likesCount,
        isFavourite,
        readsCount,
        readAlouds,
        language,
        publisher,
        edition_type,
        isRelevelled,
        downloadLinks,
        isDownloadable,
        isTranslation,
        authors = [],
        illustrators = [],
        photographers = [],
        translators,
        translations,
        tags = [],
        readingListMembershipCount,
        onMicLink,
        similarBooks,
        originalStory: {
            name: originalTitle,
            slug: originalSlug,
            authors: originalAuthors,
            isPresent: isOriginalStoryPresent,
        },
    } = book;

    const {
        isMobile: isBookSectionMobile,
        dimensions: { width: dimension = 0 },
        isMobileWidth,
    } = useDeviceType();

    const [divRef, divHeight] = useMeasureHeight();

    const myLanguage = localStorage.getItem('locale');

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--floated-cta`]: !viewport.large,
        [`${baseClassName}--offline`]: !online,
    };

    const onFavoriteClicked = (bookID) => {
        favouriteBookWorkflow(bookID);
    };

    const onReadClicked = (slug) => {
        if (book && book.is_offline_story) {
            navigate(`/stories/${slug}/readStoryPage`);
        }
    };

    const readToMeModalOpen = () => {
        setIsReadToMeModalVisible(true);
        setIsReadToMeVideoPlay(true);

        const storyVideoId = readAlouds[0].coachingMaterialId;
        readToMeAction(storyVideoId);
    };

    const onAddToOfflineClicked = () => {
        onClickAddToOffline()
            .then(() =>
                addSlimNotification({
                    type: 'info',
                    content: t(
                        'BookCard.added-to-offline-library-notification'
                    ),
                })
            )
            .then(() => {
                recordGaEvents({
                    eventCategory: gaEventCategories.offline,
                    eventAction: gaEventActions.add,
                    userEmail: userEmail,
                    dimension2: level,
                    dimension3: language,
                    dimension5: slug,
                    metric2: 1,
                });
            });
    };

    const onOpenModal = (modalName) => {
        setIsModalVisible((prevState) => ({
            ...prevState,
            [modalName]: true,
        }));
    };

    const onCloseModal = (modalName) => {
        setIsModalVisible((prevState) => ({
            ...prevState,
            [modalName]: false,
        }));
    };

    const readToMeModalClose = () => {
        setIsReadToMeModalVisible(false);
        setIsReadToMeVideoPlay(false);
    };

    const onProfileLinkClicked = (profileSlug, linkType) => {
        recordGaEvents({
            eventCategory: gaEventCategories.profile,
            eventAction: gaEventActions.opened,
            eventLabel: `${sectionClicked.bookDetails} ${linkType}`,
            userEmail: userEmail,
            dimension5: profileSlug,
        });
    };

    const titleClasses = {
        [myLanguage === 'lo'
            ? `${baseClassName}__lo__title`
            : isRightToLeftDirection
            ? `${baseClassName}__title ${baseClassName}__arabic-title`
            : `${baseClassName}__title`]: true,
        [`${baseClassName}__title--bhoti`]: book.language === 'Bhoti',
    };

    let favouriteStat = null;
    if (likesCount >= 0 && online) {
        favouriteStat = (
            <Favourites
                t={t}
                isFavourite={isFavourite}
                favouriteToggleHandler={() => onFavoriteClicked(id)}
                isFavouriting={isFavouriting}
                isLoggedIn={isLoggedIn}
                logInMsg={t('global.please-log-in', {
                    action: t('global.like', 1),
                    content_type: t('global.story', 1),
                })}
                likes={likesCount}
                bookSlug={slug}
                bookFavorite="book-favorite"
            />
        );
    }

    let favouriteStatMainBook = null;
    if (likesCount >= 0 && online) {
        favouriteStatMainBook = (
            <Favourites
                t={t}
                isFavourite={isFavourite}
                favouriteToggleHandler={() => onFavoriteClicked(id)}
                isFavouriting={isFavouriting}
                isLoggedIn={isLoggedIn}
                logInMsg={t('global.please-log-in', {
                    action: t('global.like', 1),
                    content_type: t('global.story', 1),
                })}
                likes={likesCount}
                bookSlug={slug}
                bookFavorite="book-favorite"
                landingPageBook
            />
        );
    }

    const approvedTitles = title_logos.length > 0 && (
        <div className={`${baseClassName}__approvedTitlesContainer`}>
            {title_logos.map((logoText) => {
                return (
                    <div
                        className={
                            isMobileWidth
                                ? 'govt-approved-container-mobile'
                                : 'govt-approved-container'
                        }
                    >
                        <img
                            className="govt-approved"
                            src={require('../../assets/govt-approved.svg')}
                            alt="Govt approved"
                        />
                        <p>
                            <span>{t('Book.governmentApproved')}</span>
                        </p>
                    </div>
                );
            })}
        </div>
    );

    const readToMeModal = () => {
        return (
            <Modal>
                <div className="__close">
                    <Link onClick={() => readToMeModalClose()}>
                        <img
                            className="__profile-images"
                            src={closeIcon}
                            alt=""
                        />
                    </Link>
                </div>
                <div>
                    <VideoPlayer
                        name={readAlouds[0].filename}
                        videoSrc={readAlouds[0].url}
                        videoPlay={isReadToMeVideoPlay}
                    />
                </div>
            </Modal>
        );
    };

    return (
        <div className={classNames(classes)}>
            <DocumentHeader
                title={name}
                description={description}
                keywords={slug}
                imageUrl={
                    coverImage.isThumbnailEdited ||
                    coverImage.sizes[6] === undefined
                        ? coverImage.sizes[0].url
                        : coverImage.sizes[6].url
                }
                imgWidth={
                    coverImage.isThumbnailEdited ||
                    coverImage.sizes[6] === undefined
                        ? coverImage.sizes[0].width
                        : coverImage.sizes[6].width
                }
                imgHeight={
                    coverImage.isThumbnailEdited ||
                    coverImage.sizes[6] === undefined
                        ? coverImage.sizes[0].height
                        : coverImage.sizes[6].height
                }
                imgAlt={name}
            />
            <Joyride
                enabled={true}
                steps={
                    isBookSectionMobile
                        ? helpGuideSteps(t).mobileSteps
                        : helpGuideSteps(t).steps
                }
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={250}
                disableScrolling={false}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <DocumentTitle title={`${title} - ${t('global.site-title')}`} />
            <DocumentClass
                className={`${baseClassName}-document--offline`}
                remove={online}
            />
            {dimension < 600 ? (
                <BookSmallScreen
                    t={t}
                    baseClassName={baseClassName}
                    title_logos={title_logos}
                    myLanguage={myLanguage}
                    online={online}
                    book={book}
                    onReadClicked={onReadClicked}
                    slug={slug}
                    pagesCount={pagesCount}
                    isFavourite={isFavourite}
                    title={title}
                    description={description}
                    coverImage={coverImage}
                    titleClasses={titleClasses}
                    level={level}
                    isTranslation={isTranslation}
                    isRelevelled={isRelevelled}
                    authors={authors}
                    publisher={publisher}
                    illustrators={illustrators}
                    photographers={photographers}
                    originalTitle={originalTitle}
                    originalSlug={originalSlug}
                    originalAuthors={originalAuthors}
                    onProfileLinkClicked={onProfileLinkClicked}
                    favouriteStat={favouriteStat}
                    favouriteStatMainBook={favouriteStatMainBook}
                    readsCount={readsCount}
                    viewport={viewport}
                    isLoggedIn={isLoggedIn}
                    approvedTitles={approvedTitles}
                    storyLevelColour={storyLevelColour}
                    translators={translators}
                    edition_type={edition_type}
                    isOriginalStoryPresent={isOriginalStoryPresent}
                    readAlouds={readAlouds}
                    readToMeModalOpen={readToMeModalOpen}
                    roles={roles}
                    downloadLinks={downloadLinks}
                    bookStoryDownload={bookStoryDownload}
                    isDownloadable={isDownloadable}
                    availableOffline={availableOffline}
                    offlineBooksCount={offlineBooksCount}
                    onAddToOfflineClicked={onAddToOfflineClicked}
                    onOpenModal={onOpenModal}
                    isSavingOffline={isSavingOffline}
                    translations={translations}
                    isMobileWidth={isMobileWidth}
                    readingListMembershipCount={readingListMembershipCount}
                    tags={tags}
                    onMicLink={onMicLink}
                    language={language}
                />
            ) : (
                <BookLargeScreen
                    t={t}
                    baseClassName={baseClassName}
                    title_logos={title_logos}
                    myLanguage={myLanguage}
                    online={online}
                    book={book}
                    onReadClicked={onReadClicked}
                    slug={slug}
                    pagesCount={pagesCount}
                    isFavourite={isFavourite}
                    title={title}
                    description={description}
                    coverImage={coverImage}
                    titleClasses={titleClasses}
                    level={level}
                    isTranslation={isTranslation}
                    isRelevelled={isRelevelled}
                    authors={authors}
                    publisher={publisher}
                    illustrators={illustrators}
                    photographers={photographers}
                    originalTitle={originalTitle}
                    originalSlug={originalSlug}
                    originalAuthors={originalAuthors}
                    onProfileLinkClicked={onProfileLinkClicked}
                    favouriteStat={favouriteStat}
                    favouriteStatMainBook={favouriteStatMainBook}
                    readsCount={readsCount}
                    viewport={viewport}
                    isLoggedIn={isLoggedIn}
                    approvedTitles={approvedTitles}
                    storyLevelColour={storyLevelColour}
                    translators={translators}
                    edition_type={edition_type}
                    isOriginalStoryPresent={isOriginalStoryPresent}
                    readAlouds={readAlouds}
                    readToMeModalOpen={readToMeModalOpen}
                    roles={roles}
                    downloadLinks={downloadLinks}
                    bookStoryDownload={bookStoryDownload}
                    isDownloadable={isDownloadable}
                    availableOffline={availableOffline}
                    offlineBooksCount={offlineBooksCount}
                    onAddToOfflineClicked={onAddToOfflineClicked}
                    onOpenModal={onOpenModal}
                    isSavingOffline={isSavingOffline}
                    translations={translations}
                    isMobileWidth={isMobileWidth}
                    readingListMembershipCount={readingListMembershipCount}
                    tags={tags}
                    onMicLink={onMicLink}
                    divRef={divRef}
                    divHeight={divHeight}
                    language={language}
                />
            )}
            <SimilarBooks
                myLanguage={myLanguage}
                offline={!online}
                similarBooks={similarBooks}
                t={t}
                viewport={viewport}
            />

            {
                <div
                    id="commentsSectionContainer"
                    className={`${baseClassName}__disqus-section`}
                >
                    <div className="__disqussion">
                        <Comments
                            source="book"
                            id={id}
                            roles={roles}
                            viewport={viewport}
                            isLoggedIn={isLoggedIn}
                        />
                    </div>
                </div>
            }
            {isModalVisible.noSpaceForMoreOfflineBooks && (
                <NoSpaceForMoreOfflineBooksModal
                    viewport={viewport}
                    onClose={() => onCloseModal('noSpaceForMoreOfflineBooks')}
                    maximum={MAX_OFFLINE_BOOKS_COUNT}
                />
            )}
            {isModalVisible.delete ? (
                <DeleteModal
                    onConfirm={() => {
                        onClickRemoveFromOffline();
                        addSlimNotification({
                            type: 'info',
                            content: t(
                                'BookCard.removed-from-offline-library-notification'
                            ),
                        });
                    }}
                    myLanguage={myLanguage}
                    onClose={() => onCloseModal('delete')}
                    viewport={viewport}
                    count={1}
                    books={[book]}
                    userEmail={userEmail}
                    recordGaEvents={recordGaEvents}
                    baseClassName={baseClassName}
                    addSlimNotification={addSlimNotification}
                />
            ) : null}
            {isReadToMeModalVisible && readToMeModal()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isFavourite: state.book.book.isFavourite,
        isFavouriting: state.book.book.isFavouriting,
        helpStatus: state.help.helpStatus,
    };
};

const mapDispatchToProps = {
    favouriteBookWorkflow,
};

Book.propTypes = {
    t: PropTypes.func.isRequired,
    viewport: PropTypes.shape({
        large: PropTypes.bool.isRequired,
    }).isRequired,
    online: PropTypes.bool.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        slug: PropTypes.string.isRequired,
        coverImage: PropTypes.shape({
            isThumbnailEdited: PropTypes.bool,
            sizes: PropTypes.arrayOf(
                PropTypes.shape({
                    url: PropTypes.string.isRequired,
                    width: PropTypes.number,
                    height: PropTypes.number,
                })
            ),
        }).isRequired,
        level: PropTypes.string,
        pagesCount: PropTypes.number,
        storyLevelColour: PropTypes.string,
        title_logos: PropTypes.arrayOf(PropTypes.string),
        likesCount: PropTypes.number,
        isFavourite: PropTypes.bool,
        readsCount: PropTypes.number,
        readAlouds: PropTypes.arrayOf(
            PropTypes.shape({
                coachingMaterialId: PropTypes.string,
                filename: PropTypes.string,
                url: PropTypes.string,
            })
        ),
        language: PropTypes.string,
        publisher: PropTypes.string,
        edition_type: PropTypes.string,
        isRelevelled: PropTypes.bool,
        downloadLinks: PropTypes.arrayOf(PropTypes.string),
        isDownloadable: PropTypes.bool,
        isTranslation: PropTypes.bool,
        authors: PropTypes.arrayOf(PropTypes.string),
        illustrators: PropTypes.arrayOf(PropTypes.string),
        photographers: PropTypes.arrayOf(PropTypes.string),
        translators: PropTypes.arrayOf(PropTypes.string),
        translations: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
        readingListMembershipCount: PropTypes.number,
        onMicLink: PropTypes.string,
        originalStory: PropTypes.shape({
            name: PropTypes.string,
            slug: PropTypes.string,
            authors: PropTypes.arrayOf(PropTypes.string),
            isPresent: PropTypes.bool,
        }),
    }).isRequired,
    helpStatus: PropTypes.bool.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    isLoggedIn: PropTypes.bool.isRequired,
    isFavouriting: PropTypes.bool,
    favouriteBookWorkflow: PropTypes.func.isRequired,
    isSavingOffline: PropTypes.bool,
    availableOffline: PropTypes.bool,
    offlineBooksCount: PropTypes.number,
    bookStoryDownload: PropTypes.func,
    navigate: PropTypes.func.isRequired,
    readToMeAction: PropTypes.func.isRequired,
    onClickAddToOffline: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired,
    recordGaEvents: PropTypes.func.isRequired,
    addSlimNotification: PropTypes.func.isRequired,
    onClickRemoveFromOffline: PropTypes.func.isRequired,
};

export default withRouter(
    translate()(connect(mapStateToProps, mapDispatchToProps)(Book))
);
