import React from 'react';
import PropTypes from 'prop-types';
import { isRightToLeftDirection } from '../../../../../util/utils';

const IllustratorsHeader = ({ baseClassName, title, subHeading }) => {
    return (
        <div
            className={
                isRightToLeftDirection
                    ? `${baseClassName}_title-wrapper-rtl`
                    : `${baseClassName}_title-wrapper`
            }
        >
            <h4
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_title-rtl`
                        : `${baseClassName}_title`
                }
            >
                {title}
            </h4>
            <h4
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_subheading-rtl`
                        : `${baseClassName}_subheading`
                }
            >
                {subHeading}
            </h4>
        </div>
    );
};

IllustratorsHeader.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
};

export default IllustratorsHeader;
