import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Favourites from '../Favourites';
import './ResourceCard.scss';
import Block from '../Block';
import Link from '../Link';
import { favouriteReferenceWorkflow } from '../../redux/referencesActions';
import { isRightToLeftDirection } from '../../util/utils';

const mapDispatchToProps = {
    favouriteReferenceWorkflow,
};

const mapStateToProps = (state, ownProps) => {
    const requiredResources = state.references.references.length
        ? state.references.references
        : state.references.favouriteResources;
    const associatedindex = requiredResources.findIndex(
        (reference) => reference.id === ownProps.id
    );
    let associatedReference = null;
    if (associatedindex >= 0) {
        associatedReference = requiredResources[associatedindex];
    }
    return {
        isFavourite: associatedReference
            ? associatedReference.is_favourite
            : ownProps.isFavourite,
        isFavouriting: associatedReference
            ? associatedReference.isFavouriting
            : null,
    };
};

const ResourceCard = ({
    id,
    isFavourite,
    parentClassName,
    slug,
    t,
    title,
    likesCount,
    coverImage,
    resourceThumbnailGTM,
    resourceTitleGTM,
    isSimilarResource,
    myLanguage,
    favouriteReferenceWorkflow,
}) => {
    const favoriteToggleHandler = () => {
        favouriteReferenceWorkflow(id);
    };

    const baseClassName = 'pb-resource-card';

    const classes = classNames({
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    });

    const resourcePath = `/resourcedetail/${slug}`;

    const similarResourcePath = `/resourcedetail/${slug}`;

    let favouriteStat = (
        <Favourites
            isFavourite={isFavourite}
            favouriteToggleHandler={favoriteToggleHandler}
            isLoggedIn={true}
            bookSlug={slug}
            likes={likesCount}
        />
    );

    return (
        <div className={classes}>
            <Block
                noHorizontalPadding
                noVerticalPadding
                background="transparent"
            >
                <div className="__resourceCard-section">
                    <Link
                        isInternal
                        noPadding
                        shouldOpenNewPage
                        translation
                        href={
                            isSimilarResource
                                ? similarResourcePath
                                : resourcePath
                        }
                        dataGTM={resourceThumbnailGTM}
                    >
                        <div
                            className={`${baseClassName}__resource-image`}
                            data-gtm={resourceThumbnailGTM}
                        >
                            <img
                                src={coverImage}
                                alt="Resource Thumbnail Img"
                                data-gtm={resourceThumbnailGTM}
                            />
                        </div>
                    </Link>
                    <div className="__View-like-count">
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}__like-count-arabic`
                                    : `${baseClassName}__like-count`
                            }
                        >
                            {favouriteStat}
                        </div>
                    </div>
                    <Link
                        isInternal
                        noPadding
                        shouldOpenNewPage
                        translation
                        href={
                            isSimilarResource
                                ? similarResourcePath
                                : resourcePath
                        }
                        dataGTM={resourceTitleGTM}
                    >
                        <div
                            className={
                                myLanguage === 'lo'
                                    ? `${baseClassName}_lo-title`
                                    : isRightToLeftDirection
                                    ? `${baseClassName}_title ${baseClassName}__align-right`
                                    : `${baseClassName}_title`
                            }
                            data-gtm={resourceTitleGTM}
                        >
                            {title}
                        </div>
                    </Link>
                </div>
            </Block>
        </div>
    );
};

ResourceCard.propTypes = {
    parentClassName: PropTypes.string,
    id: PropTypes.string.isRequired,
    isFavourite: PropTypes.bool,
    slug: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    likesCount: PropTypes.number.isRequired,
    coverImage: PropTypes.string.isRequired,
    resourceThumbnailGTM: PropTypes.string.isRequired,
    resourceTitleGTM: PropTypes.string.isRequired,
    isSimilarResource: PropTypes.bool.isRequired,
    myLanguage: PropTypes.string.isRequired,
    favouriteReferenceWorkflow: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(ResourceCard));
