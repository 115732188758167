import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import withRouter from '../../util/withRouter';
import right from '../../assets/Right_option.svg';
import left from '../../assets/Left_option.svg';
import { OfflineStory } from '../../api';
import DataLoader from '../DataLoader/DataLoader';
import BookUploadHeader from '../BookUploadHeader';
import BookUploadFooter from '../BookUploadFooter';
import BookPreviewCarousel from './components/BookPreviewCarousel';
import { isRightToLeftDirection } from '../../util/utils';
import './BookPreview.scss';
import '../Book/Book.scss';

const BookPreview = ({ t, location, navigate }) => {
    const baseClassName = 'book-preview';

    const [startIndex, setStartIndex] = useState(1);
    const [bookLength, setBookLength] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        OfflineStory.fetchOfflineStoryDetail(location.pathname.split('/')[2])
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                const data = response.data;
                let listOfImages = [...data.pages];
                setBookLength([data, ...listOfImages]);
                setLoader(false);
            });
    }, []);

    const backPage = () => {
        navigate(`/upload/${location.pathname.split('/')[2]}`);
    };

    const nextPage = () => {
        navigate(`/publication/${location.pathname.split('/')[2]}`);
    };

    return (
        <div>
            {loader ? (
                <DataLoader />
            ) : (
                <div className={`${baseClassName}__page_view_container`}>
                    <div className={`${baseClassName}__arrow_position`}>
                        <img
                            onClick={() => {
                                if (startIndex > 1) {
                                    setStartIndex((prev) => prev - 2);
                                }
                            }}
                            className={
                                (isRightToLeftDirection
                                    ? `${baseClassName}__right_arrow `
                                    : `${baseClassName}__left_arrow `) +
                                (startIndex > 1
                                    ? ''
                                    : `${baseClassName}__disable_button`)
                            }
                            src={isRightToLeftDirection ? right : left}
                            alt="left"
                        />
                    </div>
                    <div>
                        <BookUploadHeader currentPage={3} />
                        <BookPreviewCarousel
                            baseClassName={baseClassName}
                            t={t}
                            bookLength={bookLength}
                            startIndex={startIndex}
                        />
                        <BookUploadFooter
                            backPage={backPage}
                            nextPage={nextPage}
                        />
                    </div>
                    <div className={`${baseClassName}__arrow_position`}>
                        <img
                            onClick={() => {
                                if (startIndex < bookLength.length - 1) {
                                    setStartIndex((prev) => prev + 2);
                                }
                            }}
                            className={
                                (isRightToLeftDirection
                                    ? `${baseClassName}__left_arrow `
                                    : `${baseClassName}__right_arrow `) +
                                (startIndex < bookLength.length - 1
                                    ? ''
                                    : `${baseClassName}__disable_button`)
                            }
                            src={isRightToLeftDirection ? left : right}
                            alt="right"
                        />
                    </div>
                </div>
            )}{' '}
        </div>
    );
};

BookPreview.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    navigate: PropTypes.func.isRequired,
};
export default withRouter(translate()(BookPreview));
