import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../Button';
import { isRightToLeftDirection } from '../../../../../util/utils';

const AuthorsTable = ({ t, offlineAuthors, baseClassName, onRemoveAuthor }) => {
    return (
        <React.Fragment>
            {offlineAuthors.length >= 1 && (
                <table>
                    <tr
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}_table-header-rtl`
                                : `${baseClassName}_table-header`
                        }
                    >
                        <th>{t('PublicationForm.authorMail')}</th>
                        <th>{t('PublicationForm.authorFirstName')}</th>
                        <th>{t('PublicationForm.authorLastName')}</th>
                        <th>{t('PublicationForm.authorBio')}</th>
                        <th>{t('PublicationForm.actions')}</th>
                    </tr>
                    {offlineAuthors.length >= 1 &&
                        offlineAuthors.map((author, index) => {
                            return (
                                <tr
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}_table-body-rtl`
                                            : `${baseClassName}_table-body`
                                    }
                                >
                                    <td>{author && author.email}</td>
                                    <td>{author && author.first_name}</td>
                                    <td>{author && author.last_name}</td>
                                    <td>{author && author.bio}</td>
                                    <td>
                                        <Button
                                            label={t('PublicationForm.remove')}
                                            variant="remove"
                                            onClick={() =>
                                                onRemoveAuthor(index, author.id)
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                </table>
            )}
        </React.Fragment>
    );
};

AuthorsTable.propTypes = {
    t: PropTypes.func.isRequired,
    offlineAuthors: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            bio: PropTypes.string,
            id: PropTypes.string,
        })
    ).isRequired,
    baseClassName: PropTypes.string.isRequired,
    onRemoveAuthor: PropTypes.func.isRequired,
};

export default AuthorsTable;
