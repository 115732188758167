import React from 'react';
import PropTypes from 'prop-types';
import InfoUnit from '../../InfoUnit';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const Website = ({ website }) => {
    return (
        <React.Fragment>
            {website !== null && website !== '' && (
                <div className="__profile-website">
                    <InfoUnit noWrap key={website}>
                        <Link
                            shouldOpenNewPage={true}
                            href={website}
                            dataGTM="profile-website"
                        >
                            <SvgIcon name="website" />
                        </Link>
                    </InfoUnit>
                </div>
            )}
        </React.Fragment>
    );
};

Website.propTypes = {
    website: PropTypes.string.isRequired,
};

export default Website;
