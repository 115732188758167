import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import './SortOption.scss';
import Label from './components/Label';
import SortBookMobile from './components/SortBookMobile';
import Sort from './components/Sort';
import SortResourceMobile from './components/SortResourceMobile';
import SortResources from './components/SortResources';
import SortVideoMobile from './components/SortVideoMobile';
import SortVideo from './components/SortVideo';
import SortIllustrations from './components/SortIllustrations';
import SortIllustrationsMobile from './components/SortIllustrationsMobile';
import SortReadingList from './components/SortReadingList';
import SortReadingListMobile from './components/SortReadingListMobile';
import SortTranslate from './components/SortTranslate';
import SortTranslateMobile from './components/SortTranslateMobile';

const SortOption = ({
    t,
    value,
    onChange,
    id,
    label,
    parentClassName,
    isBookSectionMobile,
    isVideoMobile,
    isResourceMobile,
    isReadingListMobile,
    isIllustrationMobile,
    isTranslate,
    isTranslateMobile,
    isIllustration,
    isResources,
    isAddBook,
    isAddBookMobile,
    isBookSection,
    isVideos,
    isReadingList,
    favoriteSortGTM,
    viewSortGTM,
    alphabetSortGTM,
    readSortGTM,
    isRightToLeftDirection,
    options,
}) => {
    const baseClassName = 'pb-sort-option';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const [isMostLiked, setIsMostLiked] = useState(true);
    const [isMostRead, setIsMostRead] = useState(true);
    const [isMostView, setIsMostView] = useState(true);
    const [isAscend, setIsAscend] = useState(true);

    useEffect(() => {
        if (value === 'Z-A') {
            setIsAscend(false);
        }
    }, []);

    const mostLikedSortChange = (e) => {
        onChange(e);
        setIsMostLiked(false);
    };

    const leastLikedSortChange = (e) => {
        onChange(e);
        setIsMostLiked(true);
    };

    const mostReadSortChange = (e) => {
        onChange(e);
        setIsMostRead(false);
    };

    const leastReadSortChange = (e) => {
        onChange(e);
        setIsMostRead(true);
    };

    const ascendingSortChange = (e) => {
        onChange(e);
        setIsAscend(false);
    };

    const descendingSortChange = (e) => {
        onChange(e);
        setIsAscend(true);
    };

    const mostViewSortChange = (e) => {
        onChange(e);
        setIsMostView(false);
    };

    const leastViewSortChange = (e) => {
        onChange(e);
        setIsMostView(true);
    };

    return (
        <div className={classNames(classes)}>
            {isBookSectionMobile && (
                <div className={`${baseClassName}__input-wrapper-sort`}>
                    {label && (
                        <Label
                            baseClassName={baseClassName}
                            id={id}
                            label={label}
                        />
                    )}
                    <SortBookMobile
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        favoriteSortGTM={favoriteSortGTM}
                        isMostRead={isMostRead}
                        mostReadSortChange={mostReadSortChange}
                        leastReadSortChange={leastReadSortChange}
                        readSortGTM={readSortGTM}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                    />
                </div>
            )}
            {isBookSection && (
                <div className={`${baseClassName}__input-wrapper`}>
                    <Sort
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        favoriteSortGTM={favoriteSortGTM}
                        baseClassName={baseClassName}
                        isMostRead={isMostRead}
                        mostReadSortChange={mostReadSortChange}
                        leastReadSortChange={leastReadSortChange}
                        readSortGTM={readSortGTM}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                    />
                </div>
            )}
            {isResourceMobile && (
                <div className={`${baseClassName}__input-wrapper-sort`}>
                    {label && (
                        <Label
                            baseClassName={baseClassName}
                            id={id}
                            label={label}
                        />
                    )}
                    <SortResourceMobile
                        t={t}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                    />
                </div>
            )}
            {isResources && (
                <div className={`${baseClassName}__input-wrapper`}>
                    <SortResources
                        t={t}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                        baseClassName={baseClassName}
                    />
                </div>
            )}
            {isAddBookMobile && (
                <div className={`${baseClassName}__input-wrapper-sort`}>
                    {label && (
                        <Label
                            baseClassName={baseClassName}
                            id={id}
                            label={label}
                        />
                    )}
                    <SortBookMobile
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        favoriteSortGTM={favoriteSortGTM}
                        isMostRead={isMostRead}
                        mostReadSortChange={mostReadSortChange}
                        leastReadSortChange={leastReadSortChange}
                        readSortGTM={readSortGTM}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                    />
                </div>
            )}
            {isAddBook && (
                <div className={`${baseClassName}__input-wrapper`}>
                    <Sort
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        favoriteSortGTM={favoriteSortGTM}
                        baseClassName={baseClassName}
                        isMostRead={isMostRead}
                        mostReadSortChange={mostReadSortChange}
                        leastReadSortChange={leastReadSortChange}
                        readSortGTM={readSortGTM}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                    />
                </div>
            )}
            {isVideoMobile && (
                <div className={`${baseClassName}__input-wrapper-sort`}>
                    {label && (
                        <Label
                            baseClassName={baseClassName}
                            id={id}
                            label={label}
                        />
                    )}
                    <SortVideoMobile
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        favoriteSortGTM={favoriteSortGTM}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                        isMostView={isMostView}
                        mostViewSortChange={mostViewSortChange}
                        leastViewSortChange={leastViewSortChange}
                        viewSortGTM={viewSortGTM}
                    />
                </div>
            )}
            {isVideos && (
                <div className={`${baseClassName}__input-wrapper`}>
                    <SortVideo
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        favoriteSortGTM={favoriteSortGTM}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                        isMostView={isMostView}
                        mostViewSortChange={mostViewSortChange}
                        leastViewSortChange={leastViewSortChange}
                        viewSortGTM={viewSortGTM}
                        baseClassName={baseClassName}
                    />
                </div>
            )}
            {isIllustration && (
                <div className={`${baseClassName}__input-wrapper`}>
                    <SortIllustrations
                        t={t}
                        isMostView={isMostView}
                        mostViewSortChange={mostViewSortChange}
                        leastViewSortChange={leastViewSortChange}
                        viewSortGTM={viewSortGTM}
                        baseClassName={baseClassName}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                    />
                </div>
            )}
            {isIllustrationMobile && (
                <div className={`${baseClassName}__input-wrapper-sort`}>
                    {label && (
                        <Label
                            baseClassName={baseClassName}
                            id={id}
                            label={label}
                        />
                    )}
                    <SortIllustrationsMobile
                        t={t}
                        isMostView={isMostView}
                        mostViewSortChange={mostViewSortChange}
                        leastViewSortChange={leastViewSortChange}
                        viewSortGTM={viewSortGTM}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                    />
                </div>
            )}
            {isReadingList && (
                <div className={`${baseClassName}__input-wrapper`}>
                    <SortReadingList
                        t={t}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                        alphabetSortGTM={alphabetSortGTM}
                        baseClassName={baseClassName}
                    />
                </div>
            )}
            {isReadingListMobile && (
                <div className={`${baseClassName}__input-wrapper-sort`}>
                    {label && (
                        <Label
                            baseClassName={baseClassName}
                            id={id}
                            label={label}
                        />
                    )}
                    <SortReadingListMobile
                        t={t}
                        isAscend={isAscend}
                        ascendingSortChange={ascendingSortChange}
                        descendingSortChange={descendingSortChange}
                    />
                </div>
            )}
            {isTranslate && (
                <div className={`${baseClassName}__input-wrapper`}>
                    <SortTranslate
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        baseClassName={baseClassName}
                        favoriteSortGTM={favoriteSortGTM}
                        isMostView={isMostView}
                        mostViewSortChange={mostViewSortChange}
                        leastViewSortChange={leastViewSortChange}
                        viewSortGTM={viewSortGTM}
                    />
                </div>
            )}
            {isTranslateMobile && (
                <div className={`${baseClassName}__input-wrapper-sort`}>
                    {label && (
                        <Label
                            baseClassName={baseClassName}
                            id={id}
                            label={label}
                        />
                    )}
                    <SortTranslateMobile
                        t={t}
                        isMostLiked={isMostLiked}
                        mostLikedSortChange={mostLikedSortChange}
                        leastLikedSortChange={leastLikedSortChange}
                        favoriteSortGTM={favoriteSortGTM}
                        isMostView={isMostView}
                        mostViewSortChange={mostViewSortChange}
                        leastViewSortChange={leastViewSortChange}
                        viewSortGTM={viewSortGTM}
                    />
                </div>
            )}
        </div>
    );
};

SortOption.propTypes = {
    t: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    parentClassName: PropTypes.string,
    isBookSectionMobile: PropTypes.bool,
    isVideoMobile: PropTypes.bool,
    isResourceMobile: PropTypes.bool,
    isReadingListMobile: PropTypes.bool,
    isIllustrationMobile: PropTypes.bool,
    isTranslate: PropTypes.bool,
    isTranslateMobile: PropTypes.bool,
    isIllustration: PropTypes.bool,
    isResources: PropTypes.bool,
    isAddBook: PropTypes.bool,
    isAddBookMobile: PropTypes.bool,
    isBookSection: PropTypes.bool,
    isVideos: PropTypes.bool,
    isReadingList: PropTypes.bool,
    favoriteSortGTM: PropTypes.string,
    viewSortGTM: PropTypes.string,
    alphabetSortGTM: PropTypes.string,
    readSortGTM: PropTypes.string,
    isRightToLeftDirection: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
};

export default translate()(SortOption);
