import React, { useState, useEffect } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import crop from '../../assets/crop.svg';
import uploadImg from '../../assets/upload_img.svg';
import reset from '../../assets/reset.svg';
import BookUploadHeader from '../BookUploadHeader';
import CroppedImage from '../CroppedImage';
import BookUploadFooter from '../BookUploadFooter';
import { API_URL, OfflineStory } from '../../api';
import DataLoader from '../DataLoader/DataLoader';
import withRouter from '../../util/withRouter';
import UploadPDF from './components/UploadPDF';
import UploadThumbnail from './components/UploadThumbnail';
import './BookUpload.scss';

Modal.setAppElement('body');

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        overflow: 'hidden',
    },
    content: {
        position: 'fixed',
        top: '0%',
        left: '0%',
        right: '0%',
        bottom: '0%',
        border: '1px solid #ccc',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '0px',
        zIndex: '999',
        overflowY: 'hidden',
        background: '#808080',
    },
};

const BookUpload = ({ t, location, navigate }) => {
    const baseClassName = 'book-upload_pdf';
    const progressBar = document.getElementById('progress-bar');
    const gallery = document.getElementById('gallery');
    const thumbnail = document.getElementById('thumbnail_view');

    const [fileName, setFileName] = useState('');
    const [data, setData] = useState('');
    const [restoreImage, setRestoreImage] = useState('');
    const [base64Image, setBase64Image] = useState('');
    const [uploadImage, setUploadImage] = useState('');
    const [progressValue, setProgressValue] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [encoding, setEncoding] = useState(true);
    const [firstTime, setFirstTime] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [disable, setDisable] = useState(true);
    const [loader, setLoader] = useState(false);
    const [uploadProgress, setUploadProgress] = useState([]);

    useEffect(() => {
        setLoader(true);
        OfflineStory.fetchOfflineStoryDetail(location.pathname.split('/')[2])
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setData(response.data);
                setFirstTime(true);
                setLoader(false);
            });
    }, []);

    useEffect(() => {
        if (
            firstTime &&
            data &&
            data.cover_thumbnail_image &&
            data.pages &&
            data.pages[0] &&
            data.pages[0].url
        ) {
            setFirstTime(false);
            setFileName(data.backup_pdf_file_name);
            setUploadStarted(true);
            setRestoreImage(
                'data:image/png;base64,'.concat(data.cover_thumbnail_image)
            );

            progressBar.classList.remove(`${baseClassName}__progress-bar`);
            initializeProgress(1);
            updateProgress(0, 100);
            pdfView(gallery, data.pages[0].url);
            pdfView(
                thumbnail,
                data &&
                    'data:image/png;base64,'.concat(data.cover_thumbnail_image)
            );
        }
    }, [data, firstTime]);

    const stopUploading = () => {
        setFileName(t('Book.drag'));
        setUploadStarted(false);
        setEncoding(true);
        setDisable(true);
        setUploadImage('');
        setUploadProgress([]);
        progressBar.classList.add(`${baseClassName}__progress-bar`);
        gallery.innerHTML = '';
        thumbnail.innerHTML = '';
    };

    const uploadFile = (file, i, status) => {
        let url = `${API_URL}/offline_stories/${
            location.pathname.split('/')[2]
        }`;
        let xhr = new XMLHttpRequest();
        let formData = new FormData();
        xhr.open('PUT', url, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.upload.addEventListener('progress', (e) => {
            updateProgress(i, (e.loaded * 100.0) / e.total || 100);
        });
        xhr.addEventListener('readystatechange', function (e) {
            if (xhr.readyState === 4 && xhr.status === 200) {
                updateProgress(i, 100);
                startApiCall();
            } else if (xhr.readyState === 4 && xhr.status !== 200) {
                alert('Error while uploading the file.');
                stopUploading();
            }
        });
        formData.append('story[pdf_file]', file);
        xhr.send(formData);
    };

    const updateProgress = (fileNumber, percent) => {
        uploadProgress[fileNumber] = percent;
        let total =
            uploadProgress.reduce((tot, curr) => tot + curr, 0) /
            uploadProgress.length;
        progressBar.value = total;

        setProgressValue(total);
    };

    const handleFiles = (e) => {
        let files = e.target.files;
        if (files && files[0] && files[0].size < 10915705) {
            files = [...files];
            setFileName(files[0].name);
            setUploadStarted(true);

            progressBar.classList.remove(`${baseClassName}__progress-bar`);
            initializeProgress(files.length);
            files.forEach(uploadFile);
        } else {
            alert('File size should be less than 10mb');
        }
    };

    const initializeProgress = (numFiles) => {
        if (progressBar) {
            progressBar.value = 0;

            for (let i = numFiles; i > 0; i--) {
                setUploadProgress((prev) => [...prev, 0]);
            }
        }
    };

    const pdfView = (value, url) => {
        let img = document.createElement('img');
        img.src = url;
        value.innerHTML = '';
        value.appendChild(img);
        setUploadImage(url);
        setEncoding(false);
        setDisable(false);
    };

    const startApiCall = () => {
        let dataFetch = setInterval(() => {
            OfflineStory.fetchOfflineStoryDetail(
                location.pathname.split('/')[2]
            )
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.data;
                    if (data.pages_created) {
                        pdfView(gallery, data.pages && data.pages[0].url);
                        pdfView(
                            thumbnail,
                            'data:image/png;base64,'.concat(
                                data.cover_thumbnail_image
                            )
                        );
                        setRestoreImage(
                            'data:image/png;base64,'.concat(
                                data.cover_thumbnail_image
                            )
                        );

                        clearInterval(dataFetch);
                    }
                });
        }, 30000);
    };

    let selectImgFile = (e) => {
        setUploadImage(e);

        let reader = new FileReader();
        reader.onloadend = function () {
            let img = document.createElement('img');
            img.src = reader.result;
            thumbnail.innerHTML = '';
            thumbnail.appendChild(img);
        };
        if (e && e.target && e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
            apiToUpdateImg(e.target.files[0]);
        }
    };

    const apiToUpdateImg = (file) => {
        setLoader(true);
        let url = `${API_URL}/offline_stories/${
            location.pathname.split('/')[2]
        }/modify_cover_thumbnail`;
        let xhr = new XMLHttpRequest();
        let formData = new FormData();
        xhr.open('PUT', url, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        formData.append('story[cover_thumbnail]', file);
        xhr.send(formData);
        xhr.onload = () => {
            setLoader(false);
        };
    };

    const resetImage = () => {
        if (restoreImage !== '') {
            pdfView(thumbnail, restoreImage);
            let file = dataURLtoFile(restoreImage, `${Date()}.jpg`);
            apiToUpdateImg(file);
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const nextPage = () => {
        navigate(`/preview/${location.pathname.split('/')[2]}`);
    };

    const backPage = () => {
        navigate(`/details/${location.pathname.split('/')[2]}`);
    };

    const updatedImage = (value) => {
        setBase64Image(value);
    };

    const closePopup = () => {
        setModalIsOpen(false);
    };

    const croppedImage = () => {
        if (base64Image !== '') {
            let img = document.createElement('img');
            img.src = base64Image;
            thumbnail.innerHTML = '';
            thumbnail.appendChild(img);
        }
        closePopup();
        const file = dataURLtoFile(base64Image, `${Date()}.jpg`);
        apiToUpdateImg(file);
    };

    return (
        <div>
            <div className={`${baseClassName}__page_view_container`}>
                <div>
                    <BookUploadHeader currentPage={2} />
                    <div className={`${baseClassName}__book_upload_container`}>
                        <UploadPDF
                            t={t}
                            baseClassName={baseClassName}
                            fileName={fileName}
                            uploadStarted={uploadStarted}
                            progressValue={progressValue}
                            encoding={encoding}
                            handleFiles={handleFiles}
                            stopUploading={stopUploading}
                        />
                        <UploadThumbnail
                            t={t}
                            baseClassName={baseClassName}
                            uploadImage={uploadImage}
                            setModalIsOpen={setModalIsOpen}
                            crop={crop}
                            uploadImg={uploadImg}
                            selectImgFile={selectImgFile}
                            resetImage={resetImage}
                            reset={reset}
                        />
                    </div>
                    <BookUploadFooter
                        disable={disable}
                        backPage={backPage}
                        nextPage={nextPage}
                    />
                </div>
                <div></div>
                <Modal
                    isOpen={modalIsOpen}
                    style={customStyles}
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.style.overflow = 'auto';
                    }}
                >
                    <CroppedImage
                        onCropped={updatedImage}
                        srcData={thumbnail}
                        handleClose={closePopup}
                        handleSave={croppedImage}
                    />
                </Modal>
            </div>
            {loader && (
                <div className={`${baseClassName}__book_loader`}>
                    <DataLoader />
                </div>
            )}
        </div>
    );
};

BookUpload.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withRouter(translate()(BookUpload));
