import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';

const Title = ({ t }) => {
    return (
        <Block offlineSection normalPadding>
            <ContentStyler myFavourite>
                <div className="__myfavourites-header">
                    <h3>{t('global.my-favorites')}</h3>
                </div>
            </ContentStyler>
        </Block>
    );
};

Title.propTypes = {
    t: PropTypes.func.isRequired,
};

export default Title;
