import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import NoSpaceForMoreOfflineBooksModal from '../Book/components/NoSpaceForMoreOfflineBooksModal';
import pageCountImg from '../../assets/border-image.png';
import withRouter from '../../util/withRouter';
import {
    addBookToReadingListActionAsync,
    removeBookFromReadingListActionAsync,
} from '../../redux/readingListsActions';
import {
    saveOfflineWorkflow,
    unsaveOffline,
} from '../../redux/offlineBooksActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';
import { imageSrcsetSizes, MAX_OFFLINE_BOOKS_COUNT } from '../../lib/constants';
import { addSlimNotification } from '../../redux/slimNotificationActions';
import './ManageBookCard.scss';
import BookImage from './components/BookImage';
import BookTitle from './components/BookTitle';
import BookAuthors from './components/BookAuthors';
import BookIllustrators from './components/BookIllustrators';
import RemoveButton from './components/RemoveButton';
import AddRemoveButton from './components/AddRemoveButton';
import BookPoster from './components/BookPoster';

const ManageBookCard = ({
    addBtn,
    disabled,
    loading,
    onClick,
    saveOfflineWorkflow,
    recordGaEvents,
    userEmail,
    addSlimNotification,
    t,
    addBookToReadingListActionAsync,
    removeBookFromReadingListActionAsync,
    book,
    fontSize,
    offline,
    viewport,
    faded,
    hideReadCountStat,
    bookDetails,
    pagesCount,
    manageReadingListId,
    isManageReadingList,
    isAddBook,
    addBookImageGTM,
    asdBookTitleGTM,
    addBookAuthorGTM,
    addBookIllustratorsGTM,
    removeFromBookListGTM,
    addToBookListGTM,
}) => {
    const {
        level,
        slug,
        coverImage,
        authors = [],
        illustrators = [],
        recommended,
        readsCount,
        storyLevelColour,
    } = book;

    const [isShowRemoveButton, setIsShowRemoveButton] = useState(!addBtn);
    const [isModalVisible, setIsModalVisible] = useState({
        noSpaceForMoreOfflineBooks: false,
    });

    const title = book.name ? book.name : book.title;
    const baseClassName = 'pb-manage-book-card';

    const bookPath = `/stories/${slug}`;

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--font-size-${fontSize}`]: fontSize,
        [`${baseClassName}--disabled`]: disabled || loading,
        [`${baseClassName}--loading`]: loading,
        [`${baseClassName}--recommended`]: recommended,
        [`${baseClassName}--faded`]: faded,
        [`${baseClassName}--has-footer`]:
            recommended || (readsCount && !hideReadCountStat),
    };
    const levelStyles = {
        [`${baseClassName}--level-${level}`]: level,
        [`${baseClassName}__manage-level`]: true,
    };
    const titleClasses = {
        [`${baseClassName}__title`]: true,
    };

    const handleClick = (e) => {
        if (disabled || loading) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            if (onClick) {
                e.preventDefault();
                onClick(e);
            }
        }
    };

    const removeFromBookList = (book) => {
        setIsShowRemoveButton(true);

        removeBookFromReadingListActionAsync(book);
    };

    const addToBookList = (book) => {
        setIsShowRemoveButton(false);
        addBookToReadingListActionAsync(book);
    };

    const onCloseModal = (modalName) => {
        setIsModalVisible({
            [modalName]: false,
        });
    };

    return (
        <div className={classNames(classes)}>
            <div className={`${baseClassName}__container`}>
                {!bookDetails ? (
                    <div className={`${baseClassName}__wrapper`}>
                        <BookImage
                            onClick={onClick}
                            baseClassName={baseClassName}
                            bookPath={bookPath}
                            handleClick={handleClick}
                            addBookImageGTM={addBookImageGTM}
                            coverImage={coverImage}
                            title={title}
                            imageSrcsetSizes={imageSrcsetSizes}
                            offline={offline}
                        />
                        <div className={`${baseClassName}__meta-wrapper`}>
                            <div className={`${baseClassName}__meta`}>
                                <div className={classNames(titleClasses)}>
                                    <div
                                        className={`${baseClassName}__book-title`}
                                    >
                                        <BookTitle
                                            onClick={onClick}
                                            baseClassName={baseClassName}
                                            bookPath={bookPath}
                                            handleClick={handleClick}
                                            asdBookTitleGTM={asdBookTitleGTM}
                                            titleClasses={titleClasses}
                                            title={title}
                                        />
                                    </div>
                                </div>
                                <BookAuthors
                                    t={t}
                                    baseClassName={baseClassName}
                                    authors={authors}
                                    addBookAuthorGTM={addBookAuthorGTM}
                                />
                                <BookIllustrators
                                    t={t}
                                    baseClassName={baseClassName}
                                    illustrators={illustrators}
                                    addBookIllustratorsGTM={
                                        addBookIllustratorsGTM
                                    }
                                />
                            </div>
                            {isManageReadingList && (
                                <RemoveButton
                                    t={t}
                                    baseClassName={baseClassName}
                                    removeFromBookList={removeFromBookList}
                                    book={book}
                                    manageReadingListId={manageReadingListId}
                                    removeFromBookListGTM={
                                        removeFromBookListGTM
                                    }
                                />
                            )}
                            {isAddBook && (
                                <AddRemoveButton
                                    t={t}
                                    baseClassName={baseClassName}
                                    isShowRemoveButton={isShowRemoveButton}
                                    addToBookList={addToBookList}
                                    book={book}
                                    addToBookListGTM={addToBookListGTM}
                                    removeFromBookList={removeFromBookList}
                                    manageReadingListId={manageReadingListId}
                                    removeFromBookListGTM={
                                        removeFromBookListGTM
                                    }
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <BookPoster
                        t={t}
                        baseClassName={baseClassName}
                        bookPath={bookPath}
                        coverImage={coverImage}
                        title={title}
                        imageSrcsetSizes={imageSrcsetSizes}
                        offline={offline}
                        pageCountImg={pageCountImg}
                        pagesCount={pagesCount}
                    />
                )}

                {isModalVisible.noSpaceForMoreOfflineBooks && (
                    <NoSpaceForMoreOfflineBooksModal
                        viewport={viewport}
                        onClose={() =>
                            onCloseModal('noSpaceForMoreOfflineBooks')
                        }
                        maximum={MAX_OFFLINE_BOOKS_COUNT}
                    />
                )}
                {!bookDetails && (
                    <div
                        style={{ backgroundColor: `${storyLevelColour}` }}
                        className={classNames(levelStyles)}
                    >
                        <span className={`${baseClassName}__level-text`}>
                            {t(`PublicationForm.level${level}`)}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({
    book,
    offlineBooks,
    user: { isLoggedIn, profile },
    viewport,
}) => ({
    offlineBooks,
    isSavingOffline: offlineBooks.isSavingOffline,
    userEmail: profile.email,
    isLoggedIn,
    profile,
    viewport,
    offlineBooksCount: offlineBooks.books.length,
});

const mapDispatchToProps = {
    saveOfflineWorkflow,
    unsaveOffline,
    recordGaEvents,
    addSlimNotification,
    removeBookFromReadingListActionAsync,
    addBookToReadingListActionAsync,
};

ManageBookCard.propTypes = {
    addBtn: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    saveOfflineWorkflow: PropTypes.func.isRequired,
    recordGaEvents: PropTypes.func.isRequired,
    userEmail: PropTypes.string,
    addSlimNotification: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    addBookToReadingListActionAsync: PropTypes.func.isRequired,
    removeBookFromReadingListActionAsync: PropTypes.func.isRequired,
    book: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        level: PropTypes.string,
        slug: PropTypes.string.isRequired,
        coverImage: PropTypes.string,
        authors: PropTypes.arrayOf(PropTypes.string),
        illustrators: PropTypes.arrayOf(PropTypes.string),
        recommended: PropTypes.bool,
        readsCount: PropTypes.number,
        storyLevelColour: PropTypes.string,
    }).isRequired,
    fontSize: PropTypes.string,
    offline: PropTypes.bool,
    viewport: PropTypes.object.isRequired,
    faded: PropTypes.bool,
    hideReadCountStat: PropTypes.bool,
    bookDetails: PropTypes.bool,
    pagesCount: PropTypes.number,
    manageReadingListId: PropTypes.string,
    isManageReadingList: PropTypes.bool,
    isAddBook: PropTypes.bool,
    addBookImageGTM: PropTypes.func,
    asdBookTitleGTM: PropTypes.func,
    addBookAuthorGTM: PropTypes.func,
    addBookIllustratorsGTM: PropTypes.func,
    removeFromBookListGTM: PropTypes.func,
    addToBookListGTM: PropTypes.func,
};

export default withRouter(
    translate()(connect(mapStateToProps, mapDispatchToProps)(ManageBookCard))
);
