import React from 'react';
import PropTypes from 'prop-types';
import BookCard from '../../BookCard';

const Books = ({ books, myLanguage, online }) => {
    return (
        <React.Fragment>
            {books.map((book) => {
                return (
                    <BookCard
                        myLanguage={myLanguage}
                        key={book.id}
                        id={book.id}
                        book={book}
                        shouldDisplayMenu={false}
                        noSrcSet={!online}
                        hideReadCountStat={true}
                        bookLikes={book.likesCount}
                        isFavourite={book.is_favourite}
                        bookImageGTM="books-book-image"
                        bookTitleGTM="books-book-title"
                        bookAuthorGTM="books-book-author"
                        bookIllustratorsGTM="books-book-Illustrators"
                        bookLevelGTM="books-book-Level"
                        padding130={true}
                    />
                );
            })}
        </React.Fragment>
    );
};

Books.propTypes = {
    books: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            likesCount: PropTypes.number,
            is_favourite: PropTypes.bool,
        })
    ).isRequired,
    myLanguage: PropTypes.string.isRequired,
    online: PropTypes.bool.isRequired,
};

export default Books;
