import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Loader';
import SvgIcon from '../../SvgIcon';

const Icon = ({ loading, baseClassName, radioIcon, checked }) => {
    return (
        <React.Fragment>
            {loading ? (
                <Loader parentClassName={`${baseClassName}__loader`} />
            ) : (
                <SvgIcon
                    parentClassName={`${baseClassName}__icon`}
                    name={`${radioIcon ? 'radio' : 'checkbox'}-${
                        checked ? 'selected' : 'unselected'
                    }`}
                />
            )}
        </React.Fragment>
    );
};

Icon.propTypes = {
    loading: PropTypes.bool.isRequired,
    baseClassName: PropTypes.string.isRequired,
    radioIcon: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
};

export default Icon;
