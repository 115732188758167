import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../Img';

const BookPoster = ({
    t,
    baseClassName,
    bookPath,
    coverImage,
    title,
    imageSrcsetSizes,
    offline,
    pageCountImg,
    pagesCount,
}) => {
    return (
        <div>
            <div className={`${baseClassName}__wrapper-details`}>
                <div
                    className={`${baseClassName}__image-wrapper`}
                    href={bookPath}
                >
                    <div className={`${baseClassName}__image`}>
                        <Img
                            bookDetails
                            image={coverImage}
                            alt={title}
                            lazyLoad
                            sizes={imageSrcsetSizes.grid2up6up}
                            offline={offline}
                        />
                    </div>
                </div>
            </div>
            <div className={`${baseClassName}__border-image-wrapper`}>
                <img
                    className={`${baseClassName}__border-image`}
                    src={pageCountImg}
                    alt=""
                />
            </div>
            <div className={`${baseClassName}__page-number`}>
                {pagesCount}
                <p>{t('Book.page')}</p>
            </div>
        </div>
    );
};

BookPoster.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    bookPath: PropTypes.string.isRequired,
    coverImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageSrcsetSizes: PropTypes.shape({
        grid2up6up: PropTypes.string.isRequired,
    }).isRequired,
    offline: PropTypes.bool.isRequired,
    pageCountImg: PropTypes.string.isRequired,
    pagesCount: PropTypes.number.isRequired,
};

export default BookPoster;
