import React from 'react';
import PropTypes from 'prop-types';
import InfoUnit from '../../InfoUnit';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const Youtube = ({ youtubeUrl }) => {
    return (
        <React.Fragment>
            {youtubeUrl !== null && youtubeUrl !== '' && (
                <div className="__profile-youtube">
                    <InfoUnit noWrap key={youtubeUrl}>
                        <Link
                            shouldOpenNewPage={true}
                            href={youtubeUrl}
                            dataGTM="profile-youtube"
                        >
                            <SvgIcon name="facebook" />
                        </Link>
                    </InfoUnit>
                </div>
            )}
        </React.Fragment>
    );
};

Youtube.propTypes = {
    youtubeUrl: PropTypes.string.isRequired,
};

export default Youtube;
