import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Home from '../Home';
import { fetchHomeWorkflow } from '../../redux/homeActions';
import { fetchMeWorkflow } from '../../redux/userActions';
import { videoReadCountWorkflow } from '../../redux/videoActions';
import { HomePage } from '../../api';
import { isRightToLeftDirection } from '../../util/utils';

const HomeContainer = (props) => {
    const [recommendedBooks, setRecommendedBooks] = useState([]);
    const [popularBooks, setPopularBooks] = useState([]);
    const [trendingBooks, setTrendingBooks] = useState([]);
    const [newlyAddedBooks, setNewlyAddedBooks] = useState([]);

    const loginStatus = JSON.parse(localStorage.getItem('status')) || false;

    useEffect(() => {
        props.fetchHomeWorkflow().then(() => {
            localStorage.setItem('language', props.localeLanguage);

            if (props.localeLanguage && loginStatus) {
                HomePage.fetchRecommendedBooks()
                    .then((response) => response.json())
                    .then((data) => setRecommendedBooks(data.data));
            }

            if (props.localeLanguage) {
                HomePage.fetchPopularBooks()
                    .then((response) => response.json())
                    .then((data) => setPopularBooks(data.data));

                HomePage.fetchTrendingBooks()
                    .then((response) => response.json())
                    .then((data) => setTrendingBooks(data.data));

                HomePage.fetchNewArrivals()
                    .then((response) => response.json())
                    .then((data) => setNewlyAddedBooks(data.data));
            }
        });
    }, [props.localeLanguage, loginStatus, props.fetchHomeWorkflow]);

    const {
        newArrivals,
        mostRead,
        viewport,
        profile,
        isLoggedIn,
        currentLangauge,
        videos,
        readingLists,
    } = props;

    return (
        <Home
            newArrivals={newArrivals}
            mostRead={mostRead}
            recommendedBooks={recommendedBooks}
            popularBooks={popularBooks}
            trendingBooks={trendingBooks}
            newlyAddedBooks={newlyAddedBooks}
            viewport={viewport}
            hasUserHistory={profile.hasHistory}
            profile={profile}
            videos={videos}
            isLoggedIn={isLoggedIn}
            currentLangauge={currentLangauge}
            readingLists={readingLists}
            videoReadCountWorkflow={videoReadCountWorkflow}
            isRightToLeftDirection={isRightToLeftDirection}
        />
    );
};

const mapStateToProps = ({ home, viewport, user }) => ({
    newArrivals: home.newArrivals,
    mostRead: home.mostRead,
    viewport,
    profile: user.profile,
    isLoggedIn: user.isLoggedIn,
    currentLangauge: user.currentLangauge,
    videos: home.videos,
    readingLists: home.readingLists,
    localeLanguage: home.localeLanguage,
});

const mapDispatchToProps = {
    fetchHomeWorkflow,
    fetchMeWorkflow,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
