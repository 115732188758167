import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { isRightToLeftDirection } from '../../util/utils';
import './BookPublication.scss';
import { OfflineStory } from '../../api';
import BookUploadHeader from '../BookUploadHeader';
import BookUploadFooter from '../BookUploadFooter';
import DataLoader from '../DataLoader';
import withRouter from '../../util/withRouter';
import Contributors from './components/Contributors';

const BookPublication = ({ t, location, navigate }) => {
    const baseClassName = 'book-publication';

    const [data, setData] = useState(null);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        OfflineStory.fetchOfflineStoryDetail(location.pathname.split('/')[2])
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setData(response.data);
                setLoader(false);
            });
    }, []);

    const backPage = () => {
        navigate(`/preview/${location.pathname.split('/')[2]}`);
    };

    return (
        <div>
            {loader ? (
                <DataLoader />
            ) : (
                <div className={`${baseClassName}__page_view_container`}>
                    <div>
                        <BookUploadHeader
                            bookId={location.pathname.split('/')[2]}
                            currentPage={4}
                        />
                        <div
                            className={`${baseClassName}__book_view_container`}
                        >
                            <div className={`${baseClassName}__thumbnail_view`}>
                                <img
                                    src={
                                        data &&
                                        'data:image/png;base64,'.concat(
                                            data.cover_thumbnail_image
                                        )
                                    }
                                    alt="thumbnail_img"
                                    className={`${baseClassName}__center_image`}
                                />
                            </div>
                            <div
                                className={
                                    `${baseClassName}__detail_container ` +
                                    (isRightToLeftDirection
                                        ? `${baseClassName}__mirror`
                                        : '')
                                }
                            >
                                <div className={`${baseClassName}__title`}>
                                    {data.title}
                                </div>
                                <div
                                    className={`${baseClassName}__level_box`}
                                    style={{
                                        background: data.story_level_colour,
                                    }}
                                >
                                    <span> {data.reading_level}</span>
                                </div>
                                <div className={`${baseClassName}__summary`}>
                                    {data.synopsis}
                                </div>
                                <div>
                                    {data && data.authors.length > 0 ? (
                                        <div>
                                            <span
                                                className={`${baseClassName}__label`}
                                            >
                                                {t('global.author')}
                                            </span>
                                            <Contributors data={data.authors} />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {data && data.illustrators.length > 0 ? (
                                        <div>
                                            <span
                                                className={`${baseClassName}__label`}
                                            >
                                                {
                                                    t(
                                                        'global.illustrator'
                                                    ).split('|')[0]
                                                }
                                            </span>

                                            <Contributors
                                                data={data.illustrators}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {data && data.translators.length > 0 ? (
                                        <div>
                                            <span
                                                className={`${baseClassName}__label`}
                                            >
                                                {t('global.translator')}
                                            </span>

                                            <Contributors
                                                data={data.translators}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {data && data.publishers.id ? (
                                        <div>
                                            <span
                                                className={`${baseClassName}__label`}
                                            >
                                                {
                                                    t('global.publisher').split(
                                                        '|'
                                                    )[0]
                                                }
                                            </span>
                                            <span
                                                className={`${baseClassName}__author-name`}
                                            >
                                                {data.publishers.name}
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <BookUploadFooter backPage={backPage} />
                    </div>
                </div>
            )}{' '}
        </div>
    );
};

BookPublication.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withRouter(translate()(BookPublication));
