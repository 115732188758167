import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Link from '../Link';
import './Logo.scss';
import logo from './assets/RTR.svg';
import logoCentre from './assets/RtR_New_Logo.svg';
import vietnamFooterLogo from './assets/vietnamMoetLogo.svg';

const Logo = ({ t, isVietnamese, variant = 'logo', parentClassName, ...props }) => {
    const image = { logo, logoCentre, vietnamFooterLogo }[variant];

    const baseClassName = 'pb-logo';
    const classNames = [baseClassName];

    const classNamesDict = {
        logoCentre: 'pb-logo__img--centre-aligned',
        logo: 'pb-logo__img',
        vietnamFooterLogo: 'pb-logo__vietnam-footer-image',
    };

    const imageClassName = classNamesDict[variant];

    if (parentClassName) {
        classNames.push(parentClassName);
    }

    return (
        <Link
            parentClassName={classNames.join(' ')}
            {...props}
            dataGTM="nav-logo"
        >
            <img
                className={isVietnamese ? 'vietnamLogo' : imageClassName}
                src={image}
                alt={`${t('global.logo-of')} ${t('global.site-title')}`}
                data-gtm="nav-logo"
            />
        </Link>
    );
};

Logo.propTypes = {
    parentClassName: PropTypes.string,
    t: PropTypes.func,
    isVietnamese: PropTypes.bool,
    variant: PropTypes.oneOf(['swLogo', 'logoCentre', 'logo']),
};

export default translate()(Logo);
