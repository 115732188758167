import React from 'react';
import PropTypes from 'prop-types';
import InfoUnit from '../../InfoUnit';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const Email = ({ email }) => {
    return (
        <React.Fragment>
            {email !== null && email !== '' && (
                <div className="__profile-email">
                    <InfoUnit noWrap key={email}>
                        <Link href={`mailto:${email}`} dataGTM="profile-email">
                            <SvgIcon name="mail" />
                        </Link>
                    </InfoUnit>
                </div>
            )}
        </React.Fragment>
    );
};

Email.propTypes = {
    email: PropTypes.string,
};

export default Email;
