import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './CollapsibleSection.scss';
import Title from './components/Title';

const CollapsibleSection = ({
    disableCollapse,
    children,
    expandOnMediumViewport,
    expandOnLargeViewport,
    theme,
    title,
    upperCaseTitle,
    parentClassName,
    relatedBookList,
}) => {
    const [open, setOpen] = useState(false);

    const baseClassName = 'pb-collapsible-section';
    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
        [`${baseClassName}--open`]: open,
        [`${baseClassName}--${theme}`]: theme,
        [`${baseClassName}--upper-case-title`]: upperCaseTitle,
        [`${baseClassName}--disable-collapse`]: disableCollapse,
        [`${baseClassName}--expand-on-medium-viewport`]: expandOnMediumViewport,
        [`${baseClassName}--expand-on-large-viewport`]: expandOnLargeViewport,
    };

    const toggleOpen = () => {
        if (!disableCollapse) {
            setOpen((prev) => !prev);
        }
    };

    return (
        <div className={classNames(classes)}>
            {title && (
                <Title
                    toggleOpen={toggleOpen}
                    baseClassName={baseClassName}
                    relatedBookList={relatedBookList}
                    title={title}
                    open={open}
                />
            )}
            <div className={`${baseClassName}__content`}>{children}</div>
        </div>
    );
};

CollapsibleSection.propTypes = {
    disableCollapse: PropTypes.bool,
    children: PropTypes.node.isRequired,
    expandOnMediumViewport: PropTypes.bool,
    expandOnLargeViewport: PropTypes.bool,
    theme: PropTypes.string,
    title: PropTypes.string,
    upperCaseTitle: PropTypes.bool,
    parentClassName: PropTypes.string,
    relatedBookList: PropTypes.arrayOf(PropTypes.object),
};

export default CollapsibleSection;
