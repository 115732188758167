import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import queryString from 'query-string';
import Joyride from 'react-joyride';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { cloneDeep } from 'lodash';
import {
    fetchReadAloudFiltersWorkflow,
    fetchReadAloudsWorkflow,
} from '../../redux/readAloudsActions';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import LoaderBlock from '../LoaderBlock';
import FiltersBar from '../FiltersBar';
import Block from '../Block';
import TitleImage from '../TitleImage';
import Pagination from '../Pagination';
import SearchModal from '../SearchModal';
import ReadAloudCardContainer from '../ReadAloudCardContainer';
import withRouter from '../../util/withRouter';
import useDeviceType from '../../hooks/useDeviceType';
import {
    defaultFilters,
    filtersBarSortOptions,
    helpGuideSteps,
} from './constants/constants';
import { isRightToLeftDirection } from '../../util/utils';

const ReadAloudsContainer = ({
    t,
    fetchReadAloudFiltersWorkflow,
    fetchReadAloudsWorkflow,
    navigate,
    location,
    loadedPages,
    readAlouds,
    viewport,
    videoHits,
    filters,
    totalVideosCount,
    isFetchingMoreVideos,
    helpStatus,
}) => {
    const [filtersSearchText, setFiltersSearchText] = useState({
        category: '',
        role: '',
    });

    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    useEffect(() => {
        fetchReadAloudFiltersWorkflow().then((response) => {
            let filters = getAppliedFilters();
            filters.role = [];
            filters.role = filters.role.concat(response.data.current_role);
            filters.language = response.data.current_language;

            const searchParams = queryString.stringify(filters);
            navigate({ search: `?${searchParams}` }, { replace: true });
            fetchReadAloudsWorkflow(filters);
        });
    }, []);

    useEffect(() => {
        if (location.search === '?sort=Relevance') {
            fetchReadAloudFiltersWorkflow().then((response) => {
                let filters = getAppliedFilters();
                filters.role = [];
                filters.role = filters.role.concat(response.data.current_role);
                filters.language = response.data.current_language;

                const searchParams = queryString.stringify(filters);
                navigate({ search: `?${searchParams}` }, { replace: true });

                fetchReadAloudsWorkflow(filters);
            });
        }
    }, [location.search]);

    const { isTabletDevice: isVideoMobile } = useDeviceType();

    const getAppliedFilters = () => {
        const parsedFilters = queryString.parse(location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        defaultFilters.role = [];
        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    const myLanguage = localStorage.getItem('locale');
    const shouldShowLoadMore = loadedPages && loadedPages < totalVideosCount;

    const appliedFilters = getAppliedFilters();

    if (!filters) {
        return <LoaderBlock />;
    }

    const onOpenSearchModal = () => setIsSearchModalOpen(true);
    const onCloseSearchModal = () => setIsSearchModalOpen(false);

    const applyFilterFunc = (type, value) => {
        const appliedFilters = cloneDeep(getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });

        fetchReadAloudsWorkflow(appliedFilters);
    };

    const removeFilterFunc = (type, value) => {
        const appliedFilters = cloneDeep(getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });

        fetchReadAloudsWorkflow(appliedFilters);
    };

    const updateFiltersSearchText = (key, text) => {
        setFiltersSearchText((prev) => ({
            ...prev,
            [key]: text,
        }));
    };

    const onSortOptionChanged = (option) => applyFilterFunc('sort', option);

    const onLoadMore = () =>
        fetchReadAloudsWorkflow(getAppliedFilters(), loadedPages + 1);

    const onVideoSearch = (event) => {
        let appliedFilters = getAppliedFilters();
        setIsSearchModalOpen(false);
        appliedFilters.query = this.state.queryValue;
        appliedFilters.sort = 'Relevance';

        const searchParams = queryString.stringify(appliedFilters);

        navigate({ search: `?${searchParams}` }, { replace: true });
        fetchReadAloudsWorkflow(appliedFilters);
    };

    return (
        <div className="__video-container">
            <Joyride
                enabled={true}
                steps={
                    isVideoMobile
                        ? helpGuideSteps(t).mobileSteps
                        : helpGuideSteps(t).steps
                }
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={250}
                disableScrolling={true}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <DocumentTitle
                title={`${t('global.readAlouds')} - ${t('global.site-title')}`}
            />
            <Block background="transparent">
                <div className="video-resource-header">
                    <div className="__video-header">
                        <h3>{t('global.readAlouds')}</h3>
                        <h3 className="__video-count">({videoHits})</h3>
                    </div>
                    <div
                        className={'__videos-search'}
                        onClick={onOpenSearchModal}
                    >
                        <TitleImage
                            className={
                                isRightToLeftDirection
                                    ? '__profile-images-arabic'
                                    : '__profile-images'
                            }
                            svgName={require('../../assets/search_tl.svg')}
                            title={t('videos.search')}
                            contentSpecificSearch
                        />
                    </div>
                </div>
            </Block>
            <div className="__video-filter-component">
                <FiltersBar
                    readAloud
                    noTopBorder
                    pullUp
                    filters={filters}
                    applyFilter={applyFilterFunc}
                    removeFilter={removeFilterFunc}
                    appliedFilters={appliedFilters}
                    styleSearchValue={filtersSearchText.style}
                    updateStyleSearchValue={(e) =>
                        updateFiltersSearchText('style', e.target.value)
                    }
                    resultsCount={totalVideosCount}
                    sortOptions={filtersBarSortOptions(t)}
                    applySortOption={onSortOptionChanged}
                    appliedSortOption={appliedFilters.sort}
                    isVideos
                    isVideoMobile={isVideoMobile}
                    viewSortGTM="video-view-sort"
                    alphabetSortGTM="video-alphabetic-sort"
                    hideLevelSelector
                    hideLanguageSelector
                />
            </div>
            <div className="__video-card-section">
                <ReadAloudCardContainer
                    myLanguage={myLanguage}
                    Videos={readAlouds}
                    applyFilter={applyFilterFunc}
                    removeFilter={removeFilterFunc}
                    onSortOptionChanged={onSortOptionChanged}
                    appliedFilters={{ ...appliedFilters }}
                />
                {shouldShowLoadMore && (
                    <Pagination
                        onClick={onLoadMore}
                        loading={isFetchingMoreVideos}
                        dataGTM="videos-view-more"
                    />
                )}
            </div>
            {isSearchModalOpen && (
                <SearchModal
                    viewport={viewport}
                    onClose={onCloseSearchModal}
                    onSubmit={onVideoSearch}
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ readAlouds, viewport, help }) => ({
    ...readAlouds,
    isFetchingReadAlouds: readAlouds.isFetchingReadAlouds,
    isFetchingMoreReadAlouds: readAlouds.isFetchingMoreReadAlouds,
    loadedPages: readAlouds.loadedPages,
    totalVideosCount: readAlouds.totalVideosCount,
    filters: readAlouds.filters,
    videoHits: readAlouds.hits,
    viewport,
    helpStatus: help.helpStatus,
});

const mapDispatchToProps = {
    fetchReadAloudFiltersWorkflow,
    fetchReadAloudsWorkflow,
    applyFilter,
    resetFilters,
};

ReadAloudsContainer.propTypes = {
    t: PropTypes.func.isRequired,
    fetchReadAloudFiltersWorkflow: PropTypes.func.isRequired,
    fetchReadAloudsWorkflow: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
    loadedPages: PropTypes.number.isRequired,
    readAlouds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
        })
    ).isRequired,
    viewport: PropTypes.object.isRequired,
    videoHits: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    totalVideosCount: PropTypes.number.isRequired,
    isFetchingMoreVideos: PropTypes.bool.isRequired,
    helpStatus: PropTypes.bool.isRequired,
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(ReadAloudsContainer)
    )
);
