import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import './RTRBookReaderPageFooter.scss';
import SvgIcon from '../../../SvgIcon';
import Button from '../../../Button';
import { isRightToLeftDirection } from '../../../../util/utils';
import ReadToMeModal from '../ReadToMeModal/ReadToMeModal';

const RTRBookReaderPageFooter = ({
    t,
    pageNo,
    totalPageCount,
    book,
    readToMeAction,
    fullScreen: isFullScreen,
    exitStoryReader,
    enterStoryReader,
}) => {
    const [isReadToMeModalVisible, setIsReadToMeModalVisible] = useState(false);
    const [isReadToMeVideoPlay, setIsReadToMeVideoPlay] = useState(false);
    const [zoom, setZoom] = useState(1);

    const readToMeModalOpen = () => {
        if (book.readAlouds[0]) {
            setIsReadToMeModalVisible(true);
            setIsReadToMeVideoPlay(true);

            const storyVideoId = book.readAlouds[0].coachingMaterialId;
            readToMeAction(storyVideoId);
        }
    };

    const readToMeModalClose = () => {
        setIsReadToMeModalVisible(false);
        setIsReadToMeVideoPlay(false);
    };

    const zoomIn = () => {
        if (zoom < 1.3) {
            const myImg = isFullScreen
                ? document.getElementById('bookReaderWrapperFS')
                : document.getElementById('bookReaderWrapper');

            setZoom((prev) => prev + 0.05);
            myImg.style.transform = 'scale(' + (zoom + 0.05) + ')';
        }
    };

    const zoomOut = () => {
        if (zoom > 0.7) {
            const myImg = isFullScreen
                ? document.getElementById('bookReaderWrapperFS')
                : document.getElementById('bookReaderWrapper');

            setZoom((prev) => prev - 0.05);
            myImg.style.transform = 'scale(' + (zoom - 0.05) + ')';
        }
    };

    const mobilePageStatus = pageNo + 1;
    const progressInProccessMobile = mobilePageStatus / (totalPageCount + 3);

    var totalNoOfPagesDesktop =
        (totalPageCount + 4) % 2 === 0
            ? (totalPageCount + 4) / 2
            : (totalPageCount + 5) / 2 - 1;
    var progressInProccessDesktop = pageNo / totalNoOfPagesDesktop;

    return (
        <div
            className={
                isFullScreen
                    ? 'RTRBookReaderPageFooterFullScreen'
                    : 'RTRBookReaderPageFooter row'
            }
        >
            <div
                className="progress progress-Mobile col-12"
                style={{ height: '10px' }}
            >
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progressInProccessMobile * 100}%` }}
                    aria-valuenow={progressInProccessMobile * 100}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <div className={`col-md-2 readToMeDesktop`}>
                {book.readAlouds.length > 0 && (
                    <Button
                        iconLeft={
                            isFullScreen
                                ? 'reader-view-read2me-fs'
                                : 'reader-view-read-to-me'
                        }
                        storyReader
                        fullWidth
                        label={t('Book.readToMe')}
                        variant="primary"
                        onClick={() => readToMeModalOpen()}
                        isRightToLeftDirection={isRightToLeftDirection}
                        fullScreen={isFullScreen}
                    />
                )}
                {isReadToMeModalVisible && (
                    <ReadToMeModal
                        readToMeModalClose={readToMeModalClose}
                        book={book}
                        isReadToMeVideoPlay={isReadToMeVideoPlay}
                    />
                )}
            </div>
            <div
                className={`col-4 ${
                    !pageNo ? 'col-md-8' : 'col-lg-8'
                } pageNavigatorBar ${
                    isRightToLeftDirection
                        ? 'pageNavigatorBar-rtl'
                        : 'pageNavigatorBar'
                } `}
            >
                <span
                    className={`pageNoIndicator col-3 displayDesktop ${
                        isRightToLeftDirection
                            ? 'pageNoIndicator-rtl'
                            : 'pageNoIndicator'
                    } `}
                >
                    {`${pageNo} ${t(
                        'Book.footerPageTile2'
                    )} ${totalNoOfPagesDesktop}`}
                </span>
                <span className="pageNoIndicator col-3 displayMobile">
                    {`${mobilePageStatus} ${t('Book.footerPageTile2')} ${
                        totalPageCount + 3
                    }`}
                </span>
                <div
                    className="progress progress-Desktop col-9"
                    style={{ height: '10px' }}
                >
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                            width: `${progressInProccessDesktop * 100}%`,
                        }}
                        aria-valuenow={progressInProccessDesktop * 100}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>
            <div
                className={`BookMaginifyingControls ${
                    isRightToLeftDirection
                        ? 'BookMaginifyingControls-rtl'
                        : 'BookMaginifyingControls'
                }  col-8 col-md-4 col-lg-2`}
            >
                <span
                    className={`RTRReadToMeButton-Mobile ${
                        !pageNo &&
                        book &&
                        book.readAlouds &&
                        book.readAlouds.length > 0
                            ? ''
                            : 'invisible'
                    }`}
                    onClick={() => {
                        readToMeModalOpen();
                    }}
                >
                    <SvgIcon
                        name={
                            isFullScreen
                                ? 'reader-view-read2me-fs'
                                : 'reader-view-read-to-me'
                        }
                        size="reader-view-controls"
                    />
                </span>
                <span
                    className="RTRBookZoomInButton"
                    onClick={() => {
                        zoomIn();
                    }}
                >
                    <SvgIcon
                        name={
                            isFullScreen
                                ? 'reader-view-zoom-in-fs'
                                : 'reader-view-zoom-in'
                        }
                        size="reader-view-controls"
                    />
                </span>
                <span
                    className="RTRBookZoomOutButton"
                    onClick={() => {
                        zoomOut();
                    }}
                >
                    <SvgIcon
                        name={
                            isFullScreen
                                ? 'reader-view-zoom-out-fs'
                                : 'reader-view-zoom-out'
                        }
                        size="reader-view-controls"
                    />
                </span>

                {window.screen.availWidth > 600 && (
                    <span
                        className={
                            isRightToLeftDirection
                                ? 'RTRBookFullScreenViewButton RTRBookFullScreenViewButton-rtl'
                                : 'RTRBookFullScreenViewButton'
                        }
                        onClick={() => {
                            isFullScreen
                                ? exitStoryReader()
                                : enterStoryReader();
                        }}
                    >
                        <SvgIcon
                            name={
                                isFullScreen
                                    ? 'reader-view-fullscreen-exit'
                                    : 'reader-view-fullscreen'
                            }
                            size="reader-view-controls"
                        />
                    </span>
                )}
            </div>
        </div>
    );
};

RTRBookReaderPageFooter.propTypes = {
    t: PropTypes.func.isRequired,
    pageNo: PropTypes.number.isRequired,
    totalPageCount: PropTypes.number.isRequired,
    book: PropTypes.shape({
        readAlouds: PropTypes.array.isRequired,
        coachingMaterialId: PropTypes.string,
    }).isRequired,
    readToMeAction: PropTypes.func.isRequired,
};

export default translate()(RTRBookReaderPageFooter);
