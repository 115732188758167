import React from 'react';
import PropTypes from 'prop-types';
import heartFilled from '../../../assets/heart-filled.svg';
import heartUnFilled from '../../../assets/heart-unfilled.svg';

const HeartIcon = ({ isFavourite }) => {
    return (
        <React.Fragment>
            {isFavourite ? (
                <img
                    src={heartFilled}
                    alt="heart-filled"
                    className="__heart-unfilled"
                />
            ) : (
                <img
                    src={heartUnFilled}
                    alt="heart-unfilled"
                    className="__heart-unfilled"
                />
            )}
        </React.Fragment>
    );
};

HeartIcon.propTypes = {
    isFavourite: PropTypes.bool.isRequired,
};

export default HeartIcon;
