import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';
import { sortOptions } from '../../../lib/constants';
import { isRightToLeftDirection } from '../../../util/utils';

const SortResourceMobile = ({
    t,
    isAscend,
    ascendingSortChange,
    descendingSortChange,
    alphabetSortGTM,
}) => {
    return [
        <span>
            <Link
                onClick={
                    isAscend
                        ? () => ascendingSortChange(sortOptions.descending)
                        : () => descendingSortChange(sortOptions.ascending)
                }
                lightBlack
                dataGTM={alphabetSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={alphabetSortGTM}
                >
                    {!isAscend
                        ? t('global.alphabet-reverse')
                        : t('global.alphabet')}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={alphabetSortGTM}
                    />
                </div>
            </Link>
        </span>,
    ];
};

SortResourceMobile.propTypes = {
    t: PropTypes.func.isRequired,
    isAscend: PropTypes.bool.isRequired,
    ascendingSortChange: PropTypes.func.isRequired,
    descendingSortChange: PropTypes.func.isRequired,
    alphabetSortGTM: PropTypes.string.isRequired,
};

export default SortResourceMobile;
