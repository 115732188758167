import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import TitleImage from '../../TitleImage';
import { links } from '../../../lib/constants';
import gearIcon from '../../../assets/Dashboards.svg';

const SubAdminEls = ({ roles, userEmail, baseClassName, t, mobileMenu }) => {
    let subAdminEls = [];
    if (roles.includes('content_manager') && roles.includes('sub_admin')) {
        subAdminEls = [
            <Link
                key="dasboard"
                fullWidth
                href={links.dashboard()}
                noPadding
                dataGTM={userEmail + '-content-manager-dashboard'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={gearIcon}
                    title={t('global.content-manager-dashboard', 1)}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-content-manager-dashboard'}
                />
                {mobileMenu ? null : (
                    <div
                        className={`${baseClassName}--separator`}
                        data-gtm={userEmail + '-content-manager-dashboard'}
                    />
                )}
            </Link>,
        ];
    } else if (roles.includes('sub_admin')) {
        subAdminEls = [
            <Link
                key="dasboard"
                fullWidth
                href={links.dashboard()}
                noPadding
                dataGTM={userEmail + '-content-manager-dashboard'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={gearIcon}
                    title={t('global.content-manager-dashboard', 1)}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-content-manager-dashboard'}
                />
                {mobileMenu ? null : (
                    <div
                        className={`${baseClassName}--separator`}
                        data-gtm={userEmail + '-content-manager-dashboard'}
                    />
                )}
            </Link>,
        ];
    }

    return <div>{subAdminEls}</div>;
};

SubAdminEls.propTypes = {
    roles: PropTypes.array.isRequired,
    userEmail: PropTypes.string.isRequired,
    baseClassName: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    mobileMenu: PropTypes.bool.isRequired,
};

export default SubAdminEls;
