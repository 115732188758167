import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import DocumentTitle from 'react-document-title';
import queryString from 'query-string';
import Block from '../Block';
import TitleImage from '../TitleImage';
import BannerComponent from '../../helpers/BannerComponentHook';
import SearchModal from '../SearchModal';
import ReadingListInnerMenuTabs from './components/ReadingListInnerMenuTabs';
import { isRightToLeftDirection } from '../../util/utils';
import {
    fetchReadingListDetailsWorkflow,
    fetchMyReadingListWorkflow,
    createNewReadingListActionAsync,
} from '../../redux/readingListsActions';
import { helpGuideSteps } from './constants/helpGuideSteps';
import { sortOptions } from '../../lib/constants';
import withRouter from '../../util/withRouter';
import './ReadingListContainer.scss';
import useDeviceType from '../../hooks/useDeviceType';
import CreateReadingListButton from './components/CreateReadingListButton';
import ReadingListContents from './components/ReadingListContents';

const defaultFilters = {
    category: [],
    publisher: [],
    role: [],
    style: [],
    language: [],
    sort: sortOptions.position,
    tags: [],
    query: '',
};

const ReadingListContainer = ({
    t,
    fetchReadingListDetailsWorkflow,
    fetchMyReadingListWorkflow,
    createNewReadingListActionAsync,
    myReadingListsLoadedPages,
    appliedFilters,
    slug,
    isLoggedIn,
    helpStatus,
    viewport,
    location,
    navigate,
    themedReadingLists,
    loadedPages,
    totalListsCount,
    isFetchingMoreReadingList,
    isFetchingReadingList,
    filters,
    sortOptions,
    myReadingLists,
    totalMyReadingListsCount,
    isFetchingMoreMyReadingList,
}) => {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const { isMobile } = useDeviceType();

    useEffect(() => {
        const myReadingListsLoadedPagesCount = myReadingListsLoadedPages || 0;

        const storedStatus = localStorage.getItem('status');

        const loginStatus =
            storedStatus !== 'undefined' ? JSON.parse(storedStatus) : false;

        loginStatus &&
            fetchMyReadingListWorkflow(
                appliedFilters,
                slug,
                myReadingListsLoadedPagesCount + 1
            );
    }, []);

    const themedReadingList = window.location.pathname === '/readinglists';

    const onOpenSearchModal = () => setIsSearchModalOpen(true);

    const onCloseSearchModal = () => setIsSearchModalOpen(false);

    const getAppliedFilters = () => {
        const parsedFilters = queryString.parse(location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    const onReadingListSearch = (event) => {
        let appliedFilters = getAppliedFilters();
        setIsSearchModalOpen(false);

        appliedFilters.query = event;
        const searchParams = queryString.stringify(appliedFilters);

        navigate({ search: `?${searchParams}` }, { replace: true });
        fetchReadingListDetailsWorkflow(appliedFilters);
    };

    return (
        <div className="__reading-list-container">
            <Joyride
                enabled={true}
                steps={
                    isMobile
                        ? helpGuideSteps(t, isLoggedIn).mobileSteps
                        : helpGuideSteps(t, isLoggedIn).steps
                }
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={250}
                disableScrolling={false}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <DocumentTitle
                title={`${t('global.reading-list', 2)} - ${t(
                    'global.site-title'
                )}`}
            />
            <Block offlineSection>
                <BannerComponent bannerType={"reading_lists"}/>
                <div className="reading-list-container">
                    <div className="__reading-list-header">
                        <h3>{t('global.reading-list', 2)}</h3>
                    </div>
                    <div
                        className={'__reading-list-search'}
                        onClick={onOpenSearchModal}
                    >
                        <TitleImage
                            className={
                                isRightToLeftDirection
                                    ? '__profile-images-arabic'
                                    : '__profile-images'
                            }
                            svgName={require('../../assets/search_tl.svg')}
                            title={t('lists.search')}
                            contentSpecificSearch
                        />
                    </div>
                </div>
            </Block>
            <div className="__readinglist-navbar-section">
                <div className="__readinglist-navbar">
                    <ReadingListInnerMenuTabs isLoggedIn={isLoggedIn} />
                </div>
                {!themedReadingList && (
                    <CreateReadingListButton t={t} navigate={navigate} />
                )}
            </div>
            <ReadingListContents
                t={t}
                themedReadingList={themedReadingList}
                themedReadingLists={themedReadingLists}
                loadedPages={loadedPages}
                totalListsCount={totalListsCount}
                isFetchingMoreReadingList={isFetchingMoreReadingList}
                isFetchingReadingList={isFetchingReadingList}
                viewport={viewport}
                location={location}
                filters={filters}
                sortOptions={sortOptions}
                myReadingLists={myReadingLists}
                myReadingListsLoadedPages={myReadingListsLoadedPages}
                totalMyReadingListsCount={totalMyReadingListsCount}
                isFetchingMoreMyReadingList={isFetchingMoreMyReadingList}
            />
            {isSearchModalOpen && (
                <SearchModal
                    viewport={viewport}
                    onClose={onCloseSearchModal}
                    onSubmit={onReadingListSearch}
                />
            )}
        </div>
    );
};

ReadingListContainer.propTypes = {
    t: PropTypes.func.isRequired,
    fetchReadingListDetailsWorkflow: PropTypes.func.isRequired,
    fetchMyReadingListWorkflow: PropTypes.func.isRequired,
    createNewReadingListActionAsync: PropTypes.func.isRequired,
    myReadingListsLoadedPages: PropTypes.number.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    helpStatus: PropTypes.bool.isRequired,
    viewport: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    themedReadingLists: PropTypes.array.isRequired,
    loadedPages: PropTypes.number.isRequired,
    totalListsCount: PropTypes.number.isRequired,
    isFetchingMoreReadingList: PropTypes.bool.isRequired,
    isFetchingReadingList: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    sortOptions: PropTypes.object.isRequired,
    myReadingLists: PropTypes.array.isRequired,
    totalMyReadingListsCount: PropTypes.number.isRequired,
    isFetchingMoreMyReadingList: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
    readingLists,
    viewport,
    myReadingLists,
    user,
    help,
}) => {
    return {
        themedReadingLists: readingLists.readingLists,
        myReadingLists: readingLists.myReadingLists.allReadingLists,
        isFetchingReadingList: readingLists.isFetchingReadingList,
        isFetchingMoreReadingList: readingLists.isFetchingMoreReadingList,
        isFetchingMoreMyReadingList: readingLists.isFetchingMoreMyReadingList,
        loadedPages: readingLists.loadedPages,
        myReadingListsLoadedPages: readingLists.myReadingListsLoadedPages,
        totalListsCount: readingLists.totalListsCount,
        totalMyReadingListsCount: readingLists.totalMyReadingListsCount,
        filters: readingLists.filters,
        sortOptions: readingLists.sortOptions,
        helpStatus: help.helpStatus,
        isLoggedIn: user.isLoggedIn,
        viewport,
    };
};

const mapDispatchToProps = {
    fetchReadingListDetailsWorkflow,
    fetchMyReadingListWorkflow,
    createNewReadingListActionAsync,
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(ReadingListContainer)
    )
);
