import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';
import BookCard from '../BookCard';

const Books = ({
    type,
    books = [],
    bookImageGTM,
    bookTitleGTM,
    bookAuthorGTM,
    bookIllustratorsGTM,
    bookLevelGTM,
    myLanguage,
    isProfilePage,
}) => {
    const isProfilePageCheckHandler = () => {
        if (isProfilePage) {
            return true;
        }
        return false;
    };
    return (
        <Grid variant="4up">
            {books.map((book) => {
                return (
                    <BookCard
                        myLanguage={myLanguage}
                        type={type}
                        key={book.slug}
                        book={book}
                        id={book.id}
                        isFavourite={book.is_favourite}
                        isFavouriting={book.isFavouriting}
                        bookLikes={book.likesCount}
                        bookImageGTM={bookImageGTM}
                        bookTitleGTM={bookTitleGTM}
                        bookAuthorGTM={bookAuthorGTM}
                        bookIllustratorsGTM={bookIllustratorsGTM}
                        bookLevelGTM={bookLevelGTM}
                        isProfilePage={isProfilePageCheckHandler()}
                    />
                );
            })}
        </Grid>
    );
};

Books.propTypes = {
    type: PropTypes.string.isRequired,
    books: PropTypes.arrayOf(PropTypes.object),
    bookImageGTM: PropTypes.string,
    bookTitleGTM: PropTypes.string,
    bookAuthorGTM: PropTypes.string,
    bookIllustratorsGTM: PropTypes.string,
    bookLevelGTM: PropTypes.string,
    myLanguage: PropTypes.string,
    isProfilePage: PropTypes.bool,
};

export default Books;
