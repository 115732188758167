import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';
import { isRightToLeftDirection } from '../../../util/utils';

const UploadThumbnail = ({
    t,
    baseClassName,
    uploadImage,
    setModalIsOpen,
    crop,
    uploadImg,
    selectImgFile,
    resetImage,
    reset,
}) => {
    return (
        <div>
            <span
                className={
                    `${baseClassName}__title ` +
                    (isRightToLeftDirection ? `${baseClassName}__mirror` : '')
                }
            >
                {' '}
                {t('Book.thumbnail')}
            </span>
            <br />
            <div className={`${baseClassName}__thumbnail_container`}>
                <div id="thumbnail_view">
                    <SvgIcon
                        name="placeholder"
                        size={'thumbnail-placeholder'}
                    />
                </div>
                <div>
                    <div
                        className={[
                            `${baseClassName}__thumbnail_button`,
                            `${baseClassName}__crop`,
                            uploadImage === ''
                                ? `${baseClassName}__disable`
                                : '',
                        ].join(' ')}
                        onClick={() => setModalIsOpen(true)}
                    >
                        <img src={crop} alt="alt" />
                    </div>
                    <div>
                        <label htmlFor="imgUpload">
                            <div
                                className={`${baseClassName}__thumbnail_button ${baseClassName}__upload`}
                            >
                                <img src={uploadImg} alt="alt" />
                            </div>
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            onClick={(e) => (e.target.value = '')}
                            onChange={(e) => selectImgFile(e)}
                            id="imgUpload"
                        />
                    </div>
                    <div
                        className={[
                            `${baseClassName}__thumbnail_button`,
                            `${baseClassName}__upload`,
                            uploadImage === ''
                                ? `${baseClassName}__disable`
                                : '',
                        ].join(' ')}
                        onClick={() => resetImage()}
                    >
                        <img src={reset} alt="alt" />
                    </div>
                </div>
            </div>
        </div>
    );
};

UploadThumbnail.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    uploadImage: PropTypes.string.isRequired,
    setModalIsOpen: PropTypes.func.isRequired,
    crop: PropTypes.string.isRequired,
    uploadImg: PropTypes.string.isRequired,
    selectImgFile: PropTypes.func.isRequired,
    resetImage: PropTypes.func.isRequired,
    reset: PropTypes.string.isRequired,
};

export default UploadThumbnail;
