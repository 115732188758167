import React from 'react';
import InputFieldTitle from '../../InputFieldTitle';
import TextField from '../../TextField';
import Sizer from '../../Sizer';
import SelectField from '../../SelectField';
import { isRightToLeftDirection } from '../../../util/utils';

const BookData = ({
    t,
    baseClassName,
    existingTitle,
    projectId,
    onSearchTitle,
    setEnglishTitle,
    englishTitle,
    closeDropdown,
    stories,
    onDescriptionChange,
    editions,
    handleSelect,
    setEditionType,
    editionType,
    bookId,
    isSIDPresent,
    onSearchProjectId,
    projectIdDropDown,
    filteredProjectIds,
    onSelectProjectId,
    onTitleChange,
    setTitle,
    title,
    languages,
    setLanguageId,
    languageId,
    orientations,
    setOrientation,
    orientation,
}) => {
    return (
        <React.Fragment>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_row-wrapper-rtl`
                        : `${baseClassName}_row-wrapper`
                }
            >
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.storyTitle')}
                        isRightToLeftDirection={isRightToLeftDirection}
                        isSubHeading={t('PublicationForm.storySubHeading')}
                    />
                    <div
                        className={
                            existingTitle || projectId
                                ? `${baseClassName}_disable-title `
                                : ''
                        }
                        style={{ position: 'relative' }}
                    >
                        <TextField
                            id="story-title"
                            type="text"
                            placeholder={t('PublicationForm.typeHere')}
                            autoFocus={false}
                            custom={true}
                            bordered={false}
                            isPublication
                            onChange={(e) =>
                                onSearchTitle(
                                    'englishTitle',
                                    e,
                                    setEnglishTitle
                                )
                            }
                            value={existingTitle ? existingTitle : englishTitle}
                            isEnglishOnly
                        />
                        {closeDropdown && (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}_title-dropdown-rtl`
                                        : `${baseClassName}_title-dropdown`
                                }
                            >
                                <Sizer maxHeight="l" scrollY>
                                    {stories.map((story, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    onDescriptionChange(
                                                        'englishTitle',
                                                        story,
                                                        setEnglishTitle
                                                    )
                                                }
                                                value={englishTitle}
                                            >
                                                {story}
                                            </div>
                                        );
                                    })}
                                </Sizer>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.editionType')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    <div
                        className={
                            projectId ? `${baseClassName}_disable-title ` : ''
                        }
                    >
                        <SelectField
                            options={editions}
                            publicationForm
                            onChange={(e) =>
                                handleSelect('editionType', e, setEditionType)
                            }
                            value={editionType}
                        />
                    </div>
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={!bookId}
                        t={t}
                        value={t('PublicationForm.projectId')}
                        isRightToLeftDirection={isRightToLeftDirection}
                        tooltip={true}
                        tooltipData={t('PublicationForm.projectId-Message')}
                    />
                    {/* SalesForce Dropdown */}
                    <div
                        className={
                            isSIDPresent === true
                                ? `${baseClassName}_disable-title `
                                : ''
                        }
                        style={{ position: 'relative' }}
                    >
                        <TextField
                            id="project-id"
                            type="text"
                            placeholder={t('PublicationForm.typeHere')}
                            autoFocus={false}
                            custom={true}
                            bordered={false}
                            isPublication
                            onChange={onSearchProjectId}
                            value={projectId}
                        />
                        {projectIdDropDown && (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}_title-dropdown-rtl`
                                        : `${baseClassName}_title-dropdown`
                                }
                            >
                                <Sizer maxHeight="l" scrollY>
                                    {filteredProjectIds.map((id, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    onSelectProjectId(id)
                                                }
                                                value={projectId}
                                            >
                                                {id}
                                            </div>
                                        );
                                    })}
                                </Sizer>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={`${baseClassName}_row-wrapper`}>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.storyTitle')}
                        isRightToLeftDirection={isRightToLeftDirection}
                        isSubHeading={t('PublicationForm.translateSubHeading')}
                    />
                    <div>
                        <TextField
                            id="translated-story-title"
                            type="text"
                            placeholder={t('PublicationForm.typeHere')}
                            autoFocus={false}
                            custom={true}
                            bordered={false}
                            isPublication
                            onChange={(e) => onTitleChange(e, setTitle)}
                            value={title}
                        />
                    </div>
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.language')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    {languages.length > 0 && (
                        <div style={{ position: 'relative' }}>
                            <SelectField
                                options={languages}
                                publicationForm
                                onChange={(e) =>
                                    handleSelect('languageId', e, setLanguageId)
                                }
                                value={languageId}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.orientation')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    <SelectField
                        options={orientations}
                        publicationForm
                        onChange={(e) =>
                            handleSelect('orientation', e, setOrientation)
                        }
                        value={orientation}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default BookData;
