import React from 'react';
import PropTypes from 'prop-types';
import ResourceCard from '../../../ResourceCard';
import Grid from '../../../Grid';

const ResourcesCards = ({ favouriteResources }) => {
    const myLanguage = localStorage.getItem('locale');

    return (
        <Grid variant="4up">
            {favouriteResources.map((referenceItem) => {
                return (
                    <div key={referenceItem.id}>
                        <ResourceCard
                            myLanguage={myLanguage}
                            id={referenceItem.id}
                            slug={referenceItem.slug}
                            title={referenceItem.title}
                            contentUrl={referenceItem.contentUrl}
                            likesCount={referenceItem.likesCount}
                            readsCount={referenceItem.readsCount}
                            isFavourite={referenceItem.is_favourite}
                            coverImage={referenceItem.thumbUrl}
                            resourceThumbnailGTM="my-favorite-resource-thumbnail"
                            resourceTitleGTM="my-favorite-resource-title"
                        />
                    </div>
                );
            })}
        </Grid>
    );
};

ResourcesCards.propTypes = {
    favouriteResources: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            contentUrl: PropTypes.string.isRequired,
            likesCount: PropTypes.number.isRequired,
            readsCount: PropTypes.number.isRequired,
            is_favourite: PropTypes.bool.isRequired,
            thumbUrl: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ResourcesCards;
