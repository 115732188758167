import React from 'react';
import PropTypes from 'prop-types';

const Legend = ({ baseClassName, legend }) => {
    return <span className={`${baseClassName}__legend`}>{legend}</span>;
};

Legend.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    legend: PropTypes.string.isRequired,
};

export default Legend;
