import PropTypes from 'prop-types';
import React from 'react';
import SvgSymbol from '../SvgSymbol';
import './SvgIcon.scss';
import { isRightToLeftDirection } from '../../util/utils';

const baseClassName = 'pb-svg-icon';

const SvgIcon = ({
    name,
    parentClassName,
    variant,
    size,
    pushRight,
    pushLeft,
    pushLeftMedium,
    pushLeftSmall,
    svgIcon,
    pushIcon,
    rightAbsolute,
    svgIconBooks,
    bookIcon,
    playButton,
    bookCardSvg,
    governmentApprovedLogo,
    flip,
    favouriteIcon,
    shareIcon,
    replyArrow,
    labelText,
    infoGrey,
    errorPage,
    pushCenter,
    dataGTM,
}) => {
    const classNames = [baseClassName];

    // Helper function to add class names based on condition
    const addClassIf = (condition, className) =>
        condition && classNames.push(className);

    // Conditionally add class names based on props
    addClassIf(size && size !== 'm', `${baseClassName}--${size}`);
    addClassIf(name, `${baseClassName}--type-${name}`);
    addClassIf(parentClassName, parentClassName);
    addClassIf(variant, `${baseClassName}--${variant}`);
    addClassIf(pushLeft, `${baseClassName}--push-left`);
    addClassIf(pushRight, `${baseClassName}--push-right`);
    addClassIf(pushLeftMedium, `${baseClassName}--push-left-medium`);
    addClassIf(pushIcon, `${baseClassName}--push-icon`);
    addClassIf(pushLeftSmall, `${baseClassName}--push-left-small`);
    addClassIf(rightAbsolute, `${baseClassName}--right-absolute`);
    addClassIf(pushCenter, `${baseClassName}--push-center`);
    addClassIf(labelText, `${baseClassName}--label-text`);
    addClassIf(infoGrey, `${baseClassName}--info-grey`);
    addClassIf(errorPage, `${baseClassName}--error-page`);

    // Handle RTL (right-to-left) icons
    const rtlSuffix = isRightToLeftDirection ? '-rtl' : '';
    addClassIf(svgIcon, `${baseClassName}--svg-icon${rtlSuffix}`);
    addClassIf(svgIconBooks, `${baseClassName}--svg-icon-books${rtlSuffix}`);
    addClassIf(favouriteIcon, `${baseClassName}--favourite-icon${rtlSuffix}`);
    addClassIf(shareIcon, `${baseClassName}--share-icon${rtlSuffix}`);
    addClassIf(replyArrow, `${baseClassName}--reply-arrow${rtlSuffix}`);

    // Handle icon-specific conditions
    addClassIf(name === 'close', `${baseClassName}--type-close`);
    addClassIf(bookIcon, `${baseClassName}--book-icon`);
    addClassIf(playButton, `${baseClassName}--play-button`);
    addClassIf(bookCardSvg, `${baseClassName}--book-card-svg`);
    addClassIf(
        governmentApprovedLogo,
        `${baseClassName}--government-approved-title`
    );
    addClassIf(flip, `${baseClassName}--flip-image`);

    // Return the SvgSymbol with the dynamically generated class names
    return (
        <SvgSymbol
            name={name}
            parentClassName={classNames.join(' ')}
            dataGTM={dataGTM}
        />
    );
};

SvgIcon.propTypes = {
    name: PropTypes.string,
    parentClassName: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'accent']),
    size: PropTypes.oneOf([
        's',
        'm',
        'sm',
        'l',
        'xl',
        's-m',
        'sm',
        'm-l',
        'l-xl',
        'xl-xxl',
        'si',
        'mi',
        'custom',
    ]),
    pushRight: PropTypes.bool,
    pushLeft: PropTypes.bool,
    svgIcon: PropTypes.bool,
    pushLeftMedium: PropTypes.bool,
    pushIcon: PropTypes.bool,
    pushLeftSmall: PropTypes.bool,
    rightAbsolute: PropTypes.bool,
    svgIconBooks: PropTypes.bool,
    bookIcon: PropTypes.bool,
    playButton: PropTypes.bool,
    bookCardSvg: PropTypes.bool,
    governmentApprovedLogo: PropTypes.bool,
    flip: PropTypes.bool,
    favouriteIcon: PropTypes.bool,
    shareIcon: PropTypes.bool,
    replyArrow: PropTypes.bool,
    labelText: PropTypes.bool,
    infoGrey: PropTypes.bool,
    errorPage: PropTypes.bool,
    pushCenter: PropTypes.bool,
    dataGTM: PropTypes.string,
};

export default SvgIcon;
