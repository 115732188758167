import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';

const RemoveButton = ({
    t,
    baseClassName,
    removeFromBookList,
    book,
    manageReadingListId,
    removeFromBookListGTM,
}) => {
    return (
        <div className={`${baseClassName}__add-remove-button-section`}>
            <Link
                isInternal
                onClick={() => removeFromBookList(book, manageReadingListId)}
                dataGTM={removeFromBookListGTM}
            >
                <div
                    className="__add-remove-button"
                    data-gtm={removeFromBookListGTM}
                >
                    {t('global.remove')}
                </div>
            </Link>
        </div>
    );
};

RemoveButton.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    removeFromBookList: PropTypes.func.isRequired,
    book: PropTypes.object.isRequired,
    manageReadingListId: PropTypes.string.isRequired,
    removeFromBookListGTM: PropTypes.string,
};

export default RemoveButton;
