import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';
import { sortOptions } from '../../../lib/constants';

const SortReadingListMobile = ({
    t,
    isAscend,
    ascendingSortChange,
    descendingSortChange,
}) => {
    return [
        <span>
            <Link
                onClick={
                    isAscend
                        ? () => ascendingSortChange(sortOptions.descending)
                        : () => descendingSortChange(sortOptions.ascending)
                }
                lightBlack
                data-gtm="reading-list-alphabet-sort"
            >
                <div className="__option-values-media-sort">
                    {!isAscend
                        ? t('global.alphabet-reverse')
                        : t('global.alphabet')}
                    <SvgIcon name="sort" size="sort" pushLeft />
                </div>
            </Link>
        </span>,
    ];
};

SortReadingListMobile.propTypes = {
    t: PropTypes.func.isRequired,
    isAscend: PropTypes.bool.isRequired,
    ascendingSortChange: PropTypes.func.isRequired,
    descendingSortChange: PropTypes.func.isRequired,
};

export default SortReadingListMobile;
