import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isRightToLeftDirection } from '../../util/utils';
import './Checkbox.scss';
import Icon from './components/Icon';
import Legend from './components/Legend';

const Checkbox = ({
    onChange,
    disabled,
    label,
    name,
    id,
    value,
    checked,
    loading,
    radioIcon,
    inline,
    fontWeight,
    legend,
}) => {
    const [focus, setFocus] = useState(false);

    const baseClassName = 'pb-checkbox';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--checked`]: checked,
        [`${baseClassName}--focused`]: focus,
        [`${baseClassName}--disabled`]: disabled || loading,
        [`${baseClassName}--inline`]: inline,
        [`${baseClassName}--loading`]: loading,
        [`${baseClassName}--radio-icon`]: radioIcon,
        [`${baseClassName}--font-weight--${fontWeight}`]: fontWeight,
    };

    const onChangeFunc = (e) => {
        const target = e.target;
        const checked = target.checked;
        const value = target.value;

        onChange({
            checked,
            value,
        });
    };

    const handleOnFocus = () => {
        setFocus(true);
    };

    const handleOnBlur = (e) => {
        setFocus(false);
    };
    return (
        <div className={classNames(classes)}>
            <Icon
                loading={loading}
                baseClassName={baseClassName}
                radioIcon={radioIcon}
                checked={checked}
            />
            <label className={`${baseClassName}__label`} htmlFor={id}>
                {isRightToLeftDirection ? ' ' + label : label}
                {legend && (
                    <Legend baseClassName={baseClassName} legend={legend} />
                )}
                <input
                    className={`${baseClassName}__input`}
                    type="checkbox"
                    id={id}
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChangeFunc}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    value={value}
                />
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    radioIcon: PropTypes.bool,
    inline: PropTypes.bool,
    fontWeight: PropTypes.oneOf(['normal', 'bold']),
    legend: PropTypes.string,
};

export default Checkbox;
