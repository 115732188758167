import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import Tooltip from 'react-tooltip';
import './InputFieldTitle.scss';

const InputFieldTitle = ({
    isRequired = false,
    isRightToLeftDirection = false,
    value,
    isSubHeading = null,
    tooltip = false,
    tooltipData = '',
}) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '9px' }}>
            <h4
                className={
                    isRightToLeftDirection
                        ? 'input-field-title-rtl'
                        : 'input-field-title'
                }
            >
                {value}
            </h4>
            {isRequired && (
                <SvgIcon name="label-text" size="custom" labelText />
            )}
            {tooltip && (
                <div style={{ position: 'relative' }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '-6px',
                            margin: '0 5px',
                        }}
                        data-tip={tooltipData}
                    >
                        <SvgIcon name="info-grey" infoGrey />
                    </div>
                    <Tooltip multiline />
                </div>
            )}
            {isSubHeading && (
                <h4
                    className={
                        isRightToLeftDirection
                            ? 'input-field-sub-heading-rtl'
                            : 'input-field-sub-heading'
                    }
                >
                    {isSubHeading}
                </h4>
            )}
        </div>
    );
};

InputFieldTitle.propTypes = {
    isRequired: PropTypes.bool,
    isRightToLeftDirection: PropTypes.bool,
    value: PropTypes.string.isRequired,
    isSubHeading: PropTypes.string,
    tooltip: PropTypes.bool,
    tooltipData: PropTypes.string,
};

export default InputFieldTitle;
