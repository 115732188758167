import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../Link';
import './Avatar.scss';

const Avatar = ({
    url,
    name,
    size = 'm',
    variant = 'default',
    parentClassName,
    marginRight = false,
    dataGTM,
    website,
}) => {
    const baseClassName = 'pb-avatar';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
        [`${baseClassName}--${size}`]: size,
        [`${baseClassName}--${variant}`]: variant,
        [`${baseClassName}--margin`]: marginRight,
    };

    const source = url && url !== '' ? url : require('./assets/avatar.svg');

    return (
        <div className={classNames(classes)} data-gtm={dataGTM}>
            <Link
                shouldOpenNewPage={true}
                href={website}
                dataGTM="profile-logo"
            >
                <img
                    className={`${baseClassName}__image`}
                    src={source}
                    alt={name}
                    data-gtm={dataGTM}
                />
            </Link>
        </div>
    );
};

Avatar.propTypes = {
    parentClassName: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'circular']),
    size: PropTypes.oneOf(['m', 'l', 's']),
    marginRight: PropTypes.bool,
};

export default Avatar;
