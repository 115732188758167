import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import ReadAloudsDetail from '../ReadAloudsDetail';
import LoaderBlock from '../LoaderBlock';
import NotFoundPage from '../NotFoundPage';
import {
    videoReadCountWorkflow,
    fetchReadAloudsWorkflow,
    fetchVideosDetailWorkflow,
} from '../../redux/readAloudsActions';
import withRouter from '../../util/withRouter';

const ReadAloudsDetailContainer = ({
    params,
    fetchReadAloudsWorkflow,
    fetchVideosDetailWorkflow,
    videoDetail,
    videoUrl,
    videoID,
    isFetchingVideoDetail,
    parentClassName,
    online,
    isLoggedIn,
    viewport,
    videoReadsCount,
    videoLikesCount,
    isFavourite,
    t,
}) => {
    const baseClassName = 'pb-video-detail-container';
    const videoSlug = params.slug;

    const myLanguage = localStorage.getItem('locale');

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    useEffect(() => {
        fetchReadAloudsWorkflow();
        fetchVideosDetailWorkflow(videoSlug);
    }, []);

    useEffect(() => {
        fetchVideosDetailWorkflow(params.slug);
    }, [params.slug]);

    if (isFetchingVideoDetail) {
        return <LoaderBlock />;
    }

    if (!isFetchingVideoDetail && videoDetail.id === undefined) {
        return <NotFoundPage />;
    }

    return (
        <div className={classNames(classes)}>
            <ReadAloudsDetail
                myLanguage={myLanguage}
                t={t}
                videoId={videoID}
                readsCount={videoReadsCount}
                likesCount={videoLikesCount}
                isFavourite={isFavourite}
                viewport={viewport}
                online={online}
                isLoggedIn={isLoggedIn}
                readCount={videoReadCountWorkflow}
                videoDetail={videoDetail}
                videoUrl={videoUrl}
                logInMsg={t('global.please-log-in', {
                    action: t('global.like', 1),
                    content_type: t('global.story', 1),
                })}
                videosVideoCloseGTM="videos-video-close"
                videosVideoThumbnailGTM="videos-video-thumbnail"
                videosVideoWatchGTM="videos-video-watch"
                videosVideoDownloadGTM="videos-video-download"
                videosVideoFavoriteGTM="videos-video-favorite"
                videoDisqusGTM="video-disqus-comments"
            />
        </div>
    );
};

const mapDispatchToProps = {
    fetchReadAloudsWorkflow,
    fetchVideosDetailWorkflow,
};

const mapStateToProps = ({ readAlouds, viewport, network, user }) => ({
    ...readAlouds,
    videoDetail: readAlouds.videoDetail,
    videoID: readAlouds.videoDetail.id,
    videoReadsCount: readAlouds.videoDetail.readsCount,
    videoLikesCount: readAlouds.videoDetail.likesCount,
    isFavourite: readAlouds.videoDetail.is_favourite,
    videoUrl: readAlouds.videoDetail.materialUrl,
    online: network.online,
    isLoggedIn: user.isLoggedIn,
    viewport,
});

ReadAloudsDetailContainer.propTypes = {
    params: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }).isRequired,
    fetchReadAloudsWorkflow: PropTypes.func.isRequired,
    fetchVideosDetailWorkflow: PropTypes.func.isRequired,
    videoDetail: PropTypes.object.isRequired,
    videoUrl: PropTypes.string,
    videoID: PropTypes.string,
    isFetchingVideoDetail: PropTypes.bool.isRequired,
    parentClassName: PropTypes.string,
    online: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    viewport: PropTypes.object.isRequired,
    videoReadsCount: PropTypes.number,
    videoLikesCount: PropTypes.number,
    isFavourite: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(ReadAloudsDetailContainer)
    )
);
