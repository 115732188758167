import { useState, useEffect } from 'react';

const useViewport = () => {
    const [viewport, setViewport] = useState({
        isMobile: false,
        isTablet: false,
        isOther: false,
    });

    const updateViewport = () => {
        if (window.innerWidth < 768) {
            setViewport({ isMobile: true, isTablet: false, isOther: false });
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
            setViewport({ isMobile: false, isTablet: true, isOther: false });
        } else {
            setViewport({ isMobile: false, isTablet: false, isOther: true });
        }
    };

    useEffect(() => {
        updateViewport();
        window.addEventListener('resize', updateViewport);

        return () => {
            window.removeEventListener('resize', updateViewport);
        };
    }, []);

    return viewport;
};

export default useViewport;
