import { sortOptions } from '../../../lib/constants';
import { isRightToLeftDirection } from '../../../util/utils';

export const defaultFilters = {
    category: [],
    role: [],
    language: [],
    sort: sortOptions.relevance,
};

export const helpGuideSteps = (t) => ({
    steps: [
        {
            target: '.__bookFilter',
            content: t('Video.filterHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-filters-bar__actions--secondary',
            content: t('Video.sortHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-link--normal',
            content: t('Video.favoriteHelpGuide'),
            placementBeacon: 'left',
        },
    ],
    mobileSteps: [
        isRightToLeftDirection
            ? {
                  target: '.pb-filters-bar__filter-option-rtl',
                  content: t('Video.filterHelpGuide'),
                  placementBeacon: 'left',
                  disableBeacon: true,
              }
            : {
                  target: '.pb-filters-bar__filter-option',
                  content: t('Video.filterHelpGuide'),
                  placementBeacon: 'left',
                  disableBeacon: true,
              },
        {
            target: '.pb-filters-bar__sort-option',
            content: t('Video.sortHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-link--normal',
            content: t('Video.favoriteHelpGuide'),
            placementBeacon: 'left',
        },
    ],
});

export const filtersBarSortOptions = (t) => [
    {
        name: t('sort.relevance'),
        queryValue: sortOptions.relevance,
    },
    {
        name: t('sort.new-arrivals-images'),
        queryValue: sortOptions.newArrivals,
    },
    {
        name: t('sort.most-viewed'),
        queryValue: sortOptions.mostViewed,
    },
    {
        name: t('sort.most-liked'),
        queryValue: sortOptions.mostLiked,
    },
];
