import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../Button';
import { isRightToLeftDirection } from '../../../../../util/utils';

const IllustratorsTables = ({
    t,
    baseClassName,
    offlineIllustrators,
    onRemoveIllustrator,
}) => {
    return (
        <table>
            <tr
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_table-header-rtl`
                        : `${baseClassName}_table-header`
                }
            >
                <th>{t('PublicationForm.authorMail')}</th>
                <th>{t('PublicationForm.authorFirstName')}</th>
                <th>{t('PublicationForm.authorLastName')}</th>
                <th>{t('PublicationForm.authorBio')}</th>
                <th>{t('PublicationForm.actions')}</th>
            </tr>
            {offlineIllustrators.length >= 1 &&
                offlineIllustrators.map((illustrator, index) => {
                    return (
                        <tr
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_table-body-rtl`
                                    : `${baseClassName}_table-body`
                            }
                        >
                            <td>{illustrator && illustrator.email}</td>
                            <td>{illustrator && illustrator.first_name}</td>
                            <td>{illustrator && illustrator.last_name}</td>
                            <td>{illustrator && illustrator.bio}</td>
                            <td>
                                <Button
                                    label={t('PublicationForm.remove')}
                                    variant="remove"
                                    onClick={() =>
                                        onRemoveIllustrator(
                                            index,
                                            illustrator.id
                                        )
                                    }
                                />
                            </td>
                        </tr>
                    );
                })}
        </table>
    );
};

IllustratorsTables.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    offlineIllustrators: PropTypes.array.isRequired,
    onRemoveIllustrator: PropTypes.func.isRequired,
};

export default IllustratorsTables;
