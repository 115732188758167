import React from 'react';
import PropTypes from 'prop-types';
import './BookUploadHeader.scss';
import locales from '../../i18n';
import { BookPublicationForm } from '../../api';
import { isRightToLeftDirection } from '../../util/utils';
import withRouter from '../../util/withRouter';

const BookUploadHeader = ({ bookId, currentPage, navigate }) => {
    const baseClassName = 'book-upload_header';
    const locale = localStorage.getItem('locale') || 'en';
    const messages = locales[locale];

    const saveBook = () => {
        let x = document.getElementById('snackbar1');
        x.className = 'show';
        setTimeout(() => {
            navigate('/');
            x.className = x.className.replace('show', '');
        }, 7000);
    };

    const publishBook = () => {
        let x =
            'Woohoo! Your story has been published. Please wait for 2-5 minutes until the story shows on the BOOKS page.';
        BookPublicationForm.publish(bookId).then((response) => {
            if (response.status === 200) {
                navigate('/');
                window.location.reload();
                localStorage.setItem('publishMessage', x);
            } else {
                alert('Error while publishing the book');
            }
        });
    };

    return (
        <div>
            <div className={`${baseClassName}__next_button`}>
                <div className={`${baseClassName}__book_tag`}>
                    {' '}
                    {messages['Book.upload']}
                </div>
                <div className={`${baseClassName}__gap`}>
                    <button
                        className={
                            `${baseClassName}__save_button ` +
                            (currentPage === 1
                                ? `${baseClassName}__disable`
                                : '')
                        }
                        onClick={() => saveBook()}
                    >
                        {messages['Book.save']}
                    </button>
                    <button
                        className={
                            `${baseClassName}__publish_button ` +
                            (currentPage === 4
                                ? ''
                                : `${baseClassName}__disable`)
                        }
                        onClick={() => publishBook()}
                    >
                        {messages['Book.publish']}
                    </button>
                </div>
            </div>
            <div className={`${baseClassName}__navigation`}>
                <div
                    className={
                        currentPage >= 1
                            ? isRightToLeftDirection
                                ? `${baseClassName}__step_border_left`
                                : `${baseClassName}__step_border_right`
                            : `${baseClassName}__initial_step`
                    }
                >
                    {messages['Book.step-1']}
                </div>
                <div
                    className={
                        currentPage >= 2
                            ? isRightToLeftDirection
                                ? `${baseClassName}__step_border_left`
                                : `${baseClassName}__step_border_right`
                            : `${baseClassName}__initial_step`
                    }
                >
                    {messages['Book.step-2']}
                </div>
                <div
                    className={
                        currentPage >= 3
                            ? isRightToLeftDirection
                                ? `${baseClassName}__step_border_left`
                                : `${baseClassName}__step_border_right`
                            : `${baseClassName}__initial_step`
                    }
                >
                    {messages['Book.step-3']}
                </div>
                <div
                    className={
                        `${baseClassName}__initial_step ` +
                        (currentPage >= 4 ? `${baseClassName}__last_step` : '')
                    }
                >
                    {messages['Book.step-4']}
                </div>
            </div>
            <div id="snackbar1">
                Your Story has been saved. You can edit, complete and publish
                your story from Drafts Tab under the Profile in the Menu.
            </div>
        </div>
    );
};

BookUploadHeader.propTypes = {
    bookId: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withRouter(BookUploadHeader);
