import React from 'react';
import PropTypes from 'prop-types';
import ZeroFavourites from './ZeroFavourites';
import RequiredCards from './RequiredCards';

const CardsContainer = ({
    t,
    selectedTab,
    booksCount,
    isFetchingData,
    updatedResourcesCount,
    updatedVideosCount,
    favouriteBooks,
    favouriteResources,
    favouriteVideos,
}) => {
    return (
        <div className="__my-readinglist-container-section">
            <div className="__video-card-section">
                <ZeroFavourites
                    t={t}
                    selectedTab={selectedTab}
                    booksCount={booksCount}
                    isFetchingData={isFetchingData}
                    updatedResourcesCount={updatedResourcesCount}
                    updatedVideosCount={updatedVideosCount}
                />
                <RequiredCards
                    isFetchingData={isFetchingData}
                    selectedTab={selectedTab}
                    favouriteBooks={favouriteBooks}
                    favouriteResources={favouriteResources}
                    favouriteVideos={favouriteVideos}
                />
            </div>
        </div>
    );
};

CardsContainer.propTypes = {
    t: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired,
    booksCount: PropTypes.number.isRequired,
    isFetchingData: PropTypes.bool.isRequired,
    updatedResourcesCount: PropTypes.number.isRequired,
    updatedVideosCount: PropTypes.number.isRequired,
    favouriteBooks: PropTypes.array.isRequired,
    favouriteResources: PropTypes.array.isRequired,
    favouriteVideos: PropTypes.array.isRequired,
};

export default CardsContainer;
