import React from 'react';
import PropTypes from 'prop-types';

const Contributors = ({ data }) => {
    const baseClassName = 'book-publication';

    return (
        <span>
            {data.map((item, i) => (
                <span className={`${baseClassName}__author-name`}>
                    {' '}
                    {item.first_name}
                    <span>{i < data.length - 1 ? ', ' : ''}</span>
                </span>
            ))}
        </span>
    );
};

Contributors.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            first_name: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Contributors;
