import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import TitleImage from '../../TitleImage';
import { links } from '../../../lib/constants';
import createStory from '../../../assets/Book_Upload.svg';
import gearIcon from '../../../assets/Dashboards.svg';

const ContentMangerElements = ({
    userEmail,
    baseClassName,
    t,
    mobileMenu,
    roles,
}) => {
    return [
        window.screen.width <= 768 ? null : (
            <Link
                key="upload-pdf"
                fullWidth
                href={links.upload()}
                noPadding
                dataGTM={userEmail + '-upload-pdf'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={createStory}
                    title={t('Illustration.upload-pdf')}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-upload-pdf'}
                />
                {mobileMenu ? null : (
                    <div
                        className={`${baseClassName}--separator`}
                        data-gtm={userEmail + '-upload-pdf'}
                    />
                )}
            </Link>
        ),
        <Link
            key="dasboard"
            fullWidth
            href={links.dashboard()}
            noPadding
            dataGTM={userEmail + '-content-manager-dashboard'}
        >
            <TitleImage
                className={`${baseClassName}--menu-icon`}
                svgName={gearIcon}
                title={t('global.content-manager-dashboard', 1)}
                mobileMenu={mobileMenu}
                dataGTM={userEmail + '-content-manager-dashboard'}
            />
            {mobileMenu ? null : (
                <div
                    className={`${baseClassName}--separator`}
                    data-gtm={userEmail + '-content-manager-dashboard'}
                />
            )}
        </Link>,
        roles.includes('sub_admin') || roles.includes('admin') ? (
            <Link
                key="data-dashboard"
                fullWidth
                href={links.dataDashboard()}
                noPadding
                dataGTM={userEmail + '-data-dashboard'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={gearIcon}
                    title={t('global.data-dashboard', 1)}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-data-dashboard'}
                />
                {mobileMenu ? null : (
                    <div
                        className={`${baseClassName}--separator`}
                        data-gtm={userEmail + '-data-dashboard'}
                    />
                )}
            </Link>
        ) : null,
    ];
};

ContentMangerElements.propTypes = {
    userEmail: PropTypes.string.isRequired,
    baseClassName: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    mobileMenu: PropTypes.bool.isRequired,
    roles: PropTypes.array.isRequired,
};

export default ContentMangerElements;
