import React from 'react';
import PropTypes from 'prop-types';
import InputFieldTitle from '../../InputFieldTitle';
import Dropdown from '../../Dropdown';
import TextField from '../../TextField';
import Picklist from '../../Picklist';
import { isRightToLeftDirection } from '../../../util/utils';

const Category = ({
    t,
    baseClassName,
    limitList,
    categoryTextValue,
    categories,
    handlePicklistChange,
    setCategoryTextValue,
}) => {
    return (
        <div className={`${baseClassName}_row-wrapper`}>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_header-container-rtl`
                        : `${baseClassName}_header-container`
                }
            >
                <InputFieldTitle
                    isRequired={true}
                    t={t}
                    value={t('PublicationForm.categories')}
                    isRightToLeftDirection={isRightToLeftDirection}
                />
                <Dropdown
                    align="left"
                    toggleEl={
                        <TextField
                            id="publication-form-categories"
                            value={limitList(categoryTextValue)}
                            isPublication
                            autoFocus={false}
                            bordered={false}
                            custom={true}
                            type="text"
                        />
                    }
                    minWidth="auto"
                >
                    <Picklist
                        id="publication-form-category"
                        options={categories}
                        onChange={(e) =>
                            handlePicklistChange(
                                categoryTextValue,
                                e,
                                setCategoryTextValue
                            )
                        }
                        checkedValues={categoryTextValue}
                    />
                </Dropdown>
            </div>
        </div>
    );
};

Category.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    limitList: PropTypes.func.isRequired,
    categoryTextValue: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    handlePicklistChange: PropTypes.func.isRequired,
    setCategoryTextValue: PropTypes.func.isRequired,
};

export default Category;
