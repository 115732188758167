import React from 'react';
import PropTypes from 'prop-types';
import './Stuffer.scss';

const Stuffer = ({
    children,
    parentClassName,
    horizontalSpacing,
    verticalSpacing,
}) => {
    const baseClassName = 'pb-stuffer';

    // Generate the list of class names conditionally
    const classNames = [
        baseClassName,
        horizontalSpacing && `${baseClassName}--h-${horizontalSpacing}`,
        verticalSpacing && `${baseClassName}--v-${verticalSpacing}`,
        parentClassName,
    ].filter(Boolean); // Filter out any falsy values (e.g., `undefined` or `null`)

    return <div className={classNames.join(' ')}>{children}</div>;
};

Stuffer.propTypes = {
    children: PropTypes.node.isRequired,
    parentClassName: PropTypes.string,
    horizontalSpacing: PropTypes.oneOf(['m', 'l', 'xl']),
    verticalSpacing: PropTypes.oneOf(['m', 'l', 'xl']),
};

export default Stuffer;
