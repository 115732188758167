import React from 'react';
import PropTypes from 'prop-types';
import TitleImage from '../../TitleImage';
import { isRightToLeftDirection } from '../../../util/utils';

const MobileHandler = ({ mobileMenu, title, avatar, baseClassName }) => {
    return mobileMenu ? (
        <div>
            <TitleImage
                title={title}
                mobileMenu={mobileMenu}
                avatar={avatar}
                reverse
            />
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}--padding-right`
                        : `${baseClassName}--padding-left`
                }
            >
                <div className={`${baseClassName}--separator-menu`} />
            </div>
        </div>
    ) : null;
};

MobileHandler.propTypes = {
    mobileMenu: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    baseClassName: PropTypes.string.isRequired,
};

export default MobileHandler;
