import React from 'react';
import PropTypes from 'prop-types';
import { isRightToLeftDirection } from '../../../util/utils';
import FavouriteStat from './FavouriteStat';
import FavouriteHandler from './FavouriteHandler';
import SvgIcon from '../../SvgIcon';

const StatusShare = ({
    baseClassName,
    isLoggedIn,
    likesCount,
    online,
    parentClassName,
    onFavoriteClicked,
    id,
    videosVideoFavoriteGTM,
    isFavourite,
    logInMsg,
    slug,
    t,
    readsCount,
}) => {
    return (
        <div className="__status-share">
            <div
                className={
                    isRightToLeftDirection
                        ? '__video-like-count-rtl'
                        : '__video-like-count'
                }
            >
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__like-count-rtl`
                            : `${baseClassName}__like-count`
                    }
                >
                    {isLoggedIn ? (
                        <FavouriteStat
                            likesCount={likesCount}
                            online={online}
                            parentClassName={parentClassName}
                            isLoggedIn={isLoggedIn}
                            onFavoriteClicked={onFavoriteClicked}
                            id={id}
                            videosVideoFavoriteGTM={videosVideoFavoriteGTM}
                            isFavourite={isFavourite}
                        />
                    ) : (
                        <FavouriteHandler
                            parentClassName={parentClassName}
                            logInMsg={logInMsg}
                            slug={slug}
                            videosVideoFavoriteGTM={videosVideoFavoriteGTM}
                            t={t}
                            likesCount={likesCount}
                            online={online}
                            isLoggedIn={isLoggedIn}
                            onFavoriteClicked={onFavoriteClicked}
                            id={id}
                            isFavourite={isFavourite}
                        />
                    )}
                </div>
                <div>
                    <span>
                        <SvgIcon
                            name="playIcon"
                            size="sm"
                            pushRight
                            svgIcon
                            playButton
                        />
                    </span>
                    <span>{readsCount}</span>
                </div>
            </div>
        </div>
    );
};

StatusShare.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likesCount: PropTypes.number.isRequired,
    online: PropTypes.bool.isRequired,
    parentClassName: PropTypes.string.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    videosVideoFavoriteGTM: PropTypes.string.isRequired,
    isFavourite: PropTypes.bool.isRequired,
    logInMsg: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    readsCount: PropTypes.number.isRequired,
};

export default StatusShare;
