import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Link from '../Link';
import List from '../List';
import SvgIcon from '../SvgIcon';
import './SocialLinks.scss';

const SocialLinks = ({ t, dataGTM, theme, variant, links }) => {
    const baseClassName = 'pb-social-links';
    const classNames = [baseClassName];

    // Conditionally add theme and variant to classNames
    if (theme) {
        classNames.push(`${baseClassName}--${theme}`);
    }

    if (variant) {
        classNames.push(`${baseClassName}--${variant}`);
    }

    // Generate the social link elements
    const linkEls = Object.keys(links).map((l, i) => {
        const link = links[l];
        if (link) {
            return (
                <Link
                    key={`${i}-${link}`}
                    parentClassName={`${baseClassName}__link`}
                    href={link}
                    shouldOpenNewPage
                    title={t(`global.${l}`)}
                    dataGTM={dataGTM}
                >
                    <SvgIcon name={l} pushIcon dataGTM={dataGTM} />
                </Link>
            );
        }
        return null;
    });

    return (
        <List
            socialLinks
            parentClassName={classNames.join(' ')}
            socialLink
            dataGTM={dataGTM}
        >
            {linkEls}
        </List>
    );
};

SocialLinks.propTypes = {
    t: PropTypes.func.isRequired,
    dataGTM: PropTypes.string,
    theme: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'circular']),
    t: PropTypes.func.isRequired,
    dataGTM: PropTypes.string,
};

SocialLinks.defaultProps = {
    variant: 'default',
    links: {},
};

export default translate()(SocialLinks);
