import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import Loader from '../Loader';
import Link from '../Link';
import Dropdown from '../Dropdown';
import List from '../List';
import './Button.scss';
import { links } from '../../lib/constants';
import { isRightToLeftDirection } from '../../util/utils';

// eslint-disable-next-line no-script-url
const noopStr = 'javascript:;';

const Button = ({
    label,
    parentClassName,
    iconLeft,
    iconRight,
    fullWidth,
    disabled,
    matchInputFields,
    loading,
    variant = 'default',
    size = 'm',
    inGroup,
    mergeTop,
    mergeBottom,
    mergeSides,
    buttonBlue,
    readStory,
    iconLeftOffline,
    applyButton,
    dataGTM,
    viewVideos,
    download,
    href,
    id,
    onClick,
    saveOffline,
    onClickAddToOffline,
    onClickRemoveFromOffline,
    availableOffline,
    isLoggedIn,
    homePage,
    cardLevelOffline,
    isSavingOffline,
    t,
    bookSlug,
    btnDisabled,
    storyReader,
    buttonWatchBlue,
    fullScreen,
}) => {
    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            if (disabled) {
                e.stopPropagation();
            } else if (!loading) {
                if (onClick) {
                    onClick(e);
                } else if (download) {
                    const element = document.createElement('a');
                    const file = new Blob([href], { type: 'pdf/*' });
                    element.href = URL.createObjectURL(file);
                    element.download = `book-${id}.pdf`;
                    element.click();
                }
            }
        },
        [disabled, loading, onClick, download, href, id]
    );

    const leftIconEl = useMemo(() => {
        if (loading) {
            return (
                <Loader
                    size="m"
                    parentClassName={
                        isRightToLeftDirection
                            ? 'pb-button__icon-right'
                            : 'pb-button__icon-left'
                    }
                />
            );
        } else if (iconLeft) {
            return (
                <SvgIcon
                    name={iconLeft}
                    size="ml"
                    parentClassName={
                        isRightToLeftDirection
                            ? 'pb-button__icon-right'
                            : 'pb-button__icon-left'
                    }
                    dataGTM={dataGTM}
                />
            );
        } else if (iconLeftOffline) {
            return (
                <SvgIcon
                    name={iconLeftOffline}
                    size="sm"
                    parentClassName={
                        isRightToLeftDirection
                            ? 'pb-button__icon-left-offline'
                            : 'pb-button__icon-right-offline'
                    }
                    dataGTM={dataGTM}
                />
            );
        }
        return null;
    }, [loading, iconLeft, iconLeftOffline, isRightToLeftDirection, dataGTM]);

    const rightIconEl = useMemo(() => {
        if (iconRight) {
            return (
                <SvgIcon
                    name={iconRight}
                    size="m"
                    parentClassName="pb-button__icon-right"
                    dataGTM={dataGTM}
                />
            );
        }
        return null;
    }, [iconRight, dataGTM]);

    const classes = useMemo(
        () =>
            classNames({
                'pb-button': true,
                [parentClassName]: parentClassName,
                [`pb-button--${variant}`]: variant,
                [`pb-button--size-${size}`]: size,
                'pb-button--disabled': disabled || loading,
                'pb-button--loading': loading,
                'pb-button--match-input-fields': matchInputFields,
                'pb-button--full-width': fullWidth,
                [`pb-button--in-group-${inGroup}`]: inGroup,
                'pb-button--merge-top': mergeTop,
                'pb-button--merge-bottom': mergeBottom,
                'pb-button--merge-sides': mergeSides,
                'pb-button--button-blue': buttonBlue,
                'pb-button--read-story': readStory,
                'pb-button--apply-button': applyButton,
                'pb-button--view-videos': viewVideos,
                'pb-button--button-disabled': btnDisabled,
                [fullScreen
                    ? `pb-button--story-reader-fs`
                    : `pb-button--story-reader`]: storyReader,
                'pb-button--button-watch-blue': buttonWatchBlue,
            }),
        [
            parentClassName,
            variant,
            size,
            disabled,
            loading,
            matchInputFields,
            fullWidth,
            inGroup,
            mergeTop,
            mergeBottom,
            mergeSides,
            buttonBlue,
            readStory,
            applyButton,
            viewVideos,
            btnDisabled,
            storyReader,
            buttonWatchBlue,
            fullScreen,
        ]
    );

    if (saveOffline) {
        if (disabled) {
            return (
                <Dropdown
                    toggleEl={
                        <a
                            className={classNames(classes)}
                            href={noopStr}
                            saveOffline={saveOffline ? saveOffline : null}
                            cardLevelOffline={
                                cardLevelOffline ? cardLevelOffline : null
                            }
                            dataGTM="book-save-offline"
                        >
                            {/* <SvgIcon name="offline" size="m" pushRight /> */}
                            <div className="save-offline-text">
                                {t('Book.add-to-offline')}
                            </div>
                        </a>
                    }
                    minWidth="m"
                    align="left"
                >
                    <List nowrap>
                        {process.env.REACT_APP_FEATURE_AUTH ? (
                            <a
                                className={classNames(classes)}
                                href={noopStr}
                                dataGTM="book-log-in"
                            >
                                {t('Book.please-log-in-add-story-to-offline')}
                                <SvgIcon name="user" /> {t('global.log-in')}
                            </a>
                        ) : (
                            <a
                                className={classNames(classes)}
                                href={noopStr}
                                onClick={
                                    bookSlug === 'false'
                                        ? links.login()
                                        : links.saveOffLogin(bookSlug)
                                }
                                dataGTM="book-log-in"
                            >
                                {t('Book.please-log-in-add-story-to-offline')}
                                <SvgIcon name="user" /> {t('global.log-in')}
                            </a>
                        )}
                    </List>
                </Dropdown>
            );
        } else {
            if (isLoggedIn) {
                return (
                    <div>
                        {homePage ? (
                            <Link
                                saveOffline={saveOffline ? saveOffline : null}
                                cardLevelOffline={
                                    cardLevelOffline ? cardLevelOffline : null
                                }
                                onClick={
                                    availableOffline
                                        ? onClickRemoveFromOffline
                                        : onClickAddToOffline
                                }
                                theme={availableOffline ? 'danger' : null}
                                dataGTM="book-save-offline"
                            >
                                {isSavingOffline ? null : null}{' '}
                                {availableOffline ? (
                                    <div className={classNames(classes)}>
                                        {t('Book.saved')}
                                    </div>
                                ) : (
                                    <div className={classNames(classes)}>
                                        {t('Book.add-to-offline')}
                                    </div>
                                )}
                            </Link>
                        ) : (
                            <Link
                                className={classNames(classes)}
                                href={noopStr}
                                saveOffline={saveOffline ? saveOffline : null}
                                cardLevelOffline={
                                    cardLevelOffline ? cardLevelOffline : null
                                }
                                onClick={
                                    availableOffline
                                        ? onClickRemoveFromOffline
                                        : onClickAddToOffline
                                }
                                theme={availableOffline ? 'danger' : null}
                                dataGTM="book-save-offline"
                            >
                                {isSavingOffline ? <Loader /> : null}{' '}
                                {availableOffline ? (
                                    <div className={classNames(classes)}>
                                        {t('Book.remove-from-offline')}
                                    </div>
                                ) : (
                                    <div className={classNames(classes)}>
                                        {t('Book.add-to-offline')}
                                    </div>
                                )}
                            </Link>
                        )}
                    </div>
                );
            } else {
                if (availableOffline) {
                    return (
                        <List inline bookRelatedCollection>
                            <Link
                                onClick={onClickRemoveFromOffline}
                                saveOffline={saveOffline ? saveOffline : null}
                                cardLevelOffline={
                                    cardLevelOffline ? cardLevelOffline : null
                                }
                                dataGTM="book-remove-offline"
                            >
                                {/* <SvgIcon name="bin" />{' '} */}
                                {homePage ? (
                                    <div className={classNames(classes)}>
                                        {t('Book.saved')}
                                    </div>
                                ) : (
                                    <div className={classNames(classes)}>
                                        {t('Book.remove-from-offline')}
                                    </div>
                                )}
                            </Link>
                        </List>
                    );
                } else {
                    return (
                        <Dropdown
                            toggleEl={
                                <Link
                                    saveOffline={
                                        saveOffline ? saveOffline : null
                                    }
                                    cardLevelOffline={
                                        cardLevelOffline
                                            ? cardLevelOffline
                                            : null
                                    }
                                    dataGTM="book-save-offline"
                                >
                                    {/* <SvgIcon name="offline" size="m" pushRight /> */}
                                    <div className={classNames(classes)}>
                                        {t('Book.add-to-offline')}
                                    </div>
                                </Link>
                            }
                            minWidth="m"
                            align="left"
                        >
                            <List nowrap>
                                {process.env.REACT_APP_FEATURE_AUTH ? (
                                    <Link
                                        fullWidth
                                        legend={t(
                                            'Book.please-log-in-add-story-to-offline'
                                        )}
                                        dataGTM="book-log-in"
                                    >
                                        <SvgIcon name="user" />{' '}
                                        {t('global.log-in')}
                                    </Link>
                                ) : (
                                    <Link
                                        fullWidth
                                        legend={t(
                                            'Book.please-log-in-add-story-to-offline'
                                        )}
                                        href={
                                            bookSlug === 'false'
                                                ? links.login()
                                                : links.saveOffLogin(bookSlug)
                                        }
                                        dataGTM="book-log-in"
                                    >
                                        <SvgIcon name="user" />{' '}
                                        {t('global.log-in')}
                                    </Link>
                                )}
                            </List>
                        </Dropdown>
                    );
                }
            }
        }
    } else {
        return (
            <a
                className={classes}
                href={download ? href : noopStr}
                onClick={handleClick}
                data-gtm={dataGTM}
            >
                {leftIconEl}
                {label}
                {rightIconEl}
            </a>
        );
    }
};

Button.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    parentClassName: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    fullWidth: PropTypes.bool,
    matchInputFields: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    inGroup: PropTypes.oneOf(['first', 'last', 'middle']),
    mergeTop: PropTypes.bool,
    mergeBottom: PropTypes.bool,
    mergeSides: PropTypes.bool,
    variant: PropTypes.oneOf([
        'default',
        'primary',
        'info',
        'danger',
        'warning',
        'success',
        'facebook',
        'google',
        'nav-link-button',
        'view-more',
        'enter',
        'primaryRL',
        'submit',
    ]),
    size: PropTypes.oneOf(['m', 's', 'l']),
};

export default Button;
