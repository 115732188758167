import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './List.scss';
import { isRightToLeftDirection } from '../../util/utils';

const List = ({
    children,
    inline,
    nowrap,
    parentClassName,
    separator,
    bookRelatedCollection,
    translationList,
    socialLink,
    noPadding,
    pushRight,
    socialLinks,
    type,
    socialShareIcons,
    tabletView,
}) => {
    const baseClassName = 'pb-list';
    const itemEls = [];

    const separatorEl = (
        <span className={`${baseClassName}__separator`}>{separator}</span>
    );

    if (children && children.length && typeof children !== 'string') {
        React.Children.toArray(children).forEach((c, i, a) => {
            if (c == null) return;
            itemEls.push(
                <li key={i}>
                    {c}
                    {inline && separator && i !== a.length - 1
                        ? separatorEl
                        : null}
                </li>
            );
        });
    } else {
        itemEls.push(<li key="1">{children}</li>);
    }

    const classes = {
        [`${baseClassName}--site-footer-social-links`]: socialLink,
        [baseClassName]: true,
        [parentClassName]: parentClassName,
        [`${baseClassName}--inline`]: inline,
        [`${baseClassName}--nowrap`]: nowrap,
        [`${baseClassName}--separated`]: inline && separator,
        [`${baseClassName}--no-padding`]: noPadding,
        [`${baseClassName}--padding`]: translationList,
        [isRightToLeftDirection
            ? `${baseClassName}--push-left`
            : `${baseClassName}--push-right`]: pushRight,
        [`${baseClassName}--social-links`]: socialLinks,
        [isRightToLeftDirection
            ? `${baseClassName}--social-share-icons-rtl`
            : `${baseClassName}--social-share-icons`]: socialShareIcons,
        [type === 'bookPage'
            ? isRightToLeftDirection
                ? `${baseClassName}--bookPage-rtl`
                : `${baseClassName}--bookPage`
            : isRightToLeftDirection
            ? `${baseClassName}--bookFinalPage-rtl`
            : `${baseClassName}--bookFinalPage`]: type,
        [isRightToLeftDirection
            ? `${baseClassName}--tabletView-rtl`
            : `${baseClassName}--tabletView`]: tabletView,
        [`${baseClassName}--book-related-collection`]: bookRelatedCollection,
    };

    return <ul className={classNames(classes)}>{itemEls}</ul>;
};

List.defaultProps = {
    socialLink: false,
    noPadding: false,
};

List.propTypes = {
    children: PropTypes.node.isRequired,
    inline: PropTypes.bool,
    nowrap: PropTypes.bool,
    separator: PropTypes.string,
    parentClassName: PropTypes.string,
    socialLink: PropTypes.bool,
    noPadding: PropTypes.bool,
};

export default List;
