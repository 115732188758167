import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import Link from '../../Link';
import VideoPlayer from '../../VideoPlayer';
import closeIcon from '../../../assets/closeIcon.png';

const VideoPlayerModal = ({
    videoModalClose,
    videosVideoCloseGTM,
    videoDetail,
    videoUrl,
    isVideoPlay,
}) => {
    return (
        <Modal>
            <div className="__close">
                <Link
                    onClick={() => videoModalClose()}
                    dataGTM={videosVideoCloseGTM}
                >
                    <img
                        className="__profile-images"
                        src={closeIcon}
                        alt=""
                        data-gtm={videosVideoCloseGTM}
                    />
                </Link>
            </div>
            <div>
                <VideoPlayer
                    name={videoDetail.name}
                    videoSrc={videoUrl}
                    videoPlay={isVideoPlay}
                />
            </div>
        </Modal>
    );
};

VideoPlayerModal.propTypes = {
    videoModalClose: PropTypes.func.isRequired,
    videosVideoCloseGTM: PropTypes.string.isRequired,
    videoDetail: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    videoUrl: PropTypes.string.isRequired,
    isVideoPlay: PropTypes.bool.isRequired,
};

export default VideoPlayerModal;
