import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ baseClassName, id, label }) => {
    return (
        <label className={`${baseClassName}__label`} htmlFor={id}>
            {label}
        </label>
    );
};

Label.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
export default Label;
