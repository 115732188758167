export const isRightToLeftDirection = ['ar', 'ur'].includes(
    localStorage.getItem('locale')
);
export const isRTLLanguage = (language) =>
    ['arabic', 'urdu'].includes(language.toLowerCase());

export const getTimeAgo = (commentedAt, t) => {
    const date1 = Date.now();
    const date2 = new Date(commentedAt).getTime();

    const diffInSeconds = Math.abs((date2 - date1) / 1000);

    const getTimeDiff = (value, unit, singularKey, pluralKey) => {
        return `${Math.floor(value)} ${t(
            `comments.${value === 1 ? singularKey : pluralKey}`
        )}`;
    };

    if (diffInSeconds < 60) {
        return getTimeDiff(diffInSeconds, 'seconds', 'second', 'seconds');
    }

    const diffInMinutes = diffInSeconds / 60;
    if (diffInMinutes < 60) {
        return getTimeDiff(diffInMinutes, 'minutes', 'minute', 'minutes');
    }

    const diffInHours = diffInMinutes / 60;
    if (diffInHours < 24) {
        return getTimeDiff(diffInHours, 'hours', 'hour', 'hours');
    }

    const diffInDays = diffInHours / 24;
    if (diffInDays < 30) {
        return getTimeDiff(diffInDays, 'days', 'day', 'days');
    }

    const diffInMonths = diffInDays / 30;
    if (diffInMonths < 12) {
        return getTimeDiff(diffInMonths, 'months', 'month', 'months');
    }

    const diffInYears = diffInMonths / 12;
    return getTimeDiff(diffInYears, 'years', 'year', 'years');
};

export const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };
};
