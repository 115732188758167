import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Caret from '../../Caret';

const UserInfoHeaderDropDown = ({
    classes,
    baseClassName,
    title,
    avatar,
    caret,
    variant,
}) => {
    return (
        <div className={classNames(classes)}>
            <div className={`${baseClassName}--child-elements`}>{title}</div>
            <div className={`${baseClassName}--child-elements`}>{avatar}</div>
            <div className={`${baseClassName}--child-elements`}>
                {caret && <Caret direction={caret} variant={variant} />}
            </div>
        </div>
    );
};

UserInfoHeaderDropDown.propTypes = {
    classes: PropTypes.object.isRequired,
    baseClassName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    caret: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};


export default UserInfoHeaderDropDown;
