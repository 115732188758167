import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';

const BookIllustrators = ({
    t,
    baseClassName,
    illustrators,
    addBookIllustratorsGTM,
}) => {
    return (
        <div className={`${baseClassName}__book-illustrated`}>
            <span className={`${baseClassName}__illustrated-by`}>
                {t('global.illustrator', 1)}
            </span>{' '}
            {illustrators &&
                illustrators.map((item, i) => (
                    <span
                        key={i}
                        className={`${baseClassName}__illustrated-name`}
                    >
                        <Link
                            href={'/users/' + item.slug}
                            isInternal
                            dataGTM={addBookIllustratorsGTM}
                        >
                            {item.name}
                            <span data-gtm={addBookIllustratorsGTM}>
                                {i < illustrators.length - 1 ? ', ' : ''}
                            </span>
                        </Link>
                    </span>
                ))}
        </div>
    );
};

BookIllustrators.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    illustrators: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    addBookIllustratorsGTM: PropTypes.string.isRequired,
};

export default BookIllustrators;
