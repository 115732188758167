import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../../Link';
import { isRightToLeftDirection } from '../../../util/utils';

const Title = ({ toggleOpen, baseClassName, relatedBookList, title, open }) => {
    const titleClasses = {
        [`${baseClassName}__title`]: true,
        [`${baseClassName}__title ${baseClassName}__align-text-right`]:
            isRightToLeftDirection,
        [`${baseClassName}__RelatedBookList`]: relatedBookList,
    };

    return (
        <Link onClick={toggleOpen} readOtherLanguage={true}>
            <div className={classNames(titleClasses)}>
                {title}
                <div
                    className={
                        open
                            ? `${baseClassName}__arrow ArrowUp`
                            : `${baseClassName}__arrow ArrowDown`
                    }
                ></div>
            </div>
        </Link>
    );
};

Title.propTypes = {
    toggleOpen: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    relatedBookList: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

export default Title;
