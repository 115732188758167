import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';

const OfflineText = ({ online, books, t }) => {
    return (
        <React.Fragment>
            {online && books.length <= 0 && (
                <div className="offline-link-button">
                    <Link
                        href={'/stories'}
                        isInternal
                        offlineLink
                        dataGTM="addBooksToOfflineLibrary"
                    >
                        {t('OfflineBooks.addBooksToOfflineLibrary')}
                    </Link>
                </div>
            )}
            {!online && books.length <= 0 && (
                <div className="offline-text">
                    {t('OfflineBooks.addBooksWhenOnline')}
                </div>
            )}
        </React.Fragment>
    );
};

OfflineText.propTypes = {
    online: PropTypes.bool.isRequired,
    books: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            likesCount: PropTypes.number,
            is_favourite: PropTypes.bool,
        })
    ).isRequired,
    t: PropTypes.func.isRequired,
};

export default OfflineText;
