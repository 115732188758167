import React from 'react';
import PropTypes from 'prop-types';
import './Caret.scss';

const Caret = ({
    variant = 'default',
    direction = 'down',
    parentClassName,
}) => {
    const baseClassName = 'pb-caret';
    const classNames = [
        baseClassName,
        direction && `${baseClassName}--${direction}`,
        parentClassName,
    ].join(' ');

    const variantMap = {
        white: require('./assets/Caret-white.svg'),
        'deep-charcoal': require('./assets/Caret-dc.svg'),
        'twilight-lavender': require('./assets/Caret-tl.svg'),
        default: require('./assets/Caret-down.svg'),
    };
    return (
        <img
            className={classNames}
            src={variantMap[variant] || variantMap.default}
            alt={`caret-${variant}`}
        />
    );
};

Caret.propTypes = {
    direction: PropTypes.oneOf(['down', 'up', 'left', 'right']),
    parentClassName: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'white']),
};

export default Caret;
