import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { delays } from '../../lib/constants';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
import './SlimNotification.scss';

const SlimNotification = ({ type, content, onDismiss }) => {
    useEffect(() => {
        // Set a timeout to dismiss the notification after a delay
        const timeout = setTimeout(() => {
            onDismiss();
        }, delays.slimNotificationModal);

        // Cleanup the timeout when the component unmounts
        return () => {
            clearTimeout(timeout);
        };
    }, [onDismiss]); // Dependency array ensures the effect only re-runs when onDismiss changes

    const baseClassName = 'pb-slim-notification';
    const classNames = [`${baseClassName}--${type}`];

    return (
        <div className={`${baseClassName} ${classNames.join(' ')}`}>
            <div className={`${baseClassName}__icon-wrapper`}>
                <SvgIcon
                    parentClassName={`${baseClassName}__icon`}
                    name={type}
                    size="m"
                />
            </div>
            <div className={`${baseClassName}__content-wrapper`}>
                <p className={`${baseClassName}__content`}>{content}</p>
            </div>
            <div className={`${baseClassName}__actions`}>
                <Link
                    parentClassName={`${baseClassName}__close`}
                    onClick={onDismiss}
                >
                    <SvgIcon name="close" />
                </Link>
            </div>
        </div>
    );
};

SlimNotification.defaultProps = {
    type: 'info',
};

SlimNotification.propTypes = {
    type: PropTypes.oneOf(['info', 'danger', 'success']),
    content: PropTypes.string.isRequired,
    onDismiss: PropTypes.func,
};

export default SlimNotification;
