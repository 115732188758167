import React from 'react';
import PropTypes from 'prop-types';
import BookCard from '../../../BookCard';
import Grid from '../../../Grid';

const BooksCards = ({ favouriteBooks }) => {
    const myLanguage = localStorage.getItem('locale');

    return (
        <Grid variant="4up">
            {favouriteBooks.map((book) => {
                return (
                    <BookCard
                        myLanguage={myLanguage}
                        key={book.slug}
                        id={book.id}
                        book={book}
                        faded={book.storyDownloaded}
                        bookLikes={book.likesCount}
                        isFavouriting={book.isFavouriting}
                        isFavourite={book.is_favourite}
                        enableFavouriting
                        bookImageGTM="my-favorite-book-image"
                        bookTitleGTM="my-favorite-book-title"
                        bookAuthorGTM="my-favorite-book-author"
                        bookIllustratorsGTM="my-favorite-book-illustrators"
                        bookLevelGTM="my-favorite-book-level"
                    />
                );
            })}
        </Grid>
    );
};

BooksCards.propTypes = {
    favouriteBooks: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            storyDownloaded: PropTypes.bool.isRequired,
            likesCount: PropTypes.number.isRequired,
            isFavouriting: PropTypes.bool.isRequired,
            is_favourite: PropTypes.bool.isRequired,
        })
    ).isRequired,
};

export default BooksCards;
