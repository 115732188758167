import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import Img from '../../Img';

const BookImage = ({
    onClick,
    baseClassName,
    bookPath,
    handleClick,
    addBookImageGTM,
    coverImage,
    title,
    imageSrcsetSizes,
    offline,
}) => {
    return (
        <Link
            isInternal={!onClick}
            parentClassName={`${baseClassName}__link`}
            href={bookPath}
            onClick={onClick && handleClick}
            dataGTM={addBookImageGTM}
        >
            <div
                className={`${baseClassName}__image-wrapper`}
                href={bookPath}
                data-gtm={addBookImageGTM}
            >
                <div
                    className={`${baseClassName}__image`}
                    data-gtm={addBookImageGTM}
                >
                    <Img
                        image={coverImage}
                        alt={title}
                        lazyLoad
                        sizes={imageSrcsetSizes.grid2up6up}
                        offline={offline}
                        dataGTM={addBookImageGTM}
                    />
                </div>
            </div>
        </Link>
    );
};

BookImage.propTypes = {
    onClick: PropTypes.func,
    baseClassName: PropTypes.string.isRequired,
    bookPath: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    addBookImageGTM: PropTypes.string.isRequired,
    coverImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageSrcsetSizes: PropTypes.object.isRequired,
    offline: PropTypes.bool,
};

export default BookImage;
