import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CroppedImage.scss';
import cancel from '../../assets/cancel.svg';
import save from '../../assets/save.svg';
import zoomIn from '../../assets/zoomIn.svg';
import zoomOut from '../../assets/zoomOut.svg';
import Cropper from 'react-easy-crop';
import getCroppedImg from './lib/cropImage';
// import 'react-easy-crop/react-easy-crop.css';

const CroppedImage = ({ srcData, onCropped, handleSave, handleClose }) => {
    const baseClassName = 'crop-image';

    const [image, setImage] = useState('');
    const [zoom, setZoom] = useState(1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [aspect] = useState(1 / 1.25);

    useEffect(() => {
        setImage(
            srcData.getElementsByTagName('img')[0] &&
                srcData.getElementsByTagName('img')[0].src
        );
    }, []);

    const zoomin = (zoom) => {
        setZoom((prev) => prev + 0.1);
    };

    const zoomout = (zoom) => {
        if (zoom > 1) {
            setZoom((prev) => prev - 0.1);
        }
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        onCropped(croppedImage);
    };

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    };

    return (
        <div className={`${baseClassName}__crop_wrapper`}>
            <div className={`${baseClassName}__button_wrapper`}>
                <div
                    className={`${baseClassName}__thumbnail_button`}
                    onClick={() => zoomin(zoom)}
                >
                    <img src={zoomIn} alt="alt" />
                </div>
                <div
                    className={[
                        `${baseClassName}__thumbnail_button`,
                        `${baseClassName}__zoom`,
                    ].join(' ')}
                    onClick={() => zoomout(zoom)}
                >
                    <img src={zoomOut} alt="alt" />
                </div>
                <div
                    className={`${baseClassName}__thumbnail_button`}
                    onClick={handleSave}
                >
                    <img src={save} alt="alt" />
                </div>
                <div
                    className={`${baseClassName}__thumbnail_button`}
                    onClick={handleClose}
                >
                    <img src={cancel} alt="alt" />
                </div>
            </div>

            <div className={`${baseClassName}__cropped_image`}>
                {image && (
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        style={{
                            containerStyle: {
                                width: '100%',
                                top: '15%',
                                backgroundColor: '#FFFFFF',
                            },
                        }}
                    />
                )}
            </div>
        </div>
    );
};

CroppedImage.propTypes = {
    srcData: PropTypes.object.isRequired,
    onCropped: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CroppedImage;
