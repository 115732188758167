import React from 'react';
import PropTypes from 'prop-types';
import './Inliner.scss';
import { isRightToLeftDirection } from '../../util/utils';

const Inliner = ({ children, width = 'm', isChildInput, auto }) => {
    const baseClassName = 'pb-inliner';

    const classNames = [
        baseClassName,
        `${baseClassName}--w-${width}`,
        isChildInput && `${baseClassName}--is-child-input`,
        auto &&
            (isRightToLeftDirection
                ? `${baseClassName}--auto-rtl`
                : `${baseClassName}--auto`),
    ]
        .filter(Boolean)
        .join(' ');

    return <div className={classNames}>{children}</div>;
};

Inliner.propTypes = {
    children: PropTypes.node,
    isChildInput: PropTypes.bool,
    auto: PropTypes.bool,
    size: PropTypes.oneOf(['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']),
};

export default Inliner;
