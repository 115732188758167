import React from 'react';
import PropTypes from 'prop-types';

// Import SVGs once (outside of the component) to avoid redundant reloading on every render
const requireSvgs = require.context('./svgs/', false, /\.svg$/);
const svgObjects = requireSvgs.keys().reduce((acc, s) => {
    const name = s.replace(/\.svg$/, '').replace(/^\.\//, '');
    acc[name] = requireSvgs(s).default;
    return acc;
}, {});

const SvgSymbol = ({ name, parentClassName, includeViewBox, dataGTM }) => {
    const svg = svgObjects[name];

    // If no matching SVG is found, return null
    if (!svg) return null;

    return (
        <svg
            className={parentClassName}
            viewBox={includeViewBox ? svg.viewBox : null}
            data-gtm={dataGTM}
        >
            <use xlinkHref={`#${svg.id}`} />
        </svg>
    );
};

SvgSymbol.defaultProps = {
    includeViewBox: false,
    parentClassName: '',
    dataGTM: null,
};

SvgSymbol.propTypes = {
    name: PropTypes.string.isRequired, // The name should match the file name of svg (without .svg)
    parentClassName: PropTypes.string,
    dataGTM: PropTypes.string,
    includeViewBox: PropTypes.bool,
    dataGTM: PropTypes.string,
};

export default SvgSymbol;
