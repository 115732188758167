import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputFieldTitle from '../../../InputFieldTitle';
import TextField from '../../../TextField';
import Button from '../../../Button';
import Sizer from '../../../Sizer';
import { BookPublicationForm } from '../../../../api';
import './Illustrators.scss';
import IllustratorsHeader from './components/IllustratorsHeader';
import IllustratorsTables from './components/IllustratorsTables';
import useDebounce from '../../../../hooks/useDebounce';
const Illustrators = ({
    offlineIllustratorIds,
    offlineIllustratorData,
    isGrey,
    t,
    title,
    subHeading,
    isRightToLeftDirection,
    btnLabel,
    projectId,
}) => {
    const baseClassName = 'book-illustrators';

    const [illustratorSearch, setIllustratorSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [bio, setBio] = useState('');
    const [dropDownFlag, setDropDownFlag] = useState(false);
    const [offlineIllustrators, setOfflineIllustrators] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false);

    useEffect(() => {
        if (offlineIllustratorData.length > 0) {
            const illustratorMap = new Map(
                offlineIllustratorData.map((item) => [
                    JSON.stringify(item),
                    item,
                ])
            );
            const illustartor = Array.from(illustratorMap.values());

            setOfflineIllustrators(illustartor);
        }
    }, []);

    const fetchContributors = (searchValue) => {
        BookPublicationForm.contributor(searchValue)
            .then((response) => response.json())
            .then((response) => {
                setUsers(response.users);
            });
    };

    const debouncedFetchContributors = useDebounce(fetchContributors, 500);

    const onIllustratorSearch = (e) => {
        const searchvalue = e.target.value;

        e.persist();
        setIllustratorSearch(searchvalue);
        setEmail(searchvalue);
        setDropDownFlag(true);

        if (searchvalue === '') {
            setDropDownFlag(false);
        }

        if (searchvalue) {
            debouncedFetchContributors(searchvalue);
        }
    };

    const onIllustratorSelect = (e) => {
        setEmail(e.email);
        setFirstName(e.first_name);
        setLastName(e.last_name);
        setBio(e.bio);
        setIllustratorSearch(e.email);
    };

    const onDescriptionChange = (e, setter) => {
        setter(e.target.value);
    };

    const onAddIllustrator = () => {
        const data = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            bio: bio,
        };

        let uniqueIllustrator = offlineIllustrators;
        if (email) {
            setDisableBtn(true);
            BookPublicationForm.addUser(data)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if (
                        !uniqueIllustrator.some(
                            (e) => e.email === response.user.email
                        )
                    ) {
                        setOfflineIllustrators((prev) => [
                            ...prev,
                            response.user,
                        ]);
                        setEmail('');
                        setIllustratorSearch('');
                        setFirstName('');
                        setLastName('');
                        setBio('');
                    } else {
                        alert('User exists');
                    }
                    setDisableBtn(false);

                    offlineIllustratorIds(response.user.id, 'add');
                });
        } else {
            alert('Fields cannot be empty, Please Select/Add to proceed');
        }
    };

    const onRemoveIllustrator = (authorIndex, id) => {
        setOfflineIllustrators((prev) =>
            prev.filter((author, index) => index !== authorIndex)
        );

        offlineIllustratorIds(id, 'remove');
    };

    return (
        <div
            style={
                isGrey ? { backgroundColor: 'rgba(228, 236, 239, 0.3)' } : null
            }
            onClick={() => setDropDownFlag(false)}
        >
            <IllustratorsHeader
                baseClassName={baseClassName}
                title={title}
                subHeading={subHeading}
            />
            <div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_input-row-rtl`
                            : `${baseClassName}_input-row`
                    }
                >
                    <div style={{ position: 'relative' }}>
                        <InputFieldTitle
                            isRequired={false}
                            value={t('PublicationForm.authorMail')}
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                        <TextField
                            id="translated-story-title"
                            type="text"
                            placeholder={t('PublicationForm.typeHere')}
                            autoFocus={false}
                            custom={true}
                            bordered={false}
                            isPublication
                            onChange={(e) => onIllustratorSearch(e)}
                            value={illustratorSearch}
                        />
                        {dropDownFlag && (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}_title-dropdown-rtl`
                                        : `${baseClassName}_title-dropdown`
                                }
                            >
                                <Sizer maxHeight="l" scrollY>
                                    {users.map((user, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    onIllustratorSelect(user)
                                                }
                                                value={user.email}
                                            >
                                                {user.email}
                                            </div>
                                        );
                                    })}
                                </Sizer>
                            </div>
                        )}
                    </div>
                    <div>
                        <InputFieldTitle
                            isRequired={false}
                            value={t('PublicationForm.authorFirstName')}
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                        <TextField
                            id="translated-story-title"
                            type="text"
                            placeholder={t('PublicationForm.typeHere')}
                            autoFocus={false}
                            custom={true}
                            bordered={false}
                            isPublication
                            onChange={(e) =>
                                onDescriptionChange(e, setFirstName)
                            }
                            value={firstName}
                        />
                    </div>
                    <div>
                        <InputFieldTitle
                            isRequired={false}
                            value={t('PublicationForm.authorLastName')}
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                        <TextField
                            id="translated-story-title"
                            type="text"
                            placeholder={t('PublicationForm.typeHere')}
                            autoFocus={false}
                            custom={true}
                            bordered={false}
                            isPublication
                            onChange={(e) =>
                                onDescriptionChange(e, setLastName)
                            }
                            value={lastName}
                        />
                    </div>
                </div>
                <div>
                    <InputFieldTitle
                        isRequired={false}
                        value={t('PublicationForm.authorBio')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}_bio-container-rtl`
                                : `${baseClassName}_bio-container`
                        }
                    >
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_bio-rtl`
                                    : `${baseClassName}_bio`
                            }
                        >
                            <TextField
                                id="translated-story-title"
                                type="text"
                                placeholder={t('PublicationForm.typeHere')}
                                autoFocus={false}
                                custom={true}
                                bordered={false}
                                isPublication
                                onChange={(e) => onDescriptionChange(e, setBio)}
                                value={bio}
                            />
                        </div>
                        <div
                            className={
                                disableBtn ||
                                (projectId && offlineIllustrators.length > 0)
                                    ? `${baseClassName}_disable-illustrator-btn`
                                    : ''
                            }
                        >
                            <Button
                                label={btnLabel}
                                variant="contributor"
                                onClick={onAddIllustrator}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {offlineIllustrators.length >= 1 && (
                <IllustratorsTables
                    t={t}
                    baseClassName={baseClassName}
                    offlineIllustrators={offlineIllustrators}
                    onRemoveIllustrator={onRemoveIllustrator}
                />
            )}
        </div>
    );
};

Illustrators.propTypes = {
    offlineIllustratorIds: PropTypes.func.isRequired,
    offlineIllustratorData: PropTypes.array.isRequired,
    isGrey: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    isRightToLeftDirection: PropTypes.bool.isRequired,
    btnLabel: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
};

export default Illustrators;
