import React from 'react';
import BookUploadHeader from '../../BookUploadHeader';
import { isRightToLeftDirection } from '../../../util/utils';

const PublicationHeader = ({ t, baseClassName, displayMessages }) => {
    return (
        <React.Fragment>
            <BookUploadHeader currentPage={1} />
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_form-info-rtl`
                        : `${baseClassName}_form-info`
                }
            >
                {t('PublicationForm.information')}
            </div>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_form-error-info-rtl`
                        : `${baseClassName}_form-error-info`
                }
            >
                {displayMessages &&
                    displayMessages.map((error) => {
                        return <div>{error}</div>;
                    })}
            </div>
        </React.Fragment>
    );
};

export default PublicationHeader;
