import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';
import { isRightToLeftDirection } from '../../../util/utils';

const UploadPDF = ({
    t,
    baseClassName,
    fileName,
    uploadStarted,
    progressValue,
    encoding,
    handleFiles,
    stopUploading,
}) => {
    return (
        <div>
            <span
                className={
                    `${baseClassName}__title ` +
                    (isRightToLeftDirection ? `${baseClassName}__mirror` : '')
                }
            >
                {' '}
                {t('Book.upload-pdf-message')}
            </span>
            <br />
            <div id="drop-area">
                <div>
                    <div id="gallery">
                        <SvgIcon
                            name="placeholder"
                            size={'gallery-placeholder'}
                        />
                    </div>
                </div>
                <div className={`${baseClassName}__spacing`}>
                    <div className={`${baseClassName}__fileName`}>
                        {fileName !== '' ? fileName : t('Book.drag')}
                    </div>
                    <div style={{ lineHeight: '0rem' }}>
                        {!uploadStarted ? (
                            <span
                                className={`${baseClassName}__restrict_message`}
                            >
                                {t('Book.restrict')}
                            </span>
                        ) : (
                            ''
                        )}

                        <progress
                            id="progress-bar"
                            className={`${baseClassName}__progress-bar`}
                            max="100"
                            value="0"
                        ></progress>
                        <span className={`${baseClassName}__status`}>
                            {!uploadStarted
                                ? ' '
                                : progressValue < 100
                                ? t('Book.uploading')
                                : encoding
                                ? t('Book.encoding')
                                : t('Book.complete')}
                        </span>
                    </div>
                    <div>
                        {!uploadStarted ? (
                            <div>
                                <label
                                    className={`${baseClassName}__button`}
                                    htmlFor="fileElem"
                                >
                                    <div>{t('Book.select-file')}</div>
                                </label>
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(e) => handleFiles(e)}
                                    id="fileElem"
                                />
                            </div>
                        ) : progressValue < 100 || encoding ? (
                            ''
                        ) : (
                            <button
                                className={`${baseClassName}__remove_button`}
                                onClick={() => stopUploading()}
                            >
                                {t('Book.delete-offline')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

UploadPDF.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    uploadStarted: PropTypes.bool.isRequired,
    progressValue: PropTypes.number.isRequired,
    encoding: PropTypes.bool.isRequired,
    handleFiles: PropTypes.func.isRequired,
    stopUploading: PropTypes.func.isRequired,
};

export default UploadPDF;
