import React from 'react';
import PropTypes from 'prop-types';
import { isRightToLeftDirection } from '../../../util/utils';
import Favourites from '../../Favourites';
import SvgIcon from '../../SvgIcon';
import Link from '../../Link';
import { links } from '../../../lib/constants';

const VideoInfo = ({
    baseClassName,
    videoReads,
    isSimilarVideo,
    similarVideoPath,
    isLoggedIn,
    videoPath,
    videoTitleGTM,
    myLanguage,
    videoTitle,
    isFavourite,
    videoLikes,
    favouriteToggleHandler,
    isFavouriting,
    videoSlug,
}) => {
    return (
        <React.Fragment>
            <div
                className={
                    isRightToLeftDirection
                        ? '__View-like-count-arabic'
                        : '__View-like-count'
                }
            >
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__like-count-arabic`
                            : `${baseClassName}__like-count`
                    }
                >
                    <Favourites
                        isLoggedIn={isLoggedIn}
                        isFavourite={isFavourite}
                        likes={videoLikes}
                        favouriteToggleHandler={favouriteToggleHandler}
                        isFavouriting={isFavouriting}
                        videoSlug={videoSlug}
                    />
                </div>
                <div>
                    <span>
                        <SvgIcon
                            name="playIcon"
                            size="sm"
                            pushRight
                            svgIcon
                            playButton
                        />
                    </span>
                    <span>{videoReads}</span>
                </div>
            </div>
            <Link
                isInternal
                href={
                    isSimilarVideo
                        ? similarVideoPath
                        : isLoggedIn
                        ? videoPath
                        : links.fetchTabLogin('videos')
                }
                dataGTM={videoTitleGTM}
            >
                <div
                    className={
                        myLanguage === 'lo'
                            ? '__video-lo-title'
                            : isRightToLeftDirection
                            ? '__video-title  __align-right'
                            : '__video-title'
                    }
                    data-gtm={videoTitleGTM}
                >
                    {videoTitle}
                </div>
            </Link>
        </React.Fragment>
    );
};

VideoInfo.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    videoReads: PropTypes.number.isRequired,
    isSimilarVideo: PropTypes.bool,
    similarVideoPath: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    videoPath: PropTypes.string.isRequired,
    videoTitleGTM: PropTypes.string,
    myLanguage: PropTypes.string,
    videoTitle: PropTypes.string.isRequired,
};

export default VideoInfo;
