import React from 'react';
import InputFieldTitle from '../../InputFieldTitle';
import SelectField from '../../SelectField';
import TextField from '../../TextField';
import { isRightToLeftDirection } from '../../../util/utils';

const BookMetaData = ({
    t,
    baseClassName,
    copyRightYears,
    handleSelect,
    setCopyRightYear,
    copyRightYear,
    licenseTypes,
    setLicenseTypeId,
    licenseTypeId,
    onTagsInput,
    tagsData,
    tags_message,
    existingSynopsis,
    onSummaryChange,
    setSynopsis,
    synopsis,
    publishers,
    setOrganizationId,
    organizationId,
    setCopyRightHolderId,
    copyRightHolderId,
    donors,
    setDonorId,
    donorId,
    creditLine,
    setCreditLine,
}) => {
    return (
        <React.Fragment>
            <div className={`${baseClassName}_row-wrapper`}>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.copyRight')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    <SelectField
                        options={copyRightYears}
                        publicationForm
                        onChange={(e) =>
                            handleSelect('copyRightYear', e, setCopyRightYear)
                        }
                        value={copyRightYear}
                    />
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.licenceType')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    {licenseTypes.length > 0 && (
                        <SelectField
                            options={licenseTypes}
                            publicationForm
                            onChange={(e) =>
                                handleSelect(
                                    'licenseTypeId',
                                    e,
                                    setLicenseTypeId
                                )
                            }
                            value={licenseTypeId}
                        />
                    )}
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.tags')}
                        isRightToLeftDirection={isRightToLeftDirection}
                        tooltip={true}
                        tooltipData={t('PublicationForm.tooltipMessage')}
                    />
                    <div style={{ position: 'relative' }}>
                        <TextField
                            id="tags"
                            type="text"
                            placeholder={t('PublicationForm.typeHere')}
                            autoFocus={false}
                            custom={true}
                            bordered={false}
                            isPublication
                            onChange={(e) => onTagsInput(e)}
                            value={tagsData}
                        />
                        <h4
                            className={
                                isRightToLeftDirection
                                    ? 'input-field-sub-heading-rtl'
                                    : 'input-field-sub-heading'
                            }
                        >
                            {tags_message}
                        </h4>
                    </div>
                </div>
            </div>
            <div className={`${baseClassName}_row-wrapper`}>
                <div className={`${baseClassName}_summary-container`}>
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.summary')}
                        isRightToLeftDirection={isRightToLeftDirection}
                        isSubHeading={t('PublicationForm.summarySubHeading')}
                    />
                    {existingSynopsis && existingSynopsis.length > 0 && (
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_existing-synopsis-rtl`
                                    : `${baseClassName}_existing-synopsis`
                            }
                        >
                            <span style={{ fontWeight: '800' }}>
                                {t('PublicationForm.originalStorySynopsis')}
                                &nbsp;
                            </span>
                            <span>{existingSynopsis}</span>
                        </div>
                    )}
                    <div>
                        <TextField
                            id="upload-details-input"
                            placeholder={t('PublicationForm.typeHere')}
                            size="m"
                            theme="dark"
                            onChange={(e) => onSummaryChange(e, setSynopsis)}
                            value={synopsis}
                            custom={true}
                            bordered={false}
                            type="multiline"
                            rows="4"
                            isPublication
                        />
                    </div>
                </div>
            </div>
            <div className={`${baseClassName}_row-wrapper`}>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={true}
                        t={t}
                        value={t('PublicationForm.publisher')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    {publishers.length > 0 && (
                        <SelectField
                            options={publishers}
                            publicationForm
                            onChange={(e) =>
                                handleSelect(
                                    'organizationId',
                                    e,
                                    setOrganizationId
                                )
                            }
                            value={organizationId}
                        />
                    )}
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={false}
                        t={t}
                        value={t('PublicationForm.copyRightHolder')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    <SelectField
                        options={publishers}
                        publicationForm
                        onChange={(e) =>
                            handleSelect(
                                'copyRightHolderId',
                                e,
                                setCopyRightHolderId
                            )
                        }
                        value={copyRightHolderId}
                    />
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}_header-container-rtl`
                            : `${baseClassName}_header-container`
                    }
                >
                    <InputFieldTitle
                        isRequired={false}
                        t={t}
                        value={t('PublicationForm.donor')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    {donors.length > 0 && (
                        <SelectField
                            options={donors}
                            publicationForm
                            onChange={(e) =>
                                handleSelect('donorId', e, setDonorId)
                            }
                            value={donorId}
                        />
                    )}
                </div>
            </div>
            <div className={`${baseClassName}_row-wrapper`}>
                <div className={`${baseClassName}_summary-container`}>
                    <InputFieldTitle
                        isRequired={false}
                        t={t}
                        value={t('PublicationForm.dedication')}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                    <TextField
                        id="upload-details-input"
                        placeholder={t('PublicationForm.typeHere')}
                        size="m"
                        theme="dark"
                        onChange={(e) => {
                            onSummaryChange(e, setCreditLine);
                        }}
                        value={creditLine}
                        custom={true}
                        bordered={false}
                        type="multiline"
                        rows="4"
                        isPublication
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default BookMetaData;
