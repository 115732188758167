import React from 'react';
import { connect } from 'react-redux';
import { dismiss } from '../../redux/slimNotificationActions';
import Block from '../Block';
import SlimNotification from '../SlimNotification';
import './SlimNotificationContainer.scss';

const SlimNotificationContainer = ({ notifications, dismiss }) => {
    const baseClassName = 'pb-slim-notification-container';

    return (
        <div className={baseClassName}>
            <Block noHorizontalPadding noVerticalPadding>
                {notifications.map((options, index) => {
                    const defaultOptions = {
                        onDismiss: () => dismiss(index),
                    };
                    return (
                        <SlimNotification
                            key={index}
                            {...defaultOptions}
                            {...options}
                        />
                    );
                })}
            </Block>
        </div>
    );
};

// mapStateToProps function
const mapStateToProps = (state) => ({
    notifications: state.slimNotification.notifications,
});

export default connect(
    mapStateToProps,
    { dismiss }
)(SlimNotificationContainer);
