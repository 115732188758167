import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Grid';
import ManageBookCard from '../../ManageBookCard';

const ManageReadingListBody = ({ manageReadingList }) => {
    return (
        <Grid variant="4up">
            {manageReadingList &&
                manageReadingList.books &&
                manageReadingList.books.map((bookItem) => {
                    return (
                        <div className="__themedBookItem-section">
                            <ManageBookCard
                                key={bookItem.id}
                                book={bookItem}
                                manageReadingListId={manageReadingList.id}
                                isManageReadingList
                                addBookImageGTM="manage-book-image"
                                asdBookTitleGTM="manage-book-title"
                                addBookAuthorGTM="manage-book-author"
                                addBookIllustratorsGTM="manage-book-illustrator"
                                addBookLevelGTM="manage-book-level"
                                removeFromBookListGTM="remove-from-book-list"
                                addToBookListGTM="add-to-book-list"
                            />
                        </div>
                    );
                })}
        </Grid>
    );
};

ManageReadingListBody.propTypes = {
    manageReadingList: PropTypes.shape({
        id: PropTypes.string,
        books: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
            })
        ),
    }),
};

export default ManageReadingListBody;
