import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GovtApproved from '../components/GovtApproved';
import LikeAndShareRow from '../components/LikeAndShareRow';
import BookDetails from '../components/BookDetails';
import Translations from '../components/Translations';
import BookTags from '../components/BookTags';
import BookCard from '../../BookCard';
import Button from '../../Button';
import Dropdown from '../../Dropdown';
import Link from '../../Link';
import List from '../../List';
import BookRelatedCollection from '../../BookRelatedCollection';
import SvgIcon from '../../SvgIcon';
import { API_URL } from '../../../api';
import { isRightToLeftDirection, isRTLLanguage } from '../../../util/utils';
import { MAX_OFFLINE_BOOKS_COUNT } from '../../../lib/constants';
import LanguageIndicator from './LanguageIndicator';

const BookSmallScreen = ({
    t,
    baseClassName,
    title_logos,
    myLanguage,
    online,
    book,
    onReadClicked,
    slug,
    pagesCount,
    isFavourite,
    title,
    description,
    coverImage,
    titleClasses,
    level,
    isTranslation,
    isRelevelled,
    authors,
    publisher,
    illustrators,
    photographers,
    originalTitle,
    originalSlug,
    originalAuthors,
    onProfileLinkClicked,
    favouriteStat,
    favouriteStatMainBook,
    readsCount,
    viewport,
    isLoggedIn,
    approvedTitles,
    storyLevelColour,
    translators,
    edition_type,
    isOriginalStoryPresent,
    readAlouds,
    readToMeModalOpen,
    roles,
    downloadLinks,
    bookStoryDownload,
    isDownloadable,
    availableOffline,
    offlineBooksCount,
    onAddToOfflineClicked,
    onOpenModal,
    isSavingOffline,
    translations,
    isMobileWidth,
    readingListMembershipCount,
    tags,
    onMicLink,
    language,
}) => {
    return (
        <div className={`${baseClassName}__small-screen`}>
            <div className={`${baseClassName}__small-screen-cover`}>
                <div className="book-details">
                    {title_logos.length > 0 && (
                        <GovtApproved t={t} title_logos={title_logos} />
                    )}
                </div>
                <BookCard
                    myLanguage={myLanguage}
                    online={online}
                    book={book}
                    fontSize="l"
                    readStory={() => onReadClicked(slug)}
                    shouldDisplayMenu={false}
                    noSrcSet={!online}
                    landingPageBook
                    pagesCount={pagesCount}
                    enableFavouriting
                    mobileViewDetails
                    isFavourite={isFavourite}
                />
                {isRTLLanguage(language) && (
                    <div className={`${baseClassName}__arabic-header-title`}>
                        {t('Book.arabicHeaderTitle')}
                    </div>
                )}
                <LikeAndShareRow
                    t={t}
                    title={title}
                    description={description}
                    coverImage={coverImage}
                    landingPageBook
                />
            </div>
            <div className={`${baseClassName}__title-row`}>
                <div className={classNames(titleClasses)}>{title}</div>
            </div>
            {language && (
                <LanguageIndicator
                    language={language}
                    baseClassName={baseClassName}
                />
            )}
            <div className={`${baseClassName}__content`}>
                <BookDetails
                    isMobileWidth
                    level={level}
                    title={title}
                    isTranslation={isTranslation}
                    isRelevelled={isRelevelled}
                    authors={authors}
                    publisher={publisher}
                    illustrators={illustrators}
                    photographers={photographers}
                    originalTitle={originalTitle}
                    originalSlug={originalSlug}
                    originalAuthors={originalAuthors}
                    online={online}
                    t={t}
                    onProfileLinkClicked={onProfileLinkClicked}
                    favouriteStat={favouriteStat}
                    favouriteStatMainBook={favouriteStatMainBook}
                    readsCount={readsCount}
                    description={description}
                    coverImage={coverImage}
                    viewport={viewport}
                    isLoggedIn={isLoggedIn}
                    logInMsg={t('global.please-log-in', {
                        action: t('global.like', 1),
                        content_type: t('global.story', 1),
                    })}
                    bookSlug={slug}
                    approvedTitles={approvedTitles}
                    title_logos={title_logos}
                    landingPageBook
                    storyLevelColour={storyLevelColour}
                    isLandingBookPage
                    translator={translators}
                    type={edition_type}
                    isOriginalStoryPresent={isOriginalStoryPresent}
                    baseClassName={baseClassName}
                />
            </div>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}__readStory-wrapper-arabic`
                        : `${baseClassName}__readStory-wrapper`
                }
            >
                <div className={`${baseClassName}__readStory-flex-wrapper`}>
                    <div className={`${baseClassName}__read-button`}>
                        <Button
                            iconLeft="read_white"
                            buttonBlue
                            fullWidth
                            label={t('Book.read')}
                            variant="primary"
                            onClick={() => onReadClicked(slug)}
                            dataGTM="book-read-story"
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                    </div>

                    {readAlouds.length > 0 && (
                        <div className={`${baseClassName}__read-to-me-button`}>
                            <Button
                                iconLeft="video"
                                buttonBlue
                                fullWidth
                                label={t('Book.readToMe')}
                                variant="primary"
                                onClick={() => readToMeModalOpen()}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={`${baseClassName}__buttons-container `}>
                {isLoggedIn &&
                    (roles.includes('content_manager') ||
                    roles.includes('sub_admin') ||
                    roles.includes('admin') ? (
                        API_URL ===
                        'https://staging.literacycloud.org/api/v1' ? null : (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__book-download-arabic`
                                        : `${baseClassName}__book-download`
                                }
                            >
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link normal>
                                            {t('global.download', 1)}
                                        </Link>
                                    }
                                    bookTabletMode
                                >
                                    <List nowrap>
                                        {downloadLinks &&
                                            downloadLinks
                                                .slice(0, 2)
                                                .map(
                                                    ({ href, type }, index) => {
                                                        return (
                                                            <Link
                                                                parentClassName={`${baseClassName}__link`}
                                                                download
                                                                href={href}
                                                                dataGTM={
                                                                    bookStoryDownload
                                                                }
                                                            >
                                                                <div
                                                                    className="url_name"
                                                                    key={index}
                                                                >
                                                                    {type}
                                                                </div>
                                                            </Link>
                                                        );
                                                    }
                                                )}
                                    </List>
                                </Dropdown>
                            </div>
                        )
                    ) : isDownloadable ? (
                        API_URL ===
                        'https://staging.literacycloud.org/api/v1' ? null : (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__book-download-arabic`
                                        : `${baseClassName}__book-download`
                                }
                            >
                                <Link
                                    parentClassName={`${baseClassName}__link`}
                                    download
                                    href={downloadLinks[0].href}
                                    dataGTM={bookStoryDownload}
                                >
                                    <div data-gtm={bookStoryDownload}>
                                        {t('global.download', 1)}
                                    </div>
                                </Link>
                            </div>
                        )
                    ) : null)}
                {process.env.REACT_APP_FEATURE_OFFLINE && (
                    <div className={`${baseClassName}__offline-container`}>
                        <Button
                            saveOffline
                            t={t}
                            fullWidth
                            isLoggedIn={isLoggedIn}
                            availableOffline={availableOffline}
                            label={t('Book.add-to-offline')}
                            variant="white-background"
                            isRightToLeftDirection={isRightToLeftDirection}
                            dataGTM={bookStoryDownload}
                            onClickAddToOffline={() => {
                                offlineBooksCount < MAX_OFFLINE_BOOKS_COUNT
                                    ? onAddToOfflineClicked()
                                    : onOpenModal('noSpaceForMoreOfflineBooks');
                            }}
                            onClickRemoveFromOffline={() =>
                                onOpenModal('delete')
                            }
                            disabled={!('serviceWorker' in navigator)}
                            isSavingOffline={isSavingOffline}
                            bookSlug={slug}
                        ></Button>
                    </div>
                )}
            </div>
            <div className={`${baseClassName}__other-details`}>
                <div className={`${baseClassName}__wrapper-container`}>
                    <div>
                        {online &&
                        translations &&
                        translations.length &&
                        !isMobileWidth ? (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__translation-rtl`
                                        : `${baseClassName}__translation`
                                }
                            >
                                <Translations
                                    translations={translations}
                                    versionCount={book.versionCount}
                                    languageCount={book.languageCount}
                                    offline={!online}
                                    t={t}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={`${baseClassName}__wrapper-container`}>
                    {online && readingListMembershipCount > 0 && (
                        <div
                            className={`${baseClassName}__RelatedReadingListContainer`}
                        >
                            {readingListMembershipCount ? (
                                <BookRelatedCollection
                                    title={t('Book.related-collection')}
                                    book={book}
                                    bookRelatedReadingLists="related-reading-lists"
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                />
                            ) : null}
                        </div>
                    )}
                </div>
                <div className={`${baseClassName}__wrapper-container`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__cover-wrapper-rtl`
                                : `${baseClassName}__cover-wrapper`
                        }
                    >
                        {tags && tags.length > 0 && (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__BookTagsContainerRtl`
                                        : `${baseClassName}__BookTagsContainer`
                                }
                            >
                                <BookTags
                                    tags={tags}
                                    online={online}
                                    t={t}
                                    type={'bookPage'}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={[
                        isRightToLeftDirection
                            ? `${baseClassName}__cover-wrapper-rtl`
                            : `${baseClassName}__cover-wrapper`,
                        `${baseClassName}__wrapper-container`,
                    ].join(' ')}
                >
                    {(localStorage.getItem('locale') === 'vi' ||
                        localStorage.getItem('locale') === 'en') &&
                        onMicLink && (
                            <div
                                className={`${baseClassName}__audio-link-container`}
                            >
                                <div
                                    className={`${baseClassName}__audio-link-container-title`}
                                >
                                    {t('book.audioLinkTitle')}
                                </div>
                                <Link
                                    isExternal={true}
                                    audioLink
                                    href={onMicLink}
                                >
                                    <SvgIcon
                                        name="onMicIcon"
                                        size="onmic-icon"
                                    />
                                </Link>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

BookSmallScreen.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    title_logos: PropTypes.arrayOf(PropTypes.object),
    myLanguage: PropTypes.string,
    online: PropTypes.bool,
    book: PropTypes.object,
    onReadClicked: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    pagesCount: PropTypes.number,
    isFavourite: PropTypes.bool,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    coverImage: PropTypes.string,
    titleClasses: PropTypes.string,
    level: PropTypes.string,
    isTranslation: PropTypes.bool,
    isRelevelled: PropTypes.bool,
    authors: PropTypes.arrayOf(PropTypes.string),
    publisher: PropTypes.string,
    illustrators: PropTypes.arrayOf(PropTypes.string),
    photographers: PropTypes.arrayOf(PropTypes.string),
    originalTitle: PropTypes.string,
    originalSlug: PropTypes.string,
    originalAuthors: PropTypes.arrayOf(PropTypes.string),
    onProfileLinkClicked: PropTypes.func,
    favouriteStat: PropTypes.number,
    favouriteStatMainBook: PropTypes.number,
    readsCount: PropTypes.number,
    viewport: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    approvedTitles: PropTypes.arrayOf(PropTypes.string),
    storyLevelColour: PropTypes.string,
    translators: PropTypes.arrayOf(PropTypes.string),
    edition_type: PropTypes.string,
    isOriginalStoryPresent: PropTypes.bool,
    readAlouds: PropTypes.arrayOf(PropTypes.object),
    readToMeModalOpen: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    downloadLinks: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })
    ),
    bookStoryDownload: PropTypes.string,
    isDownloadable: PropTypes.bool,
    availableOffline: PropTypes.bool,
    offlineBooksCount: PropTypes.number,
    onAddToOfflineClicked: PropTypes.func,
    onOpenModal: PropTypes.func,
    isSavingOffline: PropTypes.bool,
    translations: PropTypes.arrayOf(PropTypes.object),
    isMobileWidth: PropTypes.bool,
    readingListMembershipCount: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    onMicLink: PropTypes.string,
};

export default BookSmallScreen;
