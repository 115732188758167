import React from 'react';
import PropTypes from 'prop-types';
import Tab from '../../Tab';
import BlankSlate from '../../BlankSlate';

const UserList = ({ profile, isCurrentUser, t, baseClassName, title, url }) => {
    return !Boolean(profile.lists.meta.hits) && !isCurrentUser ? null : (
        <Tab
            key="user-lists"
            title={`${t('global.my-bookshelf')} (${profile.lists.meta.hits})`}
            hasContent={Boolean(profile.lists.meta.hits)}
        >
            {Boolean(profile.lists.meta.hits) ? (
                <div className={`${baseClassName}__lists`}>
                    {/* <ReadingLists lists={profile.lists.results} /> */}
                </div>
            ) : (
                <BlankSlate title={title} url={url} />
            )}
        </Tab>
    );
};

UserList.propTypes = {
    profile: PropTypes.shape({
        lists: PropTypes.shape({
            meta: PropTypes.shape({
                hits: PropTypes.number.isRequired,
            }).isRequired,
            results: PropTypes.array.isRequired,
        }).isRequired,
    }).isRequired,
    isCurrentUser: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default UserList;
