import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import { closeMenu } from '../../redux/menuToggleActions';
import './UserInformation.scss';
import UserInfoHeaderDropDown from './components/UserInfoHeaderDropDown';
import DropDownContent from './components/DropDownContent';
import MobileHandler from './components/MobileHandler';
import UserInfoHeaderExpanded from './components/UserInfoHeaderExpanded';

const UserInformation = ({
    parentClassName,
    title,
    avatar,
    caret,
    logoutHandler,
    shouldHide = false,
    roles,
    userEmail,
    mobileMenu = false,
    t,
    variant,
}) => {
    const baseClassName = 'pb-user-information';

    const classes = {
        [`${baseClassName}--should-hide`]: shouldHide,
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const menuClasses = {
        [`${baseClassName}--menu-mobile`]: mobileMenu,
        [`${baseClassName}--menu`]: true,
        [parentClassName]: parentClassName,
    };

    const menuCloseHandler = () => {
        closeMenu();
    };

    const dropDownHeaderEl = mobileMenu ? (
        <MobileHandler
            mobileMenu={mobileMenu}
            title={title}
            avatar={avatar}
            baseClassName={baseClassName}
        />
    ) : (
        <UserInfoHeaderExpanded
            mobileMenu={mobileMenu}
            baseClassName={baseClassName}
            menuClasses={menuClasses}
            menuCloseHandler={menuCloseHandler}
            title={title}
            avatar={avatar}
            caret={caret}
        />
    );

    return (
        <div>
            {mobileMenu ? (
                <DropDownContent
                    userEmail={userEmail}
                    baseClassName={baseClassName}
                    t={t}
                    mobileMenu={mobileMenu}
                    roles={roles}
                    dropDownHeaderEl={dropDownHeaderEl}
                    logoutHandler={logoutHandler}
                />
            ) : (
                <Dropdown
                    align={'right'}
                    menu
                    toggleEl={
                        <UserInfoHeaderDropDown
                            classes={classes}
                            baseClassName={baseClassName}
                            title={title}
                            avatar={avatar}
                            caret={caret}
                            variant={variant}
                        />
                    }
                >
                    <DropDownContent
                        userEmail={userEmail}
                        baseClassName={baseClassName}
                        t={t}
                        mobileMenu={mobileMenu}
                        roles={roles}
                        dropDownHeaderEl={dropDownHeaderEl}
                        logoutHandler={logoutHandler}
                    />
                </Dropdown>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    closeMenu,
};

UserInformation.propTypes = {
    parentClassName: PropTypes.string,
    title: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    caret: PropTypes.bool.isRequired,
    logoutHandler: PropTypes.func.isRequired,
    shouldHide: PropTypes.bool,
    roles: PropTypes.array.isRequired,
    userEmail: PropTypes.string.isRequired,
    mobileMenu: PropTypes.bool,
    t: PropTypes.func.isRequired,
    variant: PropTypes.string,
};

export default translate()(connect(null, mapDispatchToProps)(UserInformation));
