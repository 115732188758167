import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import {
    fetchAllVideosFiltersWorkflow,
    fetchVideosWorkflow,
    videoReadCountWorkflow,
} from '../../redux/videoActions';
import Grid from '../Grid';
import LoaderBlock from '../LoaderBlock';
import VideoCard from '../VideoCard';
import { isRightToLeftDirection } from '../../util/utils';
import './VideoCardContainer.scss';

const mapDispatchToProps = {
    fetchAllVideosFiltersWorkflow,
    fetchVideosWorkflow,
};

const mapStateToProps = ({
    videos,
    viewport,
    user: { profile },
    allFilters: { readFilters },
}) => ({
    ...videos,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    name: profile.name,
});

const VideoCardContainer = ({
    parentClassName,
    Videos,
    isFetchingVideos,
    totalVideosCount,
    myLanguage,
    isLoggedIn,
    viewport,
    t,
}) => {
    const baseClassName = 'pb-video-card-container';

    const classes = classNames({
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    });

    const NoResults = translate()(({ t }) => (
        <span>
            <p>{t('Books.no-result-warning-1')}</p>
            <p>{t('Books.no-result-warning-2')}</p>
            <ul
                style={
                    localStorage.getItem('locale') === 'ar' ||
                    localStorage.getItem('locale') === 'ur'
                        ? { paddingLeft: '0', paddingRight: '0' }
                        : null
                }
            >
                <li>{t('Books.no-result-warning-3')}</li>
                <li>{t('Books.no-result-warning-4')}</li>
                <li>{t('Books.no-result-warning-5')}</li>
                <li>{t('Books.no-result-warning-6')}</li>
            </ul>
        </span>
    ));

    return (
        <div className={classNames(classes)}>
            {isFetchingVideos || !Videos ? (
                <LoaderBlock />
            ) : (
                <div>
                    {Videos.length > 0 ? (
                        <Grid variant="4up">
                            {Videos.map((videoItem) => {
                                return (
                                    <div key={videoItem.id}>
                                        <VideoCard
                                            myLanguage={myLanguage}
                                            videoID={videoItem.id}
                                            videoSlug={videoItem.slug}
                                            videoTitle={videoItem.name}
                                            videoThumbnail={videoItem.thumbUrl}
                                            videoUrl={videoItem.materialUrl}
                                            videoDownloadUrl={
                                                videoItem.downloadUrl
                                            }
                                            videoLikes={videoItem.likesCount}
                                            videoReads={videoItem.readsCount}
                                            videoTime={videoItem.duration}
                                            isFavourite={videoItem.is_favourite}
                                            readCount={videoReadCountWorkflow}
                                            videoTitleGTM="videos-video-title"
                                            videoThumbnailGTM="videos-video-thumbnail"
                                            isLoggedIn={isLoggedIn}
                                            youtubeIdentifier={
                                                videoItem.youtube_video_identifier
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                    ) : null}
                    <div
                        className={
                            isRightToLeftDirection
                                ? '__no-result-section-rtl'
                                : '__no-result-section'
                        }
                    >
                        {totalVideosCount ? null : <NoResults />}
                    </div>
                </div>
            )}
        </div>
    );
};

VideoCardContainer.propTypes = {
    parentClassName: PropTypes.string,
    Videos: PropTypes.array,
    isFetchingVideos: PropTypes.bool,
    totalVideosCount: PropTypes.number,
    myLanguage: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(VideoCardContainer)
);
