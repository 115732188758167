import React from 'react';
import SectionBlock from '../SectionBlock';
import VideoShelf from '../VideoShelf';

const SimilarVideos = ({ offline, similarVideos, t, viewport, myLanguage }) => {
    if (offline || !similarVideos || !similarVideos.length) {
        return null;
    }

    return (
        <SectionBlock
            background="transparent"
            title={t('Video.related-videos')}
            videoDetails
        >
            <VideoShelf
                myLanguage={myLanguage}
                videos={similarVideos}
                viewport={viewport}
                offline={offline}
                isSimilarReadAloud
            />
        </SectionBlock>
    );
};

export default SimilarVideos;
