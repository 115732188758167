import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../Button';
import { isRightToLeftDirection } from '../../../../../util/utils';

const TranslatorsTable = ({
    t,
    baseClassName,
    offlineTranslators,
    onRemoveTranslator,
}) => {
    return (
        <table>
            <tr
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}_table-header-rtl`
                        : `${baseClassName}_table-header`
                }
            >
                <th>{t('PublicationForm.authorMail')}</th>
                <th>{t('PublicationForm.authorFirstName')}</th>
                <th>{t('PublicationForm.authorLastName')}</th>
                <th>{t('PublicationForm.authorBio')}</th>
                <th>{t('PublicationForm.actions')}</th>
            </tr>
            {offlineTranslators.length >= 1 &&
                offlineTranslators.map((translator, index) => {
                    return (
                        <tr
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_table-body-rtl`
                                    : `${baseClassName}_table-body`
                            }
                        >
                            <td>{translator.email}</td>
                            <td>{translator.first_name}</td>
                            <td>{translator.last_name}</td>
                            <td>{translator.bio}</td>
                            <td>
                                <Button
                                    label={t('PublicationForm.remove')}
                                    variant="remove"
                                    onClick={() =>
                                        onRemoveTranslator(index, translator.id)
                                    }
                                />
                            </td>
                        </tr>
                    );
                })}
        </table>
    );
};

TranslatorsTable.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    offlineTranslators: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string.isRequired,
            first_name: PropTypes.string.isRequired,
            last_name: PropTypes.string.isRequired,
            bio: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        })
    ).isRequired,
    onRemoveTranslator: PropTypes.func.isRequired,
};

export default TranslatorsTable;
