import React from 'react';
import PropTypes from 'prop-types';
import DynamicBookDetails from '../../DynamicBookDetails';
import { isRightToLeftDirection } from '../../../util/utils';

const BookPreviewCarousel = ({ t, baseClassName, bookLength, startIndex }) => {
    return (
        <div className={`${baseClassName}__carousal_position`}>
            <div>
                <span
                    className={
                        `${baseClassName}__title ` +
                        (isRightToLeftDirection
                            ? `${baseClassName}__mirror`
                            : '')
                    }
                >
                    {t('Book.check')}
                </span>
            </div>
            <div className={`${baseClassName}__preview_wrapper`}>
                {bookLength.slice(startIndex - 1, startIndex + 1).map((e) => (
                    <div className={`${baseClassName}__verticalhorizontal`}>
                        {e.url !== undefined ? (
                            <img
                                src={e.url}
                                alt="preview_img"
                                className={`${baseClassName}__center_image`}
                            />
                        ) : (
                            <DynamicBookDetails
                                title={e.title}
                                level={e.reading_level}
                                synopsis={e.synopsis}
                                publisher={e.publishers}
                                levelColour={e.story_level_colour}
                                authors={e.authors}
                                illustrators={e.illustrators}
                                translators={e.translators}
                                dynamicWrapperClass={`${baseClassName}__dynamic_wrapper`}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

BookPreviewCarousel.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    bookLength: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string.isRequired,
            reading_level: PropTypes.string.isRequired,
            synopsis: PropTypes.string.isRequired,
            publishers: PropTypes.object.isRequired,
            story_level_colour: PropTypes.string.isRequired,
            authors: PropTypes.array.isRequired,
            illustrators: PropTypes.array.isRequired,
            translators: PropTypes.array.isRequired,
        })
    ).isRequired,
    startIndex: PropTypes.number.isRequired,
};
export default BookPreviewCarousel;
