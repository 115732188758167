import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
import './Pill.scss';

const Pill = ({
    label,
    onClose,
    icon,
    parentClassName,
    href,
    isInternal,
    bookDetails,
    bookTagsGTM,
}) => {
    const baseClassName = 'pb-pill';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--dismissable`]: onClose,
        [`${baseClassName}--with-icon`]: icon,
        [`${baseClassName}--link`]: href,
        [`${baseClassName}--book-details`]: bookDetails,
        [parentClassName]: parentClassName,
    };

    const parentElements = {
        span: 'span',
        link: Link,
    };

    const Parent = parentElements[href ? 'link' : 'span'];

    return (
        <Parent
            className={!href && classNames(classes)}
            parentClassName={href && classNames(classes)}
            isInternal={isInternal}
            href={href}
            tags={true}
        >
            {icon && (
                <SvgIcon
                    parentClassName={`${baseClassName}__icon`}
                    name={icon}
                    size="m"
                />
            )}
            <span className={`${baseClassName}__label`}>{label}</span>
            {!href && onClose && (
                <Link
                    parentClassName={`${baseClassName}__link`}
                    onClick={onClose}
                    dataGTM={bookTagsGTM}
                >
                    <SvgIcon
                        name="close-circle"
                        size="m"
                        dataGTM={bookTagsGTM}
                    />
                </Link>
            )}
        </Parent>
    );
};

Pill.propTypes = {
    label: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    icon: PropTypes.string,
    parentClassName: PropTypes.string,
    href: PropTypes.string,
    isInternal: PropTypes.bool,
    bookDetails: PropTypes.bool,
    bookTagsGTM: PropTypes.object,
};

export default Pill;
