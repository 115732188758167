import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import DocumentTitle from 'react-document-title';
import { profileTypes } from '../../lib/constants';
import Avatar from '../Avatar';
import Block from '../Block';
import { MediaObject, Media } from '../MediaObject';
import ContentStyler from '../ContentStyler';
import './Profile.scss';
import onEmptyBgImage from '../../assets/watering-can.svg';
import onEmptyBgImageSelf from '../../assets/hand-with-pencil.svg';
import Email from './components/Email';
import Facebook from './components/Facebook';
import Twitter from './components/Twitter';
import Youtube from './components/Youtube';
import Website from './components/Website';
import Tabs from './components/Tabs';

const Profile = ({
    t,
    name,
    type,
    profile,
    isCurrentUser,
    hitsCount,
    filters,
    appliedFilters,
    applyFilter,
    removeFilter,
    isFetchingPublisherProfile,
}) => {
    const baseClassName = 'pb-profile';
    const classNames = [baseClassName];

    const { email, website, socialMediaLinks } = profile;

    const { facebookUrl, twitterUrl, youtubeUrl } = socialMediaLinks;

    const myLanguage = localStorage.getItem('locale');

    let url, title;
    if (isCurrentUser) {
        url = onEmptyBgImageSelf;
        title = t('Profile.empty-content-self');
    } else {
        url = onEmptyBgImage;
        title = t('Profile.empty-content');
    }

    return (
        <div className={classNames.join(' ')}>
            <DocumentTitle
                title={`${profile.name} - ${t('global.site-title')}`}
            />
            <MediaObject>
                <Block offlineSection normalPadding>
                    <ContentStyler offline>
                        <div className="__offline-title-header">
                            <Media>
                                <div
                                    className={`${baseClassName}__avatar-title`}
                                >
                                    <div className={`${baseClassName}__avatar`}>
                                        <Avatar
                                            url={profile.profileImage}
                                            name={profile.name}
                                            website={website}
                                            variant={
                                                type === profileTypes.USER
                                                    ? 'circular'
                                                    : 'default'
                                            }
                                            size="l"
                                        />
                                    </div>
                                    <div className={`${baseClassName}__name`}>
                                        <h3 className="__profile-contact-header">
                                            {' '}
                                            {profile.name}
                                        </h3>
                                        <div className="__profile-contact-details">
                                            <div className="__profile-email-field">
                                                <Email email={email} />
                                            </div>
                                            <div className="__profile-facebook-field">
                                                <Facebook
                                                    facebookUrl={facebookUrl}
                                                />
                                            </div>
                                            <div className="__profile-twitter-field">
                                                <Twitter
                                                    twitterUrl={twitterUrl}
                                                />
                                            </div>
                                            <div className="__profile-youtube-field">
                                                <Youtube
                                                    youtubeUrl={youtubeUrl}
                                                />
                                            </div>
                                            <div className="__profile-website-field">
                                                <Website website={website} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Media>
                        </div>
                    </ContentStyler>
                </Block>

                {profile.description && (
                    <div className={`${baseClassName}__description`}>
                        {profile.description}
                    </div>
                )}
            </MediaObject>
            <Tabs
                title={title}
                url={url}
                type={type}
                profile={profile}
                isCurrentUser={isCurrentUser}
                t={t}
                baseClassName={baseClassName}
                hitsCount={hitsCount}
                filters={filters}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                appliedFilters={appliedFilters}
                isFetchingPublisherProfile={isFetchingPublisherProfile}
                myLanguage={myLanguage}
            />
        </div>
    );
};

Profile.propTypes = {
    t: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    profile: PropTypes.shape({
        name: PropTypes.string.isRequired,
        email: PropTypes.string,
        website: PropTypes.string,
        socialMediaLinks: PropTypes.shape({
            facebookUrl: PropTypes.string,
            twitterUrl: PropTypes.string,
            youtubeUrl: PropTypes.string,
        }),
        profileImage: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    isCurrentUser: PropTypes.bool.isRequired,
    hitsCount: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isFetchingPublisherProfile: PropTypes.bool.isRequired,
};

export default translate()(Profile);
