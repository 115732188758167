import React from 'react';
import PropTypes from 'prop-types';
import List from '../../List';
import Link from '../../Link';
import TitleImage from '../../TitleImage';
import { links } from '../../../lib/constants';
import userProfile from '../../../assets/Profile.svg';
import FavoriteRR from '../../../assets/My_Favorites.svg';
import ContentMangerElements from './ContentMangerElements';
import SubAdminEls from './SubAdminEls';
import bookshelf from '../../../assets/Offline_Library.svg';
import signout from '../../../assets/Logout.svg';
import { isRightToLeftDirection } from '../../../util/utils';

const DropDownContent = ({
    userEmail,
    baseClassName,
    t,
    mobileMenu,
    roles,
    dropDownHeaderEl,
    logoutHandler,
}) => {
    const contentManagerEls =
        roles.includes('content_manager') ||
        roles.includes('sub_admin') ||
        roles.includes('admin') ? (
            <ContentMangerElements
                userEmail={userEmail}
                baseClassName={baseClassName}
                t={t}
                mobileMenu={mobileMenu}
                roles={roles}
            />
        ) : null;

    return (
        <List noPadding>
            {dropDownHeaderEl}
            <Link
                fullWidth
                href={links.profileDashboard()}
                noPadding
                dataGTM={userEmail + '-profile'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={userProfile}
                    title={t('global.profile', 1)}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-profile'}
                />
                {!mobileMenu && (
                    <div
                        className={`${baseClassName}--separator`}
                        data-gtm={userEmail}
                    />
                )}
            </Link>
            {contentManagerEls !== null ? (
                <div>{contentManagerEls}</div>
            ) : (
                <div>
                    <SubAdminEls
                        roles={roles}
                        userEmail={userEmail}
                        baseClassName={baseClassName}
                        t={t}
                        mobileMenu={mobileMenu}
                    />
                </div>
            )}

            <Link
                fullWidth
                href={links.myFavourites()}
                noPadding
                dataGTM={userEmail + '-my-favorites'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={FavoriteRR}
                    title={t('global.my-favorites', 1)}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-my-favorites'}
                />
                {mobileMenu ? null : (
                    <div
                        className={`${baseClassName}--separator`}
                        data-gtm={userEmail + '-my-favorites'}
                    />
                )}
            </Link>
            <Link
                fullWidth
                href={links.offlineBooks()}
                noPadding
                dataGTM={userEmail + '-my-offline-library'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={bookshelf}
                    title={t('global.my-offline-library', 1)}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-my-offline-library'}
                />
                {mobileMenu ? null : (
                    <div
                        className={`${baseClassName}--separator`}
                        data-gtm={userEmail + '-my-offline-library'}
                    />
                )}
            </Link>
            <Link
                fullWidth
                onClick={logoutHandler}
                noPadding
                dataGTM={userEmail + '-sign-out'}
            >
                <TitleImage
                    className={`${baseClassName}--menu-icon`}
                    svgName={signout}
                    title={t('global.sign-out', 1)}
                    mobileMenu={mobileMenu}
                    dataGTM={userEmail + '-sign-out'}
                />
            </Link>
            {mobileMenu ? (
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}--padding-right ${baseClassName}--margin-top`
                            : `${baseClassName}--padding-left ${baseClassName}--margin-top`
                    }
                    data-gtm={userEmail + '-sign-out'}
                >
                    <div
                        className={`${baseClassName}--separator-menu`}
                        data-gtm={userEmail + '-sign-out'}
                    />
                </div>
            ) : null}
        </List>
    );
};

DropDownContent.propTypes = {
    userEmail: PropTypes.string.isRequired,
    baseClassName: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    mobileMenu: PropTypes.bool.isRequired,
    roles: PropTypes.array.isRequired,
    dropDownHeaderEl: PropTypes.node.isRequired,
    logoutHandler: PropTypes.func.isRequired,
};

export default DropDownContent;
