import React from 'react';
import PropTypes from 'prop-types';

const Tabs = ({
    t,
    selectedTab,
    tabSelectHandler,
    booksCount,
    updatedVideosCount,
    updatedResourcesCount,
}) => {
    const booksTabText = `${t('Book.book-text')} (${booksCount})`;
    const videosTabText = `${t('global.video', 2)} (${updatedVideosCount})`;
    const resourcesTabText = `${t(
        'global.resource',
        2
    )} (${updatedResourcesCount})`;

    return (
        <div className="__my-favorite-navbar-section">
            <div className="__my-favorite-navbar">
                {[
                    <div
                        className={
                            selectedTab === 'books'
                                ? '__underlineStyle'
                                : '__inner-navbar'
                        }
                        onClick={() => tabSelectHandler('books')}
                    >
                        {booksTabText}
                    </div>,
                    <div
                        className={
                            selectedTab === 'videos'
                                ? '__underlineStyle'
                                : '__inner-navbar'
                        }
                        onClick={() => tabSelectHandler('videos')}
                    >
                        {videosTabText}
                    </div>,
                    <div
                        className={
                            selectedTab === 'resources'
                                ? '__underlineStyle'
                                : '__inner-navbar'
                        }
                        onClick={() => tabSelectHandler('resources')}
                    >
                        {resourcesTabText}
                    </div>,
                ]}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    t: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired,
    tabSelectHandler: PropTypes.func.isRequired,
    booksCount: PropTypes.number.isRequired,
    updatedVideosCount: PropTypes.number.isRequired,
    updatedResourcesCount: PropTypes.number.isRequired,
};

export default Tabs;
