import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';

const ZeroFavourites = ({
    t,
    selectedTab,
    booksCount,
    isFetchingData,
    updatedVideosCount,
    updatedResourcesCount,
}) => {
    let href = '';
    let text = '';

    if (selectedTab === 'books' && booksCount <= 0 && !isFetchingData) {
        href = '/stories?sort=Relevance';
        text = 'MyFavouritesContainer.addAFavouriteBook';
    } else if (
        selectedTab === 'videos' &&
        updatedVideosCount <= 0 &&
        !isFetchingData
    ) {
        href = '/videos';
        text = 'MyFavouritesContainer.addAFavouriteVideo';
    } else if (
        selectedTab === 'resources' &&
        updatedResourcesCount <= 0 &&
        !isFetchingData
    ) {
        href = '/resources';
        text = 'MyFavouritesContainer.addAFavouriteResource';
    } else {
        return null;
    }

    return (
        <div className="pb-my-favourites__zero-fav">
            <Link href={href}>{t(text)}</Link>
        </div>
    );
};

ZeroFavourites.propTypes = {
    t: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired,
    booksCount: PropTypes.number.isRequired,
    isFetchingData: PropTypes.bool.isRequired,
    updatedVideosCount: PropTypes.number.isRequired,
    updatedResourcesCount: PropTypes.number.isRequired,
};

export default ZeroFavourites;
