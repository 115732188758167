import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import './ReadAloudsDetail.scss';
import BookDetailSummary from '../BookDetailSummary';
import Comments from '../Comments';
import SimilarVideos from './SimilarVideos';
import { favouriteReadAloudsWorkflow } from '../../redux/readAloudsActions';
import DocumentHeader from '../DocumentHeader';
import IframeContainer from '../IframeContainer/IframeContainer';
import { isRightToLeftDirection } from '../../util/utils';
import { helpGuideSteps } from './constants/constants';
import VideoThumbnail from './components/VideoThumbnail';
import StatusShare from './components/StatusShare';
import VideoButton from './components/VideoButton';
import VideoPlayerModal from './components/VideoPlayerModal';

const ReadAloudsDetail = ({
    readsCount: readsCountProp,
    isFavourite: isFavouriteProp,
    readCount,
    videoId,
    favouriteReadAloudsWorkflow,
    videosVideoCloseGTM,
    videoDetail,
    videoUrl,
    t,
    parentClassName,
    online,
    isLoggedIn,
    logInMsg,
    viewport,
    videosVideoThumbnailGTM,
    videosVideoDownloadGTM,
    videosVideoFavoriteGTM,
    myLanguage,
    roles,
    helpStatus,
}) => {
    const {
        id,
        slug,
        thumbUrl,
        name,
        summary,
        likesCount: likesCountProp,
        duration,
        similar_videos,
        dpi_url,
        isDownloadable,
        youtube_video_identifier,
    } = videoDetail;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isVideoPlay, setIsVideoPlay] = useState(false);
    const [readsCount, setReadsCount] = useState(readsCountProp);
    const [likesCount, setLikesCount] = useState(likesCountProp);
    const [isFavourite, setIsFavourite] = useState(isFavouriteProp);

    const baseClassName = 'pb-readAloud-detail';
    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const videoModalOpen = () => {
        setIsModalVisible(true);
        setIsVideoPlay(true);
        setReadsCount((prev) => prev + 1);
        readCount(videoId);
    };

    const onFavoriteClicked = (videoId) => {
        favouriteReadAloudsWorkflow(videoId);
        if (isFavourite) {
            setLikesCount((prev) => prev - 1);
            setIsFavourite(false);
        } else {
            setLikesCount((prev) => prev + 1);
            setIsFavourite(true);
        }
    };

    const videoModalClose = () => {
        setIsModalVisible(false);
        setIsVideoPlay(false);
    };
    return (
        <div className={classNames(classes)}>
            <DocumentHeader
                title={name}
                description={summary}
                keywords={slug}
                imageUrl={thumbUrl}
                imgAlt={name}
            />
            <Joyride
                enabled={true}
                steps={helpGuideSteps(t)}
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={250}
                disableScrolling={false}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <DocumentTitle title={`${name} - ${t('global.site-title')}`} />
            <div className="__video-detail-section">
                {!youtube_video_identifier && (
                    <VideoThumbnail
                        videoModalOpen={videoModalOpen}
                        videosVideoThumbnailGTM={videosVideoThumbnailGTM}
                        thumbUrl={thumbUrl}
                        duration={duration}
                    />
                )}

                <div
                    className={
                        youtube_video_identifier
                            ? '__video-detail'
                            : '__video-detail-right'
                    }
                >
                    <div className="__video-detail-right-content">
                        <div
                            className={
                                myLanguage === 'lo'
                                    ? '__video-detail-lo-title'
                                    : isRightToLeftDirection
                                    ? '__video-detail-title __text-align-right'
                                    : '__video-detail-title'
                            }
                        >
                            {name}
                        </div>

                        {youtube_video_identifier && (
                            <IframeContainer
                                title={name}
                                videoId={youtube_video_identifier}
                            />
                        )}

                        <StatusShare
                            baseClassName={baseClassName}
                            isLoggedIn={isLoggedIn}
                            likesCount={likesCount}
                            online={online}
                            parentClassName={parentClassName}
                            onFavoriteClicked={onFavoriteClicked}
                            id={id}
                            videosVideoFavoriteGTM={videosVideoFavoriteGTM}
                            isFavourite={isFavourite}
                            logInMsg={logInMsg}
                            slug={slug}
                            t={t}
                            readsCount={readsCount}
                        />

                        <div className="__video-detail-desc">
                            <BookDetailSummary
                                title={t('Book.summary')}
                                description={summary}
                                hideSummary
                            />
                        </div>

                        {!youtube_video_identifier && (
                            <VideoButton
                                t={t}
                                videoModalOpen={videoModalOpen}
                                isDownloadable={isDownloadable}
                                videosVideoDownloadGTM={videosVideoDownloadGTM}
                                dpi_url={dpi_url}
                            />
                        )}
                    </div>
                </div>
            </div>
            {
                <div className={`${baseClassName}__disqus-section`}>
                    <div className="__disqussion">
                        <Comments
                            viewport={viewport}
                            source="readAloud"
                            id={id}
                            roles={roles}
                            isLoggedIn={isLoggedIn}
                        />
                    </div>
                </div>
            }
            <div>
                <SimilarVideos
                    myLanguage={myLanguage}
                    offline={!online}
                    similarVideos={similar_videos}
                    t={t}
                    viewport={viewport}
                />
            </div>
            {isModalVisible && (
                <VideoPlayerModal
                    videoModalClose={videoModalClose}
                    videosVideoCloseGTM={videosVideoCloseGTM}
                    videoDetail={videoDetail}
                    videoUrl={videoUrl}
                    isVideoPlay={isVideoPlay}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        helpStatus: state.help.helpStatus,
        roles: state.user.profile.roles,
    };
};

const mapDispatchToProps = {
    favouriteReadAloudsWorkflow,
};

ReadAloudsDetail.propTypes = {
    readsCount: PropTypes.number,
    isFavourite: PropTypes.bool,
    readCount: PropTypes.func.isRequired,
    videoId: PropTypes.string,
    favouriteReadAloudsWorkflow: PropTypes.func.isRequired,
    videosVideoCloseGTM: PropTypes.func,
    videoDetail: PropTypes.object.isRequired,
    videoUrl: PropTypes.string,
    t: PropTypes.func.isRequired,
    parentClassName: PropTypes.string,
    online: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    logInMsg: PropTypes.string,
    viewport: PropTypes.object,
    videosVideoThumbnailGTM: PropTypes.func,
    videosVideoDownloadGTM: PropTypes.func,
    videosVideoFavoriteGTM: PropTypes.func,
    myLanguage: PropTypes.string,
    roles: PropTypes.array,
    helpStatus: PropTypes.bool,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(ReadAloudsDetail)
);
