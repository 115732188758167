import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import { isRightToLeftDirection } from '../../../util/utils';

const BookAuthors = ({ t, baseClassName, authors, addBookAuthorGTM }) => {
    return (
        <div className={`${baseClassName}__book-author`}>
            {!isRightToLeftDirection && (
                <span className={`${baseClassName}__author-by`}>
                    {t('global.author') + ' '}
                </span>
            )}
            {authors &&
                authors.map((item, i) => (
                    <span key={i} className={`${baseClassName}__author-name`}>
                        <Link
                            href={'/users/' + item.slug}
                            isInternal
                            dataGTM={addBookAuthorGTM}
                        >
                            {item.name}
                            <span data-gtm={addBookAuthorGTM}>
                                {i < authors.length - 1 ? ', ' : ''}
                            </span>
                        </Link>
                    </span>
                ))}
            {isRightToLeftDirection && (
                <span className={`${baseClassName}__author-by`}>
                    {' ' + t('global.author')}
                </span>
            )}
        </div>
    );
};

BookAuthors.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    addBookAuthorGTM: PropTypes.string.isRequired,
};
export default BookAuthors;
