import React from 'react';
import Modal from '../Modal';
import Header from './components/Header';

const DetectIE = () => {
    return (
        <Modal contentPadding header={() => <Header />}>
            {navigator.language === 'id' ? (
                <p>
                    Internet explorer anda tidak mendukung sehingga beberapa
                    fitur dalam pelantar tidak dapat berfungsi dengan baik.
                    Mohon gunakan peramban lain seperti Chrome, Firefox atau
                    Safari.
                </p>
            ) : (
                <p>
                    Internet Explorer is not supported and some platform
                    functionality may not be stable , Please switch to other
                    browsers like Chrome, FireFox or Safari.
                </p>
            )}
        </Modal>
    );
};

export default DetectIE;
