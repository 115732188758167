import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BlankSlate.scss';
import GraphicEl from './components/GraphicEl';

const BlankSlate = ({ title, children, icon, image, url }) => {
    const baseClassName = 'pb-blank-slate';

    const classes = {
        [baseClassName]: true,
    };

    return (
        <div className={classNames(classes)}>
            <GraphicEl
                icon={icon}
                image={image}
                url={url}
                title={title}
                baseClassName={baseClassName}
            />
            {title && <h2 className={`${baseClassName}__title`}>{title}</h2>}
            <div className={`${baseClassName}__content`}>{children}</div>
        </div>
    );
};

BlankSlate.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
};

export default BlankSlate;
