import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import HeartIcon from './HeartIcon';

const FavouriteStat = ({
    likesCount,
    online,
    parentClassName,
    isLoggedIn,
    onFavoriteClicked,
    id,
    videosVideoFavoriteGTM,
    isFavourite,
}) => {
    return (
        <React.Fragment>
            {likesCount >= 0 && online && (
                <div className={parentClassName}>
                    <Link
                        normal
                        favouriteShare
                        onClick={
                            isLoggedIn ? () => onFavoriteClicked(id) : null
                        }
                        dataGTM={videosVideoFavoriteGTM}
                    >
                        <HeartIcon isFavourite={isFavourite} />
                        {isFavourite}
                        <span style={{ marginLeft: '3px', fontSize: '1rem' }}>
                            {likesCount}
                        </span>
                    </Link>
                </div>
            )}
        </React.Fragment>
    );
};

FavouriteStat.propTypes = {
    likesCount: PropTypes.number.isRequired,
    online: PropTypes.bool.isRequired,
    parentClassName: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    videosVideoFavoriteGTM: PropTypes.string.isRequired,
    isFavourite: PropTypes.bool.isRequired,
};

export default FavouriteStat;
