import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import Books from '../../Books';
import BlankSlate from '../../BlankSlate';

const UserBooks = ({ t, profile, isCurrentUser, title, url }) => {
    return (
        <React.Fragment>
            {!Boolean(profile.books.meta.hits) && !isCurrentUser ? null : (
                <Block normalPadding>
                    <ContentStyler>
                        <div className="flex-property">
                            <h3>{t('global.book', 2)}</h3>
                            <h3 className="__normal">
                                {' '}
                                ({profile.books.meta.hits})
                            </h3>
                        </div>
                    </ContentStyler>
                    {Boolean(profile.books.meta.hits) ? (
                        <Books
                            myLanguage
                            isProfilePage
                            type="profileBooks"
                            books={profile.books.results}
                            bookImageGTM="profile-book-image"
                            bookTitleGTM="profile-book-title"
                            bookAuthorGTM="profile-book-author"
                            bookIllustratorsGTM="profile-book-illustrators"
                            bookLevelGTM="profile-book-level"
                        />
                    ) : (
                        <BlankSlate title={title} url={url} />
                    )}
                </Block>
            )}
        </React.Fragment>
    );
};

UserBooks.propTypes = {
    t: PropTypes.func.isRequired,
    profile: PropTypes.shape({
        books: PropTypes.shape({
            meta: PropTypes.shape({
                hits: PropTypes.number.isRequired,
            }).isRequired,
            results: PropTypes.array.isRequired,
        }).isRequired,
    }).isRequired,
    isCurrentUser: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default UserBooks;
