import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import './ManageReadingList.scss';
import { fetchManageReadingListWorkflow } from '../../redux/readingListsActions';
import { API_URL } from '../../api';
import withRouter from '../../util/withRouter';
import ManageReadingListHeader from './components/ManageReadingListHeader';
import ManageReadingListBody from './components/ManageReadingListBody';

const ManageReadingList = ({
    t,
    newList,
    parentClassName,
    manageReadingList,
    BookAddedToList,
    manageReadingListBookCount,
    navigate,
    fetchManageReadingListWorkflow,
}) => {
    const [disable, setDisable] = useState(false);

    const baseClassName = 'pb-manage-reading-list';
    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const nextPath = (path) => {
        navigate.push(path);
    };

    const manageReadingListFunc = () => {
        let readingList = newList;

        fetch(`${API_URL}/lists/${readingList.slug}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                title: readingList.title,
                description: readingList.description,
                books: readingList.books.map((item) => item.slug),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.ok && response.data) {
                    setTimeout(() => {
                        nextPath('/myreadinglists');
                    }, 2500);
                } else {
                    alert('failed');
                }
            });
    };
    return (
        <div className={classNames(classes)}>
            <div className="__manage-readinglist-section">
                <DocumentTitle
                    title={`${t('Readinglist.manage-reading-list')} - ${t(
                        'global.site-title'
                    )}`}
                />
                <div className="__themedReadingLists-section">
                    <div className="__themedReadingListItem-container">
                        <div className="__themed-readinglist-section-header">
                            {manageReadingList && (
                                <ManageReadingListHeader
                                    t={t}
                                    manageReadingList={manageReadingList}
                                    manageReadingListBookCount={
                                        manageReadingListBookCount
                                    }
                                    BookAddedToList={BookAddedToList}
                                    disable={disable}
                                    setDisable={setDisable}
                                    manageReadingListFunc={
                                        manageReadingListFunc
                                    }
                                />
                            )}
                            <div>
                                <ManageReadingListBody
                                    manageReadingList={manageReadingList}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ readingLists }) => {
    return {
        manageReadingList: readingLists && readingLists.newList,
        manageReadingListBookCount:
            readingLists &&
            readingLists.newList &&
            readingLists.newList.books &&
            readingLists.newList.books.length,
        newList: readingLists && readingLists.newList,
        BookAddedToList:
            readingLists && readingLists.newList && readingLists.newList.books,
    };
};

const mapDispatchToProps = {
    fetchManageReadingListWorkflow,
};

ManageReadingList.propTypes = {
    t: PropTypes.func.isRequired,
    newList: PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        books: PropTypes.arrayOf(
            PropTypes.shape({
                slug: PropTypes.string.isRequired,
            })
        ),
    }),
    parentClassName: PropTypes.string,
    manageReadingList: PropTypes.object,
    BookAddedToList: PropTypes.array,
    manageReadingListBookCount: PropTypes.number,
    navigate: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    fetchManageReadingListWorkflow: PropTypes.func.isRequired,
};

export default withRouter(
    translate()(connect(mapStateToProps, mapDispatchToProps)(ManageReadingList))
);
