export const helpGuideSteps = (t) => [
    {
        target: '.pb-link--fav-share',
        content: t('Video.favouriteHelpGuide'),
        placementBeacon: 'left',
        disableBeacon: true,
    },
    {
        target: '.__video-watch-btn',
        content: t('Video.watchHelpGuide'),
        placementBeacon: 'left',
    },
    {
        target: '.__video-download-btn',
        content: t('Video.saveOfflineHelpGuide'),
        placementBeacon: 'left',
    },
    {
        target: '.__comments',
        content: t('Video.disqusHelpGuide'),
        placementBeacon: 'left',
    },
];
