import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Caret from '../Caret';
import Loader from '../Loader';
import Modal from '../Modal';
import { isRightToLeftDirection } from '../../util/utils';
import './SelectCountry.scss';

const localeMapping = {
    en: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'English',
        selectedCountryID: 'EN',
    },
    id: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Bahasa Indonesia',
        selectedCountryID: 'ID',
    },
    my: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Burmese',
        selectedCountryID: 'MY',
    },
    hi: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Hindi',
        selectedCountryID: 'HI',
    },
    vi: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Tiếng Việt',
        selectedCountryID: 'VI',
    },
    sw: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'KiSwahili',
        selectedCountryID: 'SW',
    },
    mr: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Marathi',
        selectedCountryID: 'MR',
    },
    ne: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Nepali',
        selectedCountryID: 'NE',
    },
    bn: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Bengali',
        selectedCountryID: 'BN',
    },
    si: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Sinhala',
        selectedCountryID: 'SI',
    },
    km: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Khmer',
        selectedCountryID: 'KM',
    },
    lo: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Lao',
        selectedCountryID: 'LO',
    },
    zh_TW: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Chinese Traditional',
        selectedCountryID: 'ZH-HK',
    },
    ta: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Tamil',
        selectedCountryID: 'TA',
    },
    fil: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Filipino',
        selectedCountryID: 'FIL',
    },
    zh_CN: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Chinese Simplified',
        selectedCountryID: 'ZH-CN',
    },
    es: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Spanish',
        selectedCountryID: 'ES',
    },
    ar: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Arabic',
        selectedCountryID: 'AR',
    },
    lg: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Luganda',
        selectedCountryID: 'LG',
    },
    ur: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Urdu',
        selectedCountryID: 'UR',
    },
    te: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Telugu',
        selectedCountryID: 'TE',
    },
    kn: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Kannada',
        selectedCountryID: 'KN',
    },
    nyn: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Runyankore-Rukiga',
        selectedCountryID: 'NYN',
    },
    gu: {
        flag: require('../../assets/localise_dc.svg'),
        language: 'Gujarati',
        selectedCountryID: 'GU',
    },
};

const SelectCountry = (props) => {
    const {
        disabled,
        loading,
        label,
        value,
        options,
        theme,
        formField,
        error,
        parentClassName,
        onChange,
    } = props;
    let [optionOnEmpty, setOptionOnEmpty] = useState(null);
    let [showCountries, setShowCountries] = useState(false);

    const nodeRef = useRef(null);

    useEffect(() => {
        if (options) {
            const foundOption = options.find((o) => o.queryValue === '');
            if (foundOption) {
                setOptionOnEmpty(foundOption.name);
            }
        }
    }, [options]);

    const showCountriesHandler = () => setShowCountries(!showCountries);
    const languageModalClose = () => setShowCountries((prev) => !prev);

    const selectRegionHandler = (value, index) => {
        localStorage.setItem('index', index);

        if (window.location.href.includes('stories?')) {
            localStorage.setItem('langChangedInBookPage', 'true');
        }

        onChange(value);
    };

    const baseClassName = 'pb-select-country';

    let iconEl = (
        <Caret
            parentClassName={`${baseClassName}__caret`}
            direction="down"
            variant="deep-charcoal"
        />
    );
    if (loading) {
        iconEl = (
            <Loader parentClassName={`${baseClassName}__loader`} size="m" />
        );
    }

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--label`]: label,
        [`${baseClassName}--focus`]: false,
        [`${baseClassName}--blank`]:
            !value && (optionOnEmpty === '' || optionOnEmpty === null),
        [`${baseClassName}--theme-${theme}`]: theme,
        [`${baseClassName}--disabled`]: disabled,
        [`${baseClassName}--loading`]: loading,
        [`${baseClassName}--form-field`]: formField,
        [`${baseClassName}--error`]: error,
        [parentClassName]: parentClassName,
    };

    return (
        <div className={classNames(classes)} ref={nodeRef}>
            <div
                className={`${baseClassName}-container`}
                onClick={showCountriesHandler}
            >
                <div
                    style={{ display: 'flex' }}
                    className={`${baseClassName}__selected-country-container`}
                >
                    <div className={`${baseClassName}-selected-flag`}>
                        <img
                            className={`${baseClassName}-flag`}
                            src={localeMapping[value].flag}
                            alt="desc"
                        />
                    </div>
                    <div className={`${baseClassName}-separator`} />
                    <div className={`${baseClassName}-selected-country-name`}>
                        {localeMapping[value].selectedCountryID}
                    </div>
                </div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__icon-wrapper-arabic`
                            : `${baseClassName}__icon-wrapper`
                    }
                >
                    {iconEl}
                </div>
            </div>
            {showCountries ? (
                <Modal languageModal onClose={languageModalClose}>
                    <div className={'languageListTitle'}>
                        {'Select Language'}
                    </div>
                    {options
                        .sort((a, b) =>
                            a.localeCode > b.localeCode
                                ? 1
                                : b.localeCode > a.localeCode
                                ? -1
                                : 0
                        )
                        .map((option, index) => (
                            <div
                                className={
                                    index ===
                                    Number(localStorage.getItem('index'))
                                        ? `${baseClassName}-flag-choices-container-selected`
                                        : `${baseClassName}-flag-choices-container`
                                }
                                key={index}
                                value={option.queryValue}
                                onClick={() =>
                                    selectRegionHandler(
                                        option.queryValue,
                                        index
                                    )
                                }
                            >
                                {option.name} {option.localeCode}
                            </div>
                        ))}
                </Modal>
            ) : null}
            {error ? (
                <div className={`${baseClassName}__error-text`}>{error}</div>
            ) : null}
        </div>
    );
};

SelectCountry.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            queryValue: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func,
    theme: PropTypes.oneOf(['default', 'light']),
    error: PropTypes.string,
};

export default SelectCountry;
