import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Joyride from 'react-joyride';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import heartFilled from '../../assets/heart-filled.svg';
import heartUnFilled from '../../assets/heart-unfilled.svg';
import { favouriteReferenceWorkflow } from '../../redux/referencesActions';
import BookDetailSummary from '../BookDetailSummary';
import Comments from '../Comments';
import DocumentHeader from '../DocumentHeader';
import Link from '../Link';
import ShareMenu from '../ShareMenu';
import './ResourceDetail.scss';
import SimilarResources from './SimilarResources';
import { isRightToLeftDirection } from '../../util/utils';

import { References } from '../../api';

const mapDispatchToProps = {
    favouriteReferenceWorkflow,
};

const ResourceDetail = (props) => {
    const {
        t,
        parentClassName,
        online,
        isLoggedIn,
        viewport,
        resourceDetail,
        resourcesResourceThumbnailGTM,
        resourcesResourceDownloadGTM,
        resourcesResourceFavoriteGTM,
        myLanguage,
        roles,
        resourceReadsCount: initialReadsCount,
        likesCount: initialLikesCount,
        isFavourite: initialFavourite,
        helpStatus,
        favouriteReferenceWorkflow,
    } = props;
    const [likesCount, setLikesCount] = useState(initialLikesCount);
    const [isFavourite, setIsFavourite] = useState(initialFavourite);

    useEffect(() => {
        setLikesCount(initialLikesCount);
    }, [initialReadsCount, initialLikesCount]);

    const onFavoriteClicked = (resourceID) => {
        favouriteReferenceWorkflow(resourceID);
        if (isFavourite) {
            setLikesCount(likesCount - 1);
            setIsFavourite(false);
        } else {
            setLikesCount(likesCount + 1);
            setIsFavourite(true);
        }
    };

    const {
        id,
        slug,
        thumbUrl,
        contentUrl,
        title,
        similar_resources,
        synopsis,
    } = resourceDetail;

    const baseClassName = 'pb-resource-detail';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const heartEl = isFavourite ? (
        <img
            src={heartFilled}
            alt="heart-filled"
            className="__heart-unfilled"
        />
    ) : (
        <img
            src={heartUnFilled}
            alt="heart-unfilled"
            className="__heart-unfilled"
        />
    );

    const favouriteStat =
        likesCount >= 0 && online ? (
            <div className={parentClassName}>
                <Link
                    normal
                    favouriteShare
                    onClick={isLoggedIn ? () => onFavoriteClicked(id) : null}
                    dataGTM={resourcesResourceFavoriteGTM}
                >
                    {heartEl}
                    {isFavourite}
                    <span style={{ marginLeft: '3px', fontSize: '1rem' }}>
                        {likesCount}
                    </span>
                </Link>
            </div>
        ) : null;

    let steps = [
        {
            target: '.pb-link--fav-share',
            content: t('Resources.favouriteHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.__resource-dowload-btn-section',
            content: t('Resources.saveOfflineHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.__comments',
            content: t('Resources.disqusHelpGuide'),
            placementBeacon: 'left',
        },
    ];

    return (
        <div className={classNames(classes)}>
            <DocumentHeader
                title={title}
                description={synopsis}
                keywords={slug}
                imageUrl={thumbUrl}
                imgAlt={title}
            />
            <Joyride
                enabled={true}
                steps={steps}
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={250}
                disableScrolling={false}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <DocumentTitle title={`${title} - ${t('global.site-title')}`} />
            <div className="__resource-detail-section">
                <div className="__resource-detail-left">
                    <div className="__resource-detail-left-content">
                        <Link
                            shouldOpenNewPage
                            parentClassName={`${baseClassName}__link`}
                            download
                            href={contentUrl}
                            dataGTM={resourcesResourceThumbnailGTM}
                            onClick={() => References.handleDownloadCount(id)}
                        >
                            <div
                                className="__thumbnailImg"
                                dataGTM={resourcesResourceThumbnailGTM}
                            >
                                <img src={thumbUrl} alt="" />
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="__resource-detail-right">
                    <div className="__resource-detail-right-content">
                        <div
                            className={
                                myLanguage === 'lo'
                                    ? '__resource-detail-lo-title'
                                    : isRightToLeftDirection
                                    ? '__resource-detail-title __align-text-right'
                                    : '__resource-detail-title'
                            }
                        >
                            {title}
                        </div>
                        <div className="__resource-detail-desc">
                            <BookDetailSummary
                                title={t('Book.summary')}
                                description={synopsis}
                                hideSummary
                            />
                        </div>
                        <div className="__status-share">
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? '__resource-like-count-rtl'
                                        : '__resource-like-count'
                                }
                            >
                                <div
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__like-count-rtl`
                                            : `${baseClassName}__like-count`
                                    }
                                >
                                    {favouriteStat}
                                </div>
                            </div>

                            <div className="__share-section">
                                <div>
                                    <ShareMenu
                                        title={title}
                                        href={window.location.href}
                                        thumbUrl={thumbUrl}
                                        contentUrl={contentUrl}
                                        desc={synopsis}
                                        hideTitle
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="__resource-btn-section">
                            <div className="__resource-dowload-btn-section">
                                <Link
                                    shouldOpenNewPage
                                    parentClassName={`${baseClassName}__link`}
                                    download
                                    href={contentUrl}
                                    dataGTM={resourcesResourceDownloadGTM}
                                    onClick={() =>
                                        References.handleDownloadCount(id)
                                    }
                                >
                                    <div
                                        className="__resource-download-btn"
                                        data-gtm={resourcesResourceDownloadGTM}
                                    >
                                        {t('global.download', 1)}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoggedIn ? (
                <div className={`${baseClassName}__disqus-section`}>
                    <div className="__disqussion">
                        <Comments
                            {...props}
                            source="resource"
                            id={id}
                            roles={roles}
                        />
                    </div>
                </div>
            ) : null}
            <div>
                <SimilarResources
                    offline={!online}
                    similarResources={similar_resources}
                    t={t}
                    viewport={viewport}
                />
            </div>
        </div>
    );
};

ResourceDetail.propTypes = {
    parentClassName: PropTypes.string,
};

const mapStateToProps = (state) => ({
    helpStatus: state.help.helpStatus,
    roles: state.user.profile.roles,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(ResourceDetail));
