import React from 'react';
import PropTypes from 'prop-types';

const Media = ({ children }) => {
    const baseClassName = 'pb-media-object__media';
    const classNames = [baseClassName];
    return <div className={classNames.join(' ')}>{children}</div>;
};

Media.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Media;
