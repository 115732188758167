import React from 'react';
import PropTypes from 'prop-types';
import InfoUnit from '../../InfoUnit';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const Facebook = ({ facebookUrl }) => {
    return (
        <React.Fragment>
            {facebookUrl !== null && facebookUrl !== '' && (
                <div className="__profile-facebook">
                    <InfoUnit noWrap key={facebookUrl}>
                        <Link
                            shouldOpenNewPage={true}
                            href={facebookUrl}
                            dataGTM="profile-facebook"
                        >
                            <SvgIcon name="facebook" />
                        </Link>
                    </InfoUnit>
                </div>
            )}
        </React.Fragment>
    );
};

Facebook.propTypes = {
    facebookUrl: PropTypes.string,
};

export default Facebook;
