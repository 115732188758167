import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Contributors from './components/Contributors';

const DynamicBookDetails = ({
    t,
    title,
    level,
    synopsis,
    dynamicWrapperClass,
    publisher,
    authors,
    illustrators,
    translators,
    levelColour,
}) => {
    const baseClassName = 'dynamic-book-data';

    return (
        <div className={dynamicWrapperClass}>
            <div className={`${baseClassName}__title`}>{title}</div>
            <div
                className={`${baseClassName}__level_box`}
                style={{
                    background: `${levelColour}`,
                }}
            >
                <span> {level}</span>
            </div>
            <div className={`${baseClassName}__summary`}>{synopsis}</div>
            <div>
                {authors.length > 0 ? (
                    <div>
                        <span className={`${baseClassName}__label`}>
                            {t('global.author')}
                        </span>
                        <Contributors data={authors} />
                    </div>
                ) : (
                    ''
                )}
                {illustrators.length > 0 ? (
                    <div>
                        <span className={`${baseClassName}__label`}>
                            {t('global.illustrator').split('|')[0]}
                        </span>
                        <Contributors data={illustrators} />
                    </div>
                ) : (
                    ''
                )}
                {translators.length > 0 ? (
                    <div>
                        <span className={`${baseClassName}__label`}>
                            {t('global.translator')}
                        </span>
                        <Contributors data={translators} />
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div>
                <img
                    src={publisher.image}
                    alt="publisher_img"
                    width="110px"
                    height="56px"
                />
            </div>
        </div>
    );
};

DynamicBookDetails.propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    synopsis: PropTypes.string.isRequired,
    dynamicWrapperClass: PropTypes.string.isRequired,
    publisher: PropTypes.shape({
        image: PropTypes.string.isRequired,
    }).isRequired,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            first_name: PropTypes.string.isRequired,
        })
    ).isRequired,
    illustrators: PropTypes.arrayOf(
        PropTypes.shape({
            first_name: PropTypes.string.isRequired,
        })
    ).isRequired,
    translators: PropTypes.arrayOf(
        PropTypes.shape({
            first_name: PropTypes.string.isRequired,
        })
    ).isRequired,
    levelColour: PropTypes.string.isRequired,
};

export default translate()(DynamicBookDetails);
