import React from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import { helpGuideSteps } from './constants/helpGuideSteps';
import DocumentTitle from 'react-document-title';
import DonateContainer from '../DonateContainer/DonateContainer';
import BannerComponent from '../../helpers/BannerComponentHook';
import BookSuggestions from './components/BookSuggestions';
import { links } from '../../lib/constants';
import { isRightToLeftDirection } from '../../util/utils';
import BookSelections from './components/BookSelections';
import Video from './components/Video';
import './Home.scss';

const HomeV2 = ({
    t,
    isLoggedIn,
    helpStatus,
    newlyAddedBooks,
    viewport,
    hasUserHistory,
    recommendedBooks,
    trendingBooks,
    readingLists,
    videoReadCountWorkflow,
    videos
}) => {
    const baseClassName = 'pb-home';
    const myLanguage = localStorage.getItem('locale');

    const classes = {
        [baseClassName]: true,
    };
    return (
        <div className={classNames(classes)}>
            <Joyride
                enabled={true}
                steps={
                    isLoggedIn
                        ? helpGuideSteps(t).loggedInSteps
                        : helpGuideSteps(t).loggedOutSteps
                }
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={40}
                disableScrolling={false}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <DocumentTitle title={`${t('global.site-title')}`} />
            {(myLanguage === 'en' || myLanguage === 'hi') && (
                <DonateContainer />
            )}
            <BannerComponent bannerType={"home"}/>
            <div className={`${baseClassName}__container`}>
                <BookSuggestions
                    t={t}
                    baseClassName={baseClassName}
                    books={newlyAddedBooks}
                    viewport={viewport}
                    isRightToLeftDirection={isRightToLeftDirection}
                    title={
                        hasUserHistory
                            ? t('Home.book-suggestions-title')
                            : t('Home.newlyAddedBooks')
                    }
                    subTitle={t('Home.moreNewlyAddedBooks')}
                    action={links.allBooks()}
                />
                {isLoggedIn && (
                    <BookSuggestions
                        t={t}
                        baseClassName={baseClassName}
                        books={recommendedBooks}
                        viewport={viewport}
                        isRightToLeftDirection={isRightToLeftDirection}
                        title={
                            hasUserHistory
                                ? t('Home.book-suggestions-title')
                                : t('Home.book-recommended-books')
                        }
                        subTitle={t('Home.moreRecommendedBooks')}
                        action={links.allRecommendedBooks()}
                    />
                )}
                <BookSuggestions
                    t={t}
                    baseClassName={baseClassName}
                    books={trendingBooks}
                    viewport={viewport}
                    isRightToLeftDirection={isRightToLeftDirection}
                    title={
                        hasUserHistory
                            ? t('Home.book-suggestions-title')
                            : t('Home.trendingBooks')
                    }
                    subTitle={t('Home.moreTrendingBooks')}
                    action={links.allTrendingBooks()}
                />
                <BookSelections
                    t={t}
                    baseClassName={baseClassName}
                    readingLists={readingLists}
                    viewport={viewport}
                    hasUserHistory={hasUserHistory}
                    isRightToLeftDirection={isRightToLeftDirection}
                />
                <Video
                    t={t}
                    videos={videos}
                    hasUserHistory={hasUserHistory}
                    viewport={viewport}
                    videoReadCountWorkflow={videoReadCountWorkflow}
                    isRightToLeftDirection={isRightToLeftDirection}
                    isLoggedIn={isLoggedIn}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ help }) => {
    return {
        helpStatus: help.helpStatus,
    };
};

export default translate()(connect(mapStateToProps)(HomeV2));
