import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import ResourceCard from '../ResourceCard';
import LoaderBlock from '../LoaderBlock';
import Grid from '../Grid';
import withRouter from '../../util/withRouter';
import { isRightToLeftDirection } from '../../util/utils';
import { fetchAllReferencesFiltersWorkflow } from '../../redux/referencesActions';
import NoResults from './components/NoResults';

const ResourceCardContainer = ({
    t,
    location,
    parentClassName,
    References,
    filters,
    isFetchingReferences,
    totalReferencesCount,
    myLanguage,
}) => {
    const baseClassName = 'pb-resource-card-container';
    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    return (
        <div className={classNames(classes)}>
            {isFetchingReferences || !References || !filters ? (
                <LoaderBlock />
            ) : (
                <div>
                    {References.length > 0 ? (
                        <Grid variant="4up">
                            {References.map((referenceItem) => {
                                return (
                                    <div key={referenceItem.id}>
                                        <ResourceCard
                                            myLanguage={myLanguage}
                                            id={referenceItem.id}
                                            slug={referenceItem.slug}
                                            title={referenceItem.title}
                                            contentUrl={
                                                referenceItem.contentUrl
                                            }
                                            likesCount={
                                                referenceItem.likesCount
                                            }
                                            readsCount={
                                                referenceItem.readsCount
                                            }
                                            isFavourite={
                                                referenceItem.is_favourite
                                            }
                                            coverImage={referenceItem.thumbUrl}
                                            resourceThumbnailGTM="resource-thumbnail"
                                            resourceTitleGTM="resource-title"
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                    ) : null}
                    <div
                        className={
                            isRightToLeftDirection
                                ? '__no-result-section-rtl'
                                : '__no-result-section'
                        }
                    >
                        {!totalReferencesCount && <NoResults t={t} />}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    fetchAllReferencesFiltersWorkflow,
};

const mapStateToProps = ({
    references,
    viewport,
    user: { profile },
    allFilters: { readFilters },
}) => ({
    ...references,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    name: profile.name,
});

ResourceCardContainer.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    parentClassName: PropTypes.string,
    References: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            contentUrl: PropTypes.string,
            likesCount: PropTypes.number,
            readsCount: PropTypes.number,
            is_favourite: PropTypes.bool,
            thumbUrl: PropTypes.string,
        })
    ),
    filters: PropTypes.object,
    isFetchingReferences: PropTypes.bool.isRequired,
    totalReferencesCount: PropTypes.number,
    myLanguage: PropTypes.string,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(translate()(ResourceCardContainer))
);
