import React, { useEffect, useState } from 'react';
import { Banner } from '../api';
import HeroCarousel from '../components/HeroCarousel';

const BannerComponent = ({ bannerType }) => {
    const [bannerData, setBannerData] = useState([]);

    useEffect(() => {
        try {
            Banner.fetchBannerImage(bannerType)
                .then((response) => response.json())
                .then((data) => setBannerData(data.data.bannerImages || []))
                .catch((error) => {
                    setBannerData([]);
                    console.error('Error fetching banner data:', error);
                });
        } catch (error) {
            setBannerData([]);
        }
    }, []);

    return <HeroCarousel banners={bannerData} padding130={false} />;
};

export default BannerComponent;
