import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import FiltersBar from '../../FiltersBar';
import LoaderBlock from '../../LoaderBlock';
import NoResults from './NoResults';
import Grid from '../../Grid';
import BookCard from '../../BookCard';

const PublisherBooks = ({
    t,
    hitsCount,
    profile,
    filters,
    applyFilter,
    removeFilter,
    appliedFilters,
    isFetchingPublisherProfile,
    myLanguage,
}) => {
    return (
        <div>
            <Block background="transparent">
                <ContentStyler>
                    <div className="__publisher-book-header">
                        <h3>{t('Book.book-text')}</h3>
                        <h3 className="__books-count">({hitsCount})</h3>
                    </div>
                </ContentStyler>
            </Block>
            <div className="__profile-books-section">
                {profile.newArrivals.results &&
                profile.newArrivals.results.length >= 0 ? (
                    <div className="__video-filter-component">
                        <FiltersBar
                            noTopBorder
                            pullUp
                            filters={filters}
                            applyFilter={applyFilter}
                            removeFilter={removeFilter}
                            appliedFilters={appliedFilters}
                            isTranslateMobile
                            resultsCount={hitsCount}
                            languageFilterGTM="themed-reading-list-language-filter"
                            alphabetSortGTM="themed-reading-list-alphabetic-sort"
                        />
                    </div>
                ) : null}
                {isFetchingPublisherProfile ? (
                    <LoaderBlock />
                ) : (
                    <div>
                        <div>
                            {profile.newArrivals.results.length <= 0 ? (
                                <div className="no-results">
                                    <NoResults t={t} />
                                </div>
                            ) : null}
                        </div>

                        {profile.newArrivals.results.length > 0 ? (
                            <div>
                                <Grid variant="4up">
                                    {profile.newArrivals.results.map((book) => {
                                        return (
                                            <div key={book.id}>
                                                <BookCard
                                                    myLanguage={myLanguage}
                                                    type="profileNewArrivals"
                                                    isProfilePage
                                                    key={book.slug}
                                                    book={book}
                                                    id={book.id}
                                                    isFavourite={
                                                        book.is_favourite
                                                    }
                                                    isFavouriting={
                                                        book.isFavouriting
                                                    }
                                                    enableFavouriting
                                                    bookLikes={book.likesCount}
                                                    bookImageGTM="profile-book-image"
                                                    bookTitleGTM="profile-book-title"
                                                    bookAuthorGTM="profile-book-author"
                                                    bookIllustratorsGTM="profile-book-illustrators"
                                                    bookLevelGTM="profile-book-level"
                                                />
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
};

PublisherBooks.propTypes = {
    t: PropTypes.func.isRequired,
    hitsCount: PropTypes.number.isRequired,
    profile: PropTypes.shape({
        newArrivals: PropTypes.shape({
            results: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    is_favourite: PropTypes.bool,
                    isFavouriting: PropTypes.bool,
                    likesCount: PropTypes.number,
                })
            ).isRequired,
        }).isRequired,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    isFetchingPublisherProfile: PropTypes.bool.isRequired,
    myLanguage: PropTypes.string.isRequired,
};

export default PublisherBooks;
