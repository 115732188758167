import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { translate } from 'react-polyglot';
import Profile from '../Profile';
import {
    fetchPublisherBooksFiltersWorkflow,
    fetchPublisherProfileWorkflow,
    fetchProfileWorkflow,
    loadMoreWorkflow,
} from '../../redux/profileActions';
import LoaderBlock from '../LoaderBlock';
import Block from '../Block';
import queryString from 'query-string';
import { sortOptions } from '../../lib/constants';
import withRouter from '../../util/withRouter';

const defaultFilters = {
    category: [],
    publisher: [],
    role: [],
    style: [],
    language: [],
    sort: sortOptions.relevance,
    tags: [],
    query: '',
};

const ProfileContainer = ({
    profileType,
    fetchPublisherBooksFiltersWorkflow,
    fetchPublisherProfileWorkflow,
    fetchProfileWorkflow,
    navigate,
    params,
    location,
    isFetchingProfile,
    profile,
    viewport,
    loadMoreWorkflow,
    isFetchingMore,
    siteUser,
    filters,
    hitsCount,
    isFetchingPublisherProfile,
}) => {
    useEffect(() => {
        if (profileType === 'publisher') {
            fetchPublisherBooksFiltersWorkflow().then((response) => {
                let filters = getAppliedFilters();
                filters.language = response.data.current_language;
                const searchParams = queryString.stringify(filters);
                navigate({ search: `?${searchParams}` }, { replace: true });
                fetchPublisherProfileWorkflow(
                    filters,
                    params.slug,
                    profileType
                );
            });
        } else {
            fetchProfileWorkflow(params.slug, profileType);
        }
    }, []);

    useEffect(() => {
        fetchProfileWorkflow(params.slug, profileType);
    }, [params.slug]);

    const getAppliedFilters = () => {
        const parsedFilters = queryString.parse(location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    if (isFetchingProfile || !profile[profileType]) {
        return <LoaderBlock />;
    }

    if (!isFetchingProfile && !profile) {
        return (
            <Block>
                <h1>Error fetching profile</h1>
            </Block>
        );
    }

    const applyFilterFunc = (type, value) => {
        const appliedFilters = cloneDeep(getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });

        fetchPublisherProfileWorkflow(appliedFilters, params.slug);
    };

    const removeFilterFunc = (type, value) => {
        const appliedFilters = cloneDeep(getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });

        fetchPublisherProfileWorkflow(appliedFilters, params.slug);
    };

    const isCurrentUser =
        siteUser.isLoggedIn &&
        siteUser.profile &&
        siteUser.profile.email === profile[profileType].email;

    const appliedFilters = getAppliedFilters();

    return (
        <Profile
            isFetchingPublisherProfile={isFetchingPublisherProfile}
            type={profileType}
            profile={profile[profileType]}
            viewport={viewport}
            loadMore={loadMoreWorkflow}
            isFetchingMore={isFetchingMore}
            isCurrentUser={isCurrentUser}
            appliedFilters={appliedFilters}
            applyFilter={applyFilterFunc}
            removeFilter={removeFilterFunc}
            filters={filters}
            hitsCount={hitsCount}
        />
    );
};

const mapStateToProps = ({ profile, viewport, user }) => ({
    isFetchingPublisherProfile: profile.isFetchingPublisherProfile,
    isFetchingFilters: profile.isFetchingFilters,
    isFetchingProfile: profile.isFetchingProfile,
    isFetchingMore: profile.isFetchingMore,
    profile: profile.profile,
    filters: profile.filters,
    hitsCount: profile.profile.hitsCount,
    viewport,
    siteUser: user,
});

const mapDispatchToProps = {
    fetchPublisherBooksFiltersWorkflow,
    fetchPublisherProfileWorkflow,
    fetchProfileWorkflow,
    loadMoreWorkflow,
};

ProfileContainer.propTypes = {
    profileType: PropTypes.string.isRequired,
    fetchPublisherBooksFiltersWorkflow: PropTypes.func.isRequired,
    fetchPublisherProfileWorkflow: PropTypes.func.isRequired,
    fetchProfileWorkflow: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isFetchingProfile: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    viewport: PropTypes.object.isRequired,
    loadMoreWorkflow: PropTypes.func.isRequired,
    isFetchingMore: PropTypes.bool.isRequired,
    siteUser: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    hitsCount: PropTypes.number.isRequired,
    isFetchingPublisherProfile: PropTypes.bool.isRequired,
};

export default withRouter(
    translate()(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer))
);
