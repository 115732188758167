import React from 'react';
import PropTypes from 'prop-types';

const MediaObject = ({ children }) => {
    const baseClassName = 'pb-media-object';
    const classNames = [baseClassName];

    return <div className={classNames.join(' ')}>{children}</div>;
};

MediaObject.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MediaObject;
