import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import u from 'updeep';
import { helpGuideSteps } from '../constants/helpGuideSteps';
import { isRightToLeftDirection } from '../../../util/utils';
import { sectionClicked } from '../../../lib/constants';
import {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
    fetchAllRecommendedBooksWorkflow,
    fetchAllTrendingBooksWorkflow,
} from '../../../redux/allBooksActions';
import { fetchMeWorkflow } from '../../../redux/userActions';
import { recordGaEvents } from '../../../redux/googleAnalyticsActions';
import { addSlimNotification } from '../../../redux/slimNotificationActions';
import Block from '../../Block';
import TitleImage from '../../TitleImage';
import SearchModal from '../../SearchModal';
import FiltersBar from '../../FiltersBar';
import LoaderBlock from '../../LoaderBlock';
import BookCard from '../../BookCard';
import Grid from '../../Grid';
import Pagination from '../../Pagination';
import withRouter from '../../../util/withRouter';
import useDeviceType from '../../../hooks/useDeviceType';
import { languages } from '../constants/languages';
import { filtersBarSortOptions } from '../constants/filtersBarSortOptions';
import BannerComponent from '../../../helpers/BannerComponentHook';
import NoResults from './NoResults';

const defaultFilters = {
    category: [],
    role: [],
    level: [],
    language: [],
};

const BooksSearchResultsContainer = ({
    t,
    bookResults,
    searchPage,
    books,
    helpStatus,
    totalBooksCount,
    searchString,
    viewport,
    fetchAllBooksWorkflow,
    navigate,
    filters,
    recommendedBooks,
    fetchAllRecommendedBooksWorkflow,
    trendingBooks,
    fetchAllTrendingBooksWorkflow,
    appliedFilters,
    fetchAllBooksFiltersWorkflow,
    isFetchingBooks,
    loadedPages,
    isFetchingMoreBooks,
}) => {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [filtersSearchText, setFiltersSearchText] = useState({
        category: '',
        publisher: '',
        level: '',
        language: '',
    });

    const { dimensions } = useDeviceType();

    const isMobile = dimensions.width <= 768;

    useEffect(() => {
        fetchAllBooksFiltersWorkflow().then((response) => {
            let filters = getAppliedFilters();

            if (!searchPage) {
                let currentLanguage = localStorage.getItem('locale');

                let translatedLanguage =
                    currentLanguage && languages[currentLanguage];
                if (!translatedLanguage) {
                    translatedLanguage = 'English';
                } else {
                    translatedLanguage = languages[currentLanguage];
                }

                let found = filters && filters.language;
                let isLangChangedInBooksPage = localStorage.getItem(
                    'langChangedInBookPage'
                );
                if (found) {
                    if (isLangChangedInBooksPage === 'true') {
                        filters.language = [translatedLanguage];
                        localStorage.removeItem('langChangedInBookPage');
                    }
                } else {
                    filters.language = [translatedLanguage];
                }
            }
            const searchParams = queryString.stringify(filters);
            navigate({ search: `?${searchParams}` }, { replace: true });

            if (recommendedBooks) {
                fetchAllRecommendedBooksWorkflow(filters);
            } else if (trendingBooks) {
                fetchAllTrendingBooksWorkflow(filters);
            } else {
                fetchAllBooksWorkflow(filters);
            }
        });
    }, []);

    const myLanguage = localStorage.getItem('locale');

    const onOpenSearchModal = () => setIsSearchModalOpen(true);
    const onCloseSearchModal = () => setIsSearchModalOpen(false);

    const onBookSearch = (event) => {
        let appliedFilters = getAppliedFilters();
        setIsSearchModalOpen(false);
        appliedFilters = {
            query: event,
            sort: 'Relevance',
        };

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });
        fetchAllBooksWorkflow(appliedFilters);
    };

    const getAppliedFilters = () => {
        const parsedFilters = queryString.parse(window.location.search);
        let currentLanguage = localStorage.getItem('locale');

        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        if (!searchPage) {
            let translatedLanguage =
                currentLanguage && languages[currentLanguage];

            if (!translatedLanguage) {
                translatedLanguage = 'English';
            } else {
                translatedLanguage = languages[currentLanguage];
            }

            defaultFilters.language = [translatedLanguage];
        }

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    const applyFilter = (type, value) => {
        let appliedFilters = cloneDeep(getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });
        if (recommendedBooks) {
            fetchAllRecommendedBooksWorkflow(appliedFilters);
        } else if (trendingBooks) {
            fetchAllTrendingBooksWorkflow(appliedFilters);
        } else {
            fetchAllBooksWorkflow(appliedFilters);
        }
    };

    const removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });
        if (recommendedBooks) {
            fetchAllRecommendedBooksWorkflow(appliedFilters);
        } else if (trendingBooks) {
            fetchAllTrendingBooksWorkflow(appliedFilters);
        } else {
            fetchAllBooksWorkflow(appliedFilters);
        }
    };

    const updateFiltersSearchText = (key, text) => {
        setFiltersSearchText((prevState) =>
            u(
                {
                    [key]: text,
                },
                prevState
            )
        );
    };

    const updateCategorySearchText = (e) =>
        updateFiltersSearchText('category', e.target.value);
    const updatePublisherSearchText = (e) =>
        updateFiltersSearchText('publisher', e.target.value);
    const updateLevelSearchText = (e) =>
        updateFiltersSearchText('level', e.target.value);
    const updateLanguageSearchText = (e) =>
        updateFiltersSearchText('language', e.target.value);

    const onSortOptionChanged = (option) => applyFilter('sort', option);

    const shouldShowLoadMore = books && books.length < totalBooksCount;

    const onLoadMore = () => {
        if (recommendedBooks) {
            fetchAllRecommendedBooksWorkflow(
                getAppliedFilters(),
                loadedPages + 1
            );
        } else if (trendingBooks) {
            fetchAllTrendingBooksWorkflow(getAppliedFilters(), loadedPages + 1);
        } else {
            fetchAllBooksWorkflow(getAppliedFilters(), loadedPages + 1);
        }
    };

    const resourceTitleEl =
        window.location.search.search('query') !== -1 ? (
            <h3 className="__search-result-title">
                {myLanguage !== 'ne'
                    ? `${totalBooksCount} ${t(
                          'Search.results-for-count'
                      )} \u2018${searchString}\u2019`
                    : `\u2018${searchString}\u2019 ${t(
                          'Search.results-for-count-1'
                      )} ${totalBooksCount} ${t('Search.results-for-count-2')}`}
            </h3>
        ) : (
            [
                <div className="__resources-title">
                    <h3>{t('global.book', 2)}</h3>
                    <h3
                        className={
                            isRightToLeftDirection
                                ? '__total-margin-left'
                                : '__normal'
                        }
                    >
                        ({totalBooksCount})
                    </h3>
                </div>,
            ]
        );

    const BookGridEl = ({ books, t, applyFilter, getAppliedFilters }) => {
        const bookEls = books.map((book) => {
            return (
                <BookCard
                    myLanguage={myLanguage}
                    key={book.slug}
                    book={book}
                    id={book.id}
                    bookLikes={book.likesCount}
                    isAllBook
                    sectionClicked={sectionClicked.allStories}
                    isFavourite={book.is_favourite}
                    isFavouriting={book.isFavouriting}
                    applyFilter={applyFilter}
                    enableFavouriting
                    bookImageGTM="books-book-image"
                    bookTitleGTM="books-book-title"
                    bookAuthorGTM="books-book-author"
                    bookIllustratorsGTM="books-book-Illustrators"
                    bookLevelGTM="books-book-Level"
                />
            );
        });
        return <Grid variant="4up">{bookEls}</Grid>;
    };

    return (
        <div>
            { bookResults && (
                <BannerComponent bannerType={"books"}/>
            )}
            <Block verticalMargin={!searchPage} background="transparent">
                <Joyride
                    enabled={true}
                    steps={
                        isMobile
                            ? helpGuideSteps(t, myLanguage).mobileSteps
                            : helpGuideSteps(t, myLanguage).steps
                    }
                    continuous
                    showProgress
                    run={helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={false}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <div className="book-header">
                    {resourceTitleEl}
                    {!searchPage && (
                        <div
                            className={`book-section--menu-search`}
                            onClick={onOpenSearchModal}
                        >
                            <TitleImage
                                className={
                                    isRightToLeftDirection
                                        ? '__profile-images-arabic'
                                        : '__profile-images'
                                }
                                svgName={require('../../../assets/search_tl.svg')}
                                title={t('books.search')}
                                contentSpecificSearch
                            />
                        </div>
                    )}
                </div>

                <div className="book-section">
                    {books && books.length >= 0 && (
                        <div className="filter-section">
                            <FiltersBar
                                viewport={viewport}
                                noTopBorder
                                pullUp
                                filters={filters}
                                applyFilter={applyFilter}
                                removeFilter={removeFilter}
                                appliedFilters={getAppliedFilters()}
                                categorySearchValue={filtersSearchText.category}
                                updateCategorySearchValue={
                                    updateCategorySearchText
                                }
                                publisherSearchValue={
                                    filtersSearchText.publisher
                                }
                                updatePublisherSearchValue={
                                    updatePublisherSearchText
                                }
                                levelSearchValue={filtersSearchText.level}
                                updateLevelSearchValue={updateLevelSearchText}
                                languageSearchValue={filtersSearchText.language}
                                updateLanguageSearchValue={
                                    updateLanguageSearchText
                                }
                                resultsCount={totalBooksCount}
                                itemTypeLabel={t(
                                    'global.story',
                                    totalBooksCount
                                )}
                                sortOptions={filtersBarSortOptions(t)}
                                applySortOption={onSortOptionChanged}
                                appliedSortOption={appliedFilters.sort}
                                isBookSection
                                isBookSectionMobile={isMobile}
                                categoryFilterGTM="book-category-filter"
                                levelFilterGTM="book-level-filter"
                                languageFilterGTM="book-language-filter"
                                favoriteSortGTM="book-favorite-sort"
                                viewSortGTM="book-view-sort"
                                alphabetSortGTM="book-alphabetic-sort"
                                displayTypeSelector
                            />
                        </div>
                    )}
                    {isFetchingBooks || !books ? (
                        <LoaderBlock />
                    ) : (
                        <div>
                            {books.length > 0 && (
                                <div>
                                    <BookGridEl
                                        books={books}
                                        t={t}
                                        applyFilter={applyFilter}
                                    />
                                    {shouldShowLoadMore && (
                                        <Pagination
                                            onClick={onLoadMore}
                                            loading={isFetchingMoreBooks}
                                        />
                                    )}
                                </div>
                            )}
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? '__no-result-section-rtl'
                                        : '__no-result-section'
                                }
                            >
                                {!totalBooksCount && <NoResults />}
                            </div>
                        </div>
                    )}
                </div>
                {isSearchModalOpen && (
                    <SearchModal
                        viewport={viewport}
                        onClose={onCloseSearchModal}
                        onSubmit={onBookSearch}
                    />
                )}
            </Block>
        </div>
    );
};

const mapDispatchToProps = {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
    fetchMeWorkflow,
    recordGaEvents,
    addSlimNotification,
    fetchAllRecommendedBooksWorkflow,
    fetchAllTrendingBooksWorkflow,
};

const mapStateToProps = ({
    allBooks,
    viewport,
    user: { profile },
    allFilters: { readFilters },
    help,
}) => ({
    ...allBooks,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    name: profile.name,
    helpStatus: help.helpStatus,
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(translate()(BooksSearchResultsContainer))
);
