import PropTypes from 'prop-types';
import React from 'react';
import translate from 'react-polyglot/lib/translate';
import Block from '../Block';
import PublicationForm from '../PublicationForm';
import './PublicationFormContainer.scss';

const PublicationFormContainer = ({
    isStoryUpload,
    isStoryEdit,
    isNewEdition,
}) => {
    return (
        <div className="publication-form-container">
            <Block background="transparent">
                <PublicationForm
                    isStoryUpload={isStoryUpload}
                    isStoryEdit={isStoryEdit}
                    isNewEdition={isNewEdition}
                />
            </Block>
        </div>
    );
};

PublicationFormContainer.propTypes = {
    isStoryUpload: PropTypes.bool,
    isStoryEdit: PropTypes.bool,
    isNewEdition: PropTypes.bool,
};

export default translate()(PublicationFormContainer);
