import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import './RTRBookReaderPageHeader.scss';
import SvgIcon from '../../../SvgIcon';
import { isRightToLeftDirection } from '../../../../util/utils';

const RTRBookReaderPageHeader = ({
    t,
    bookData,
    fullScreen,
    exitStoryReader,
}) => {
    const goBack = () => {
        if (fullScreen) {
            exitStoryReader();
        }

        window.history.back();
    };

    return (
        <div
            className={`RTRBookReaderPageHeader ${
                isRightToLeftDirection ? 'RTRBookReaderPageHeader-rtl' : ''
            }`}
        >
            <span
                className={`backToBookButton ${
                    isRightToLeftDirection ? 'backToBookButton-rtl' : ''
                }`}
                onClick={() => {
                    goBack();
                }}
            >
                <SvgIcon
                    name="reader-view-back-button"
                    size="reader-view-controls"
                />
                <span className="BackButton">{t('Book.headerBackButton')}</span>
            </span>
            <div
                className={`BookTitle ${
                    isRightToLeftDirection ? 'BookTitle-rtl' : ''
                }`}
            >
                <span>{bookData.title}</span>
            </div>
        </div>
    );
};

RTRBookReaderPageHeader.propTypes = {
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export default translate()(RTRBookReaderPageHeader);
