import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import Grid from '../../Grid';
import { isRightToLeftDirection } from '../../../util/utils';
import Books from './Books';

const OfflineInfo = ({ t, books, baseClassName, myLanguage, online }) => {
    return (
        <React.Fragment>
            <Block offlineSection normalPadding>
                <ContentStyler offline>
                    <div className="__offline-title">
                        <h3>{t('global.offline-library')}</h3>
                    </div>
                    <div className="offline-books__sub-title">
                        {t('OfflineBookModal.popup')}
                    </div>
                </ContentStyler>
            </Block>
            {process.env.REACT_APP_FEATURE_OFFLINE && (
                <Block background="transparent" normalPadding>
                    <ContentStyler>
                        <div className="offline-books__resources-title">
                            <h3>{t('global.book', 2)}</h3>
                            <h3
                                className={
                                    isRightToLeftDirection
                                        ? '__total-margin-left'
                                        : '__normal'
                                }
                            >
                                ({books.length})
                            </h3>
                        </div>
                    </ContentStyler>
                    {books.length > 0 && (
                        <div className={`${baseClassName}--offline__grid`}>
                            <Grid variant="4up">
                                <Books
                                    books={books}
                                    myLanguage={myLanguage}
                                    online={online}
                                />
                            </Grid>
                        </div>
                    )}
                </Block>
            )}
        </React.Fragment>
    );
};

OfflineInfo.propTypes = {
    t: PropTypes.func.isRequired,
    books: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            likesCount: PropTypes.number,
            is_favourite: PropTypes.bool,
        })
    ).isRequired,
    baseClassName: PropTypes.string.isRequired,
    myLanguage: PropTypes.string.isRequired,
    online: PropTypes.bool.isRequired,
};

export default OfflineInfo;
