import React from 'react';
import PropTypes from 'prop-types';
import LoaderBlock from '../../LoaderBlock';
import BooksCards from './cards/BooksCards';
import VideosCards from './cards/VideosCards';
import ResourcesCards from './cards/ResourcesCards';

const RequiredCards = ({
    isFetchingData,
    selectedTab,
    favouriteBooks,
    favouriteResources,
    favouriteVideos,
}) => {
    const createRequiredCards = (selectedTab) => {
        switch (selectedTab) {
            case 'books':
                return <BooksCards favouriteBooks={favouriteBooks} />;
            case 'videos':
                return <VideosCards favouriteVideos={favouriteVideos} />;
            case 'resources':
                return (
                    <ResourcesCards favouriteResources={favouriteResources} />
                );
            default:
                break;
        }
    };
    return (
        <React.Fragment>
            {isFetchingData ? (
                <LoaderBlock />
            ) : (
                createRequiredCards(selectedTab)
            )}
        </React.Fragment>
    );
};

RequiredCards.propTypes = {
    isFetchingData: PropTypes.bool.isRequired,
    selectedTab: PropTypes.string.isRequired,
    favouriteBooks: PropTypes.array.isRequired,
    favouriteResources: PropTypes.array.isRequired,
    favouriteVideos: PropTypes.array.isRequired,
};

export default RequiredCards;
