import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';
import { sortOptions } from '../../../lib/constants';
import { isRightToLeftDirection } from '../../../util/utils';

const SortIllustrationsMobile = ({
    t,
    isMostView,
    mostViewSortChange,
    leastViewSortChange,
    viewSortGTM,
    isAscend,
    ascendingSortChange,
    descendingSortChange,
    alphabetSortGTM,
}) => {
    return [
        <span>
            <Link
                onClick={
                    isMostView
                        ? () => mostViewSortChange(sortOptions.mostView)
                        : () => leastViewSortChange(sortOptions.leastView)
                }
                lightBlack
                dataGTM={viewSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={viewSortGTM}
                >
                    {t('global.list-view', 2)}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={viewSortGTM}
                    />
                </div>
            </Link>
        </span>,
        <span>
            <Link
                onClick={
                    isAscend
                        ? () => ascendingSortChange(sortOptions.descending)
                        : () => descendingSortChange(sortOptions.ascending)
                }
                lightBlack
                dataGTM={alphabetSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={alphabetSortGTM}
                >
                    {!isAscend
                        ? t('global.alphabet-reverse')
                        : t('global.alphabet')}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={alphabetSortGTM}
                    />
                </div>
            </Link>
        </span>,
    ];
};

SortIllustrationsMobile.propTypes = {
    t: PropTypes.func.isRequired,
    isMostView: PropTypes.bool.isRequired,
    mostViewSortChange: PropTypes.func.isRequired,
    leastViewSortChange: PropTypes.func.isRequired,
    viewSortGTM: PropTypes.string.isRequired,
    isAscend: PropTypes.bool.isRequired,
    ascendingSortChange: PropTypes.func.isRequired,
    descendingSortChange: PropTypes.func.isRequired,
    alphabetSortGTM: PropTypes.string.isRequired,
};

export default SortIllustrationsMobile;
