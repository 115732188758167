import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '../../Link';

const BookTitle = ({
    onClick,
    baseClassName,
    bookPath,
    handleClick,
    asdBookTitleGTM,
    titleClasses,
    title,
}) => {
    return (
        <Link
            isInternal={!onClick}
            parentClassName={`${baseClassName}__link`}
            href={bookPath}
            onClick={onClick && handleClick}
            dataGTM={asdBookTitleGTM}
        >
            <div
                className={classNames(titleClasses)}
                data-gtm={asdBookTitleGTM}
            >
                <div
                    className={`${baseClassName}__book-title`}
                    data-gtm={asdBookTitleGTM}
                >
                    {title}
                </div>
            </div>
        </Link>
    );
};

BookTitle.propTypes = {
    onClick: PropTypes.func,
    baseClassName: PropTypes.string.isRequired,
    bookPath: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    asdBookTitleGTM: PropTypes.string,
    titleClasses: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default BookTitle;
