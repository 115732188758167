import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const VideoThumbnail = ({
    videoModalOpen,
    videosVideoThumbnailGTM,
    thumbUrl,
    duration,
}) => {
    return (
        <div className="__video-detail-left">
            <Link
                onClick={() => videoModalOpen()}
                dataGTM={videosVideoThumbnailGTM}
            >
                <div
                    className="__video-detail-left-content"
                    data-gtm={videosVideoThumbnailGTM}
                >
                    <div
                        className="__video-play"
                        data-gtm={videosVideoThumbnailGTM}
                    >
                        <SvgIcon
                            name="play"
                            size="l"
                            dataGTM={videosVideoThumbnailGTM}
                        />
                    </div>
                    <div
                        className="__thumbnailImg"
                        data-gtm={videosVideoThumbnailGTM}
                    >
                        <img
                            src={thumbUrl}
                            alt=""
                            data-gtm={videosVideoThumbnailGTM}
                        />
                    </div>
                    <div
                        className="__video-duration"
                        data-gtm={videosVideoThumbnailGTM}
                    >
                        {duration}
                    </div>
                </div>
            </Link>
        </div>
    );
};

VideoThumbnail.propTypes = {
    videoModalOpen: PropTypes.func.isRequired,
    videosVideoThumbnailGTM: PropTypes.string.isRequired,
    thumbUrl: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
};

export default VideoThumbnail;
