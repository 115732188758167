import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './RowSeparatorContainer.scss';

const RowSeparatorContainer = ({ parentClassName, children, noPadding }) => {
    const baseClassName = 'pb-row-separator-container';

    const classes = {
        [`${baseClassName}__no-padding`]: noPadding,
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    return (
        <div className={classNames(classes)}>
            {children}
            <div className={`${baseClassName}__separator`} />
        </div>
    );
};

RowSeparatorContainer.propTypes = {
    parentClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    noPadding: PropTypes.bool,
};

export default RowSeparatorContainer;
