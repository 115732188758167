import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';
import { sortOptions } from '../../../lib/constants';
import { isRightToLeftDirection } from '../../../util/utils';

const SortBookMobile = ({
    t,
    isMostLiked,
    mostLikedSortChange,
    leastLikedSortChange,
    favoriteSortGTM,
    isMostRead,
    mostReadSortChange,
    leastReadSortChange,
    readSortGTM,
    isAscend,
    ascendingSortChange,
    descendingSortChange,
    alphabetSortGTM,
}) => {
    return [
        <span>
            <Link
                onClick={
                    isMostLiked
                        ? () => mostLikedSortChange(sortOptions.mostLiked)
                        : () => leastLikedSortChange(sortOptions.leastLiked)
                }
                lightBlack
                dataGTM={favoriteSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={favoriteSortGTM}
                >
                    {t('sort.most-liked')}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={favoriteSortGTM}
                    />
                </div>
            </Link>
        </span>,
        <span>
            <Link
                onClick={
                    isMostRead
                        ? () => mostReadSortChange(sortOptions.mostRead)
                        : () => leastReadSortChange(sortOptions.leastRead)
                }
                lightBlack
                dataGTM={readSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={readSortGTM}
                >
                    {t('global.list-read', 2)}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={readSortGTM}
                    />
                </div>
            </Link>
        </span>,
        <span>
            <Link
                onClick={
                    isAscend
                        ? () => ascendingSortChange(sortOptions.descending)
                        : () => descendingSortChange(sortOptions.ascending)
                }
                lightBlack
                dataGTM={alphabetSortGTM}
            >
                <div
                    className="__option-values-media-sort"
                    data-gtm={alphabetSortGTM}
                >
                    {!isAscend
                        ? t('global.alphabet-reverse')
                        : t('global.alphabet')}
                    <SvgIcon
                        name="sort"
                        size="sort"
                        push={
                            isRightToLeftDirection
                                ? 'pushRight'
                                : 'pushLeftSmall'
                        }
                        dataGTM={alphabetSortGTM}
                    />
                </div>
            </Link>
        </span>,
    ];
};

SortBookMobile.propTypes = {
    t: PropTypes.func.isRequired,
    isMostLiked: PropTypes.bool.isRequired,
    mostLikedSortChange: PropTypes.func.isRequired,
    leastLikedSortChange: PropTypes.func.isRequired,
    favoriteSortGTM: PropTypes.string.isRequired,
    isMostRead: PropTypes.bool.isRequired,
    mostReadSortChange: PropTypes.func.isRequired,
    leastReadSortChange: PropTypes.func.isRequired,
    readSortGTM: PropTypes.string.isRequired,
    isAscend: PropTypes.bool.isRequired,
    ascendingSortChange: PropTypes.func.isRequired,
    descendingSortChange: PropTypes.func.isRequired,
    alphabetSortGTM: PropTypes.string.isRequired,
};

export default SortBookMobile;
