import React from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import './OfflineBooks.scss';
import OfflineInfo from './components/OfflineInfo';
import OfflineText from './components/OfflineText';

const OfflineBooks = ({
    books,
    online,
    myLanguage,
    t,
    viewport,
    onDelete,
    checkedValues,
}) => {
    const baseClassName = 'pb-offline-books';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--offline`]: !online,
    };

    return (
        <div className={classNames(classes)}>
            <OfflineInfo
                t={t}
                books={books}
                baseClassName={baseClassName}
                myLanguage={myLanguage}
                online={online}
            />

            <OfflineText t={t} online={online} books={books} />
        </div>
    );
};

OfflineBooks.propTypes = {
    books: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            likesCount: PropTypes.number,
            is_favourite: PropTypes.bool,
        })
    ).isRequired,
    online: PropTypes.bool.isRequired,
    myLanguage: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    viewport: PropTypes.string,
    onDelete: PropTypes.func,
    checkedValues: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
};

export default translate()(OfflineBooks);
