import React from 'react';
import PropTypes from 'prop-types';
import InputFieldTitle from '../../InputFieldTitle';
import { isRightToLeftDirection } from '../../../util/utils';

const ReadingLevel = ({ t, baseClassName, readingLevel, handleRadio }) => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <div>
                <InputFieldTitle
                    isRequired={true}
                    t={t}
                    value={t('PublicationForm.readingLevel')}
                    isRightToLeftDirection={isRightToLeftDirection}
                />
                <div className={`${baseClassName}_radio-buttons`}>
                    <label className="radioinput-label">
                        <input
                            className={
                                isRightToLeftDirection
                                    ? 'level-radioinput-rtl'
                                    : 'level-radioinput'
                            }
                            id="level1"
                            value={t('PublicationForm.level1')}
                            name="level1"
                            type="radio"
                            checked={readingLevel === '1'}
                            onChange={() => handleRadio('1')}
                        />
                        <span
                            className={
                                isRightToLeftDirection
                                    ? 'levelradiotext-rtl'
                                    : 'levelradiotext'
                            }
                        >
                            <b>{t('PublicationForm.level1')}</b>
                            {':'} {t('PublicationForm.level1Text')}
                        </span>
                    </label>
                    <label className="radioinput-label">
                        <input
                            className={
                                isRightToLeftDirection
                                    ? 'level-radioinput-rtl'
                                    : 'level-radioinput'
                            }
                            id="level2"
                            value={t('PublicationForm.level2')}
                            name="level2"
                            type="radio"
                            checked={readingLevel === '2'}
                            onChange={() => handleRadio('2')}
                        />
                        <span
                            className={
                                isRightToLeftDirection
                                    ? 'levelradiotext-rtl'
                                    : 'levelradiotext'
                            }
                        >
                            <b>{t('PublicationForm.level2')}</b>
                            {':'} {t('PublicationForm.level2Text')}
                        </span>
                    </label>
                    <label className="radioinput-label">
                        <input
                            className={
                                isRightToLeftDirection
                                    ? 'level-radioinput-rtl'
                                    : 'level-radioinput'
                            }
                            id="level3"
                            value={t('PublicationForm.level3')}
                            name="level3"
                            type="radio"
                            onChange={() => handleRadio('3')}
                            checked={readingLevel === '3'}
                        />
                        <span
                            className={
                                isRightToLeftDirection
                                    ? 'levelradiotext-rtl'
                                    : 'levelradiotext'
                            }
                        >
                            <b>{t('PublicationForm.level3')}</b>
                            {':'} {t('PublicationForm.level3Text')}
                        </span>
                    </label>
                    <label className="radioinput-label">
                        <input
                            className={
                                isRightToLeftDirection
                                    ? 'level-radioinput-rtl'
                                    : 'level-radioinput'
                            }
                            id="level4"
                            value={t('PublicationForm.level4')}
                            name="level4"
                            type="radio"
                            onChange={() => handleRadio('4')}
                            checked={readingLevel === '4'}
                        />
                        <span
                            className={
                                isRightToLeftDirection
                                    ? 'levelradiotext-rtl'
                                    : 'levelradiotext'
                            }
                        >
                            <b>{t('PublicationForm.level4')}</b>
                            {':'} {t('PublicationForm.level4Text')}
                        </span>
                    </label>
                    <label className="radioinput-label">
                        <input
                            className={
                                isRightToLeftDirection
                                    ? 'level-radioinput-rtl'
                                    : 'level-radioinput'
                            }
                            id="level5"
                            value={t('PublicationForm.level5')}
                            name="level5"
                            type="radio"
                            onChange={() => handleRadio('5')}
                            checked={readingLevel === '5'}
                        />
                        <span
                            className={
                                isRightToLeftDirection
                                    ? 'levelradiotext-rtl'
                                    : 'levelradiotext'
                            }
                        >
                            <b>{t('PublicationForm.level5')}</b>
                            {':'} {t('PublicationForm.level5Text')}
                        </span>
                    </label>
                    <label className="radioinput-label">
                        <input
                            className={
                                isRightToLeftDirection
                                    ? 'level-radioinput-rtl'
                                    : 'level-radioinput'
                            }
                            id="level6"
                            value={t('PublicationForm.level6')}
                            name="level6"
                            type="radio"
                            onChange={() => handleRadio('6')}
                            checked={readingLevel === '6'}
                        />
                        <span
                            className={
                                isRightToLeftDirection
                                    ? 'levelradiotext-rtl'
                                    : 'levelradiotext'
                            }
                        >
                            <b>{t('PublicationForm.level6')}</b>
                            {':'} {t('PublicationForm.level6Text')}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
};

ReadingLevel.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    readingLevel: PropTypes.string.isRequired,
    handleRadio: PropTypes.func.isRequired,
};

export default ReadingLevel;
