import React, { useState } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flatMap, pick } from 'lodash';
import { connect } from 'react-redux';
import { filterData, levelData, typeData } from './constants/constants';
import FiltersBarComponent from './components/FiltersBarComponent';
import FilterModal from './components/FilterModal';
import SortModal from './components/SortModal';
import { isRightToLeftDirection } from '../../util/utils';

const FiltersBar = ({
    applyFilter,
    removeFilter,
    filters,
    categorySearchValue,
    updateCategorySearchValue,
    t,
    noTopBorder,
    pullUp,
    levelSearchValue,
    updateLevelSearchValue,
    appliedFilters,
    sortOptions,
    applySortOption,
    appliedSortOption,
    viewport,
    isIllustration,
    isIllustrationMobile,
    isResources,
    isBookSection,
    isVideos,
    isReadingList,
    isReadingListMobile,
    isBookSectionMobile,
    isResourceMobile,
    isTranslate,
    isTranslateMobile,
    isVideoMobile,
    isAddBook,
    isAddBookMobile,
    categoryFilterGTM,
    roleFilterGTM,
    levelFilterGTM,
    languageFilterGTM,
    favoriteSortGTM,
    viewSortGTM,
    alphabetSortGTM,
    readSortGTM,
    readAloud,
    hideLevelSelector,
    hideLanguageSelector,
    displayTypeSelector,
}) => {
    const { category, role, current_role, language } = filters;
    const baseClassName = 'pb-filters-bar';

    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [openModalSort, setOpenModalSort] = useState(false);

    const filtersBarFilters = pick(appliedFilters, filterData);

    const hasFilters = flatMap(filtersBarFilters, (filters) => filters).length;

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--no-top-border`]: noTopBorder,
        [`${baseClassName}--pull-up`]: pullUp,
        [`${baseClassName}--has-filter`]: hasFilters,
    };

    const shouldDisplayCategorySelector =
        typeof filtersBarFilters.category !== 'undefined' &&
        typeof filters.category !== 'undefined';

    const openModalSortFunc = () => {
        setOpenModalSort(true);
    };

    const modalSortClose = () => {
        setOpenModalSort(false);
    };

    const openModalFilterFunc = () => {
        setOpenModalFilter(true);
    };

    const modalFilterClose = () => {
        setOpenModalFilter(false);
    };

    const handlePicklistChange = (type, value, checked) => {
        if (checked) {
            applyFilter(type, value);
        } else {
            removeFilter(type, value);
        }
    };

    const levelOptions = levelData(t).queryValues.map(
        ({ name, description, queryValue }) => ({
            name,
            legend: description,
            queryValue,
        })
    );
    const typeOptions = typeData(t).queryValues.map(({ name, queryValue }) => ({
        name,
        queryValue,
    }));
    const categoryOptions = (category && category.queryValues) || [];
    const roleOptions = (role && role.queryValues) || [];
    const languageOptions = (language && language.queryValues) || [];

    const shouldDisplayRoleSelector =
        typeof filtersBarFilters.role !== 'undefined' &&
        typeof filters.role !== 'undefined';

    const shouldDisplayLanguageSelector =
        typeof filtersBarFilters.language !== 'undefined' &&
        typeof filters.language !== 'undefined';

    return (
        <React.Fragment>
            <div className={classNames(classes)}>
                <FiltersBarComponent
                    t={t}
                    viewport={viewport}
                    baseClassName={baseClassName}
                    readAloud={readAloud}
                    shouldDisplayCategorySelector={
                        shouldDisplayCategorySelector
                    }
                    categoryFilterGTM={categoryFilterGTM}
                    categorySearchValue={categorySearchValue}
                    updateCategorySearchValue={updateCategorySearchValue}
                    category={category}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    filtersBarFilters={filtersBarFilters}
                    hideLevelSelector={hideLevelSelector}
                    levelFilterGTM={levelFilterGTM}
                    levelSearchValue={levelSearchValue}
                    updateLevelSearchValue={updateLevelSearchValue}
                    filters={filters}
                    roleFilterGTM={roleFilterGTM}
                    hideLanguageSelector={hideLanguageSelector}
                    languageFilterGTM={languageFilterGTM}
                    displayTypeSelector={displayTypeSelector}
                    sortOptions={sortOptions}
                    appliedSortOption={appliedSortOption}
                    applySortOption={applySortOption}
                    isIllustration={isIllustration}
                    isResources={isResources}
                    isAddBook={isAddBook}
                    isBookSection={isBookSection}
                    isVideos={isVideos}
                    isTranslate={isTranslate}
                    isReadingList={isReadingList}
                    favoriteSortGTM={favoriteSortGTM}
                    viewSortGTM={viewSortGTM}
                    alphabetSortGTM={alphabetSortGTM}
                    readSortGTM={readSortGTM}
                    openModalFilter={openModalFilterFunc}
                    isTranslateMobile={isTranslateMobile}
                    openModalSort={openModalSortFunc}
                    handlePicklistChange={handlePicklistChange}
                    levelOptions={levelOptions}
                    shouldDisplayRoleSelector={shouldDisplayRoleSelector}
                    categoryOptions={categoryOptions}
                    roleOptions={roleOptions}
                    languageOptions={languageOptions}
                    shouldDisplayLanguageSelector={
                        shouldDisplayLanguageSelector
                    }
                    typeOptions={typeOptions}
                    currentRole={current_role}
                />
            </div>
            {openModalFilter && (
                <FilterModal
                    t={t}
                    classes={classNames}
                    baseClassName={baseClassName}
                    modalFilterClose={modalFilterClose}
                    readAloud={readAloud}
                    shouldDisplayCategorySelector={
                        shouldDisplayCategorySelector
                    }
                    categoryFilterGTM={categoryFilterGTM}
                    categorySearchValue={categorySearchValue}
                    updateCategorySearchValue={updateCategorySearchValue}
                    categoryOptions={categoryOptions}
                    roleOptions={roleOptions}
                    languageOptions={languageOptions}
                    handlePicklistChange={handlePicklistChange}
                    filtersBarFilters={filtersBarFilters}
                    hideLevelSelector={hideLevelSelector}
                    levelSearchValue={levelSearchValue}
                    updateLevelSearchValue={updateLevelSearchValue}
                    levelOptions={levelOptions}
                    shouldDisplayRoleSelector={shouldDisplayRoleSelector}
                    shouldDisplayLanguageSelector={
                        shouldDisplayLanguageSelector
                    }
                    roleFilterGTM={roleFilterGTM}
                    hideLanguageSelector={hideLanguageSelector}
                    languageFilterGTM={languageFilterGTM}
                    displayTypeSelector={displayTypeSelector}
                    typeOptions={typeOptions}
                />
            )}
            {openModalSort && (
                <SortModal
                    t={t}
                    classes={classes}
                    baseClassName={baseClassName}
                    modalSortClose={modalSortClose}
                    sortOptions={sortOptions}
                    appliedSortOption={appliedSortOption}
                    applySortOption={applySortOption}
                    isBookSectionMobile={isBookSectionMobile}
                    isVideoMobile={isVideoMobile}
                    isResourceMobile={isResourceMobile}
                    isReadingListMobile={isReadingListMobile}
                    isAddBookMobile={isAddBookMobile}
                    isIllustrationMobile={isIllustrationMobile}
                    isTranslateMobile={isTranslateMobile}
                    isRightToLeftDirection={isRightToLeftDirection}
                />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = ({ viewport }) => ({
    viewport,
});
const mapDispatchToProps = {};

FiltersBar.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    categorySearchValue: PropTypes.string,
    updateCategorySearchValue: PropTypes.func,
    t: PropTypes.func.isRequired,
    noTopBorder: PropTypes.bool,
    pullUp: PropTypes.bool,
    levelSearchValue: PropTypes.string,
    updateLevelSearchValue: PropTypes.func,
    appliedFilters: PropTypes.object.isRequired,
    sortOptions: PropTypes.array,
    applySortOption: PropTypes.func.isRequired,
    appliedSortOption: PropTypes.string,
    viewport: PropTypes.object.isRequired,
    isIllustration: PropTypes.bool,
    isIllustrationMobile: PropTypes.bool,
    isResources: PropTypes.bool,
    isBookSection: PropTypes.bool,
    isVideos: PropTypes.bool,
    isReadingList: PropTypes.bool,
    isReadingListMobile: PropTypes.bool,
    isBookSectionMobile: PropTypes.bool,
    isResourceMobile: PropTypes.bool,
    isTranslate: PropTypes.bool,
    isTranslateMobile: PropTypes.bool,
    isVideoMobile: PropTypes.bool,
    isAddBook: PropTypes.bool,
    isAddBookMobile: PropTypes.bool,
    categoryFilterGTM: PropTypes.string,
    roleFilterGTM: PropTypes.string,
    levelFilterGTM: PropTypes.string,
    languageFilterGTM: PropTypes.string,
    favoriteSortGTM: PropTypes.string,
    viewSortGTM: PropTypes.string,
    alphabetSortGTM: PropTypes.string,
    readSortGTM: PropTypes.string,
    readAloud: PropTypes.bool,
    hideLevelSelector: PropTypes.bool,
    hideLanguageSelector: PropTypes.bool,
    displayTypeSelector: PropTypes.bool,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(FiltersBar)
);
