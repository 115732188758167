import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import classNames from 'classnames';
import Checkbox from '../Checkbox';
import Link from '../Link';
import Sizer from '../Sizer';
import './Picklist.scss';

const Picklist = ({
    t,
    id,
    options,
    searchValue,
    onClear,
    fitToContainer,
    multiplePicks = true,
    fontWeight,
    dataGTM,
    type,
    onChange,
    checkedValues = [],
}) => {
    const baseClassName = 'pb-picklist';
    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--clearable`]: onClear,
        [`${baseClassName}--fit-to-container`]: fitToContainer,
    };

    const filteredOptions =
        searchValue !== '' && typeof searchValue !== 'undefined'
            ? options.filter((option) =>
                  String(option.queryValue)
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
              )
            : options;

    const isValueChecked = (value) => {
        let values = checkedValues;

        if (!multiplePicks && checkedValues && Array.isArray(checkedValues)) {
            values = checkedValues[checkedValues.length - 1];
        }

        if (values && Array.isArray(values)) {
            return values.includes(value);
        } else if (values) {
            return value === values;
        }

        return false;
    };

    const handleCheckBoxChange = ({ checked, value }) => {
        if (onChange) {
            onChange(value, checked);
        }
    };

    const selectedOptions = filteredOptions.map((c, i) => {
        return (
            isValueChecked(c.queryValue) && (
                <Checkbox
                    key={`${id}-checkbox-${i}`}
                    id={`${id}-checkbox-${i}`}
                    label={c.name}
                    value={c.queryValue}
                    legend={c.legend}
                    checked={true}
                    onChange={handleCheckBoxChange}
                    radioIcon={!multiplePicks}
                    fontWeight={fontWeight}
                    dataGTM={dataGTM}
                />
            )
        );
    });

    const levelOptions = filteredOptions.map((c, i) => {
        return (
            <Checkbox
                key={`${id}-checkbox-${i}`}
                id={`${id}-checkbox-${i}`}
                label={c.name}
                value={c.queryValue}
                legend={c.legend}
                checked={isValueChecked(c.queryValue)}
                onChange={handleCheckBoxChange}
                radioIcon={!multiplePicks}
                fontWeight={fontWeight}
                dataGTM={dataGTM}
            />
        );
    });

    const checkboxEls = filteredOptions.map((c, i) => {
        return (
            !isValueChecked(c.queryValue) && (
                <Checkbox
                    key={`${id}-checkbox-${i}`}
                    id={`${id}-checkbox-${i}`}
                    label={c.name}
                    value={c.queryValue}
                    legend={c.legend}
                    checked={isValueChecked(c.queryValue)}
                    onChange={handleCheckBoxChange}
                    radioIcon={!multiplePicks}
                    fontWeight={fontWeight}
                    dataGTM={dataGTM}
                />
            )
        );
    });

    const notLevelOptions = selectedOptions.concat(checkboxEls);

    return (
        <div className={classNames(classes)}>
            <div className={`${baseClassName}__header`}>
                {onClear && (
                    <Link
                        parentClassName={`${baseClassName}__clear-trigger`}
                        onClick={onClear}
                        dataGTM={dataGTM}
                    >
                        {t('Picklist.clear-all')}
                    </Link>
                )}
            </div>
            <div className={`${baseClassName}__options`}>
                <Sizer maxHeight="lx" scrollY>
                    {type === 'level' ? levelOptions : notLevelOptions}
                </Sizer>
            </div>
        </div>
    );
};

Picklist.propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            queryValue: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            legend: PropTypes.string,
        })
    ).isRequired,
    searchValue: PropTypes.string,
    onClear: PropTypes.func,
    fitToContainer: PropTypes.bool,
    multiplePicks: PropTypes.bool,
    fontWeight: PropTypes.string,
    dataGTM: PropTypes.string,
    type: PropTypes.oneOf(['level', 'default']),
    onChange: PropTypes.func.isRequired,
    checkedValues: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
};

export default translate()(Picklist);
